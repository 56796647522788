import React, { useEffect } from 'react';
import methodModel from '../../../methods/methods';
import { Link } from 'react-router-dom';
import './style.scss';
import Layout from '../../global/layout';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import Header from '../../../pages/HomePage/Header';
import environment from '../../../environment';

const Html = ({ handleSubmit, setForm, form, uploadImage, submitted }) => {

  useEffect(()=>{
setForm({...form,dialCode:'+1'})
  },[])
  
  
  return (
    // <Layout>
    <>

      <div className='container p80'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-8 mx-auto'>
            <div className='pprofile1'>


              <form
                className="form-row"
                onSubmit={handleSubmit}
              >

                <div className="col-md-12 mb-3 inputFlex">
                  <label>Image</label>
                  <div>
                    <div className='maininutcls'>
                      <label className="editprofile-list">
                        <img src={form?.image ? `${environment?.api}images/users/` + form?.image : "/assets/img/person.jpg"} className="" />
                      </label>
                      <div className='profile_btn'>

                        <div>
                          <label className="btn btn-primary edit ml-3">
                            <input
                              id="bannerImage"
                              type="file"
                              className="d-none"
                              accept="image/*"
                              value={form.baseImg ? form.baseImg : ''}
                              onChange={(e) => { uploadImage(e); }}
                            />{form.image ? 'Change' : 'Upload'} Image</label>
                        </div>
                        <div>
                          {form.image ? <label className="btn btn-primary  delete ml-3" onClick={e => setForm({ ...form, image: "" })}>Remove Image</label> : <></>}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>Name<span className='star'>*</span></label>
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Name"
                      value={form.fullName ? form.fullName : ''}
                      onChange={e => setForm({ ...form, fullName: e.target.value })}
                      required
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>Email</label>
                  <div>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Enter Name"
                      value={form.email ? form.email : ''}
                      disabled
                    />
                  </div>
                </div>
                <div className="col-md-12 mb-3 inputFlex">
                  <label>Mobile No<span className='star'>*</span></label>
                  <div>
                    <div className='form-row'>
                      <div className='col-md-3'>
                        <PhoneInput
                          country={'us'}
                          // required
                          enableSearch={true}
                          placeholder="Enter phone number"
                          value={form?.dialCode}
                          onKeyDown={event => { event.preventDefault() }}
                          countryCodeEditable={true}
                          onChange={e => setForm({ ...form, dialCode: e })}
                        />
                      </div>
                      <div className='col-md-9'>
                        <div className="phoneInput">
                          {/* <input
                    type="text"
                    className="form-control" placeholder='+1'
                    value={form && form.dialCode || ''}
                    // pattern="[0-9]{3}[+]"
                    title="Phone number with + and remaing 9 digit with 0-9"
                    maxLength={4}
                    onChange={e => setForm({ ...form, dialCode: e.target.value })}
                    required
                  /> */}
                          <input
                            type="text"
                            className="form-control" placeholder='Mobile No.'
                            value={form && form.mobileNo || ''}
                            maxLength={12}
                            onChange={e => setForm({ ...form, mobileNo: methodModel.isNumber(e) })}
                            required
                          />

                        </div>
                      </div>
                    </div>

                    {submitted && form.dialCode.length < 1 ? <div className="invalid-feedback d-block">invalid country code</div> : <></>}
                    {submitted && form.mobileNo.length < 10  ? <div className="invalid-feedback d-block">Min Length is 10</div> : <></>}
                  </div>
                </div>

                <div className="col-md-12 text-right mt-3">
                  <Link to="/profile" className="btn btn-primary reset">
                    Discards
                  </Link>
                  <button type="submit" className="btn btn-primary edit ml-3">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
    // </Layout>
  );
};

export default Html;
