
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import './style.scss';
import { ToastsStore } from 'react-toasts';

const ConfirmCode = () => {
    const history = useHistory();
    const user = useSelector(state => state.user)
    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/dashboard')
        }
        let message = methodModel.getPrams('message')
        if (message) toast.success(message)
    }, [])

    const formValidation = [
        { key: 'confirmPassword', minLength: 8, confirmMatch: ['confirmPassword', 'newPassword'] },
        { key: 'newPassword', minLength: 8 },
    ]

    const [form, setForm] = useState({ confirmPassword: '', newPassword: '', code: '', id: '' });
    const [submitted, setSubmitted] = useState(false)
    const [eyes, setEyes] = useState({ password: false, confirmPassword: false });

    const getError = (key) => {
        return methodModel.getError(key, form, formValidation)
    }

    useEffect(() => {
        let prm = {
            // email: methodModel.getPrams('email'),
            password: form?.newPassword,
            code: form?.code
        }

        setForm({ ...form, ...prm })
    }, [])

    const hendleSubmit = (e) => {
        e.preventDefault();
        setSubmitted(true)
        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid) return
        loader(true)
        let payload = {
            newPassword: form.newPassword,
            code: form.code,
            // id: form.id
        }
        ApiClient.put('reset/password', payload).then(res => {
            if (res.success) {
                ToastsStore.success(res.message)
                history.push('/login?message=' + res.message);
            }
            loader(false)
        })
    };


    return (
        <>

            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-md-6 px-0">
                        <div className='banner_img_local'>

                        </div>
                    </div>

                    <div className="col-md-6 p-0">
                        <div className='right_side'>
                            <form
                                className="width80 mx-auto"
                                onSubmit={hendleSubmit}
                            >

                                <div className="main_titled mb-4">
                                    <h3 className="text-left lgtext">New Password</h3>

                                    <p className=''>Please create a new password that you don’t use on any other site.</p>
                                </div>

                                <div className="inner_login">
                                    <div className="mb-3">
                                        <div className="inputWrapper">
                                            <input
                                                type="text"
                                                className="form-control mb-0 bginput"
                                                value={form.code}
                                                min="12"
                                                onChange={e => setForm({ ...form, code: e.target.value })}
                                                placeholder="Enter Code"
                                                required
                                            />
                                        </div>
                                    </div>

                                    <div className="mb-3">
                                        <div className="inputWrapper">
                                            <input
                                                type={eyes.password ? 'text' : 'password'}
                                                className="form-control mb-0 bginput"
                                                value={form.newPassword}
                                                min="12"
                                                onChange={e => setForm({ ...form, newPassword: e.target.value })}
                                                placeholder="Create new password"
                                                required
                                            />
                                            <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
                                        </div>

                                        {submitted && getError('newPassword').invalid ? <div className="invalid-feedback d-block">Min Length must be 8 characters long</div> : <></>}
                                    </div>
                                    <div className="inputWrapper mb-5">
                                        {/* <label>Confirm Password<span className="start">*</span></label> */}

                                        <div className="inputWrapper">
                                            <input
                                                type={eyes.confirmPassword ? 'text' : 'password'}
                                                className="form-control mb-0 bginput"
                                                value={form.confirmPassword}
                                                maxLength={50}
                                                onChange={e => setForm({ ...form, confirmPassword: e.target.value })}
                                                placeholder="Confirm new password"
                                                required
                                            />
                                            <i className={eyes.confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })}></i>
                                        </div>
                                        {submitted && getError('confirmPassword').err.confirmMatch ? <div className="invalid-feedback d-block">Confirm Password is not matched with New Password</div> : <></>}
                                    </div>
                                </div>


                                <div className="buttons">

                                    <button type="submit" className="btn btn-primary loginclass mb-4">
                                        Save
                                    </button>
                                </div>

                                <p className='accopuntt'> Just Remember?<Link class="sign_up" to="/login"> Sign In</Link></p>
                            </form>

                        </div>

                    </div>


                </div>
            </div>
            <ToastContainer position="top-right" />
        </>
    );
};

export default ConfirmCode;
