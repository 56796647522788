import React, { useEffect } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import Sidebar from '../sidebar';
import Header from '../header';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastsStore } from 'react-toasts';
import ReactGA from 'react-ga'
import methodModel from '../../../methods/methods';

const Layout = ({ children }) => {
  let user = useSelector(state => state.user)
  const searchState = useSelector((state) => state.search);
  const history = useHistory();
 
  const useAnalyticsEventTracker = (category="Blog category") => {
    const eventTracker = (action = "test action", label = "test label") => {
      ReactGA.event({category, action, label});
    }
    return eventTracker;
  }

ReactGA.initialize('G-PDR2K98N27');
useEffect(()=>{
 useAnalyticsEventTracker("Dazboard")
},[])
  useEffect(() => {
    // if (user && !user.loggedIn) {
    //   history.push('/login');
    // }
  }, [searchState])

  const logo = () => {
    let value = '/assets/img/logo.png'
    return value
  }

  const router=()=>{
    let route=localStorage.getItem('route')
    history.push(route);
  }
 


  return (
    <>
    <div component="layout">
    <div onClick={e=>router()} id="routerDiv"></div>
      <Header />
      <div className="main-wrapper d-flex ">
        <div className="main-sidebar  d-md-block " >
          <div className="sidebar-brand p-3 pt-4  text-left pl-5" style={{marginTop:'5px',height:'50px',marginBottom:'2px'}}>
            {/* <label className='editLogo'> */}
            <img style={{width:'20%',borderRadius:'70%'}} src={'https://static.vecteezy.com/system/resources/thumbnails/012/185/873/small/physical-therapy-logo-design-concept-free-vector.jpg'} className="logocls" />
            Therapy
            {/* </label> */}
       

          </div>
          {/* {user?.logo?<div className='d-flex justify-center' style={{display:'flex',justifyContent:'center',alignItems:'center'}}>

              <img src={methodModel.userImg(user?.logo || "")} style={{width:'40px',marginBottom:'2px',height:'40px',borderRadius:'50%'}} />
            </div>:null
            } */}
      
          
           

       
          <Sidebar />
        </div>
        <main className="main">
          <div className="mainarea">{children}</div>
 
        </main>
      </div>
    </div>
    <ToastContainer   position="top-right" />
    </>
  );
};
export default Layout;
