import React, { useEffect, useState } from "react";
import Footer from "../HomePage/Footer/Footer";
import Header from "../HomePage/Header";
import ApiClient from "../../methods/api/apiClient";
import { useSelector } from "react-redux";
import Pagination from "react-js-pagination";
// import "bootstrap/less/bootstrap.less";
import { filter } from "minimatch";
import loader from "../../methods/loader";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import dateFormat, { masks } from "dateformat";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { SocketURL } from "../Chat/socket";

function ViewAppointment() {
  const [filters, setFilter] = useState({
    page: 1,
    count: 5,
    search: "",
    sortBy: "",
    status: "",
  });
  const [isScrolled, setIsScrolled] = useState(false);
  const user = useSelector((state) => state.user);
  const [data, setdata] = useState([]);
  const [total, settTotal] = useState();
  const [search, setsearch] = useState("");
  const history = useHistory();
  const [form, setform] = useState({ rating: 0, review: "", rate_to: "" });
  const [time, setTime] = useState("15-12-2023,10");
  const [resAppoint, SetresAppoint] = useState([]);
  const [unReadCount,setUnReadCount] = useState({})
  const [Plan, setPlan] = useState([])
  const [isButtonEnabled, setIsButtonDisabled] = useState(false);
  const [appointments, SetAppointTime] = useState([])
  const [therapistData, setTherapistData] = useState([])
  const [resheduleAppointTime,SetResheduleAppointTime] = useState([])
  const totalAppointment = [...resheduleAppointTime,...appointments]

  // const GetCode = (e) => {
  //   window.open(`https://zoom.us/oauth/authorize?response_type=code&client_id=VA3DVHVCSie6QIooYnQA&redirect_uri=https://therapy.jcsoftwaresolution.in/recent`)
  // }

  const GetCode = (e) => {
    window.open(`https://accounts.google.com/o/oauth2/auth?response_type=code&client_id=683567546759-lsa6ijcmcotg05r1rrt3b38dumi8tebc.apps.googleusercontent.com&redirect_uri=https://therapy.jcsoftwaresolution.in/recent&scope=https://www.googleapis.com/auth/calendar.events&access_type=offline`)
  }

  const handleRemainder =()=>{
    document.getElementById('openRemianderEditModal').click()
  }

  const GetTherapistData = () => {
    ApiClient.get('therapist').then((res) => {
      if (res) {
        setTherapistData(res?.data)
      }
    })
  }

  useEffect(() => {
    GetTherapistData()
  }, [])

  const today = new Date();
  let FilteredSlots = [];

  const checkAppointments = () => {
    let hasConflictingAppointment = false;
  
    totalAppointment.forEach(appointment => {
      const appointmentDate = new Date(appointment.date);
      const [startHour, endHour] = appointment.time.split('-');
  
      appointmentDate.setHours(parseInt(startHour), 0, 0, 0);
      const endTime = new Date(appointmentDate);
      endTime.setHours(parseInt(endHour));
  
      if (
        appointmentDate.getDate() === today.getDate() &&
        appointmentDate.getMonth() === today.getMonth() &&
        appointmentDate.getFullYear() === today.getFullYear()
      ) {
        if (today >= appointmentDate && today <= endTime) {
          hasConflictingAppointment = true;
        }
      }
    });
    setIsButtonDisabled(hasConflictingAppointment);
  };
  
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  const getAcceptData = (p = {}) => {
    loader(true)
    // let filter = { ...filters, ...p }
    ApiClient.get('user/appointmentslist', { sortBy: "date asc", status: "accept" }).then(res => {
      if (res.success) {
        loader(false)
        SetAppointTime(res?.data)
      }

    })
  }

  const getResheduleAcceptData = (p = {}) => {
    loader(true)
    // let filter = { ...filters, ...p }
    ApiClient.get('user/reschedulelist', { sortBy: "date asc" }).then(res => {
      if (res.success) {
        loader(false)
        SetResheduleAppointTime(res?.data)
      }

    })
  }

  useEffect(() => {
    checkAppointments();
  }, [appointments,resheduleAppointTime]);

  const GetPlan = () => {
    ApiClient.get(`user/profile`).then((res) => {
      if (res.success) {
        setPlan(res?.data?.plan_details?.features)
      }
    })
  }

  useEffect(()=>{
    GetPlan()
    getAcceptData()
    getResheduleAcceptData()
  },[])

  useEffect(() => {
    if (!user?.loggedIn) {
      history.push("/");
    }
  }, []);

  const getUnReadMessage = () => {
    let url = `${SocketURL}chat/user/unread-count/all?user_id=${user?.id}`
    axios.get(url).then((res) => {
      if (res?.data?.success) {
        setUnReadCount(res?.data?.data)
      }
    });
  }

  useEffect(() => {
    getUnReadMessage()
  }, [])

  const handleStarClick = (value) => {
    const newValue = parseInt(value);
    for (let i = 1; i <= 5; i++) {
      const checkbox = document.getElementById(`st${i}`);
      if (checkbox) {
        checkbox.checked = i <= newValue;
      }
    }
    setform({ ...form, rating: parseInt(value) })
  };

  const getData = (p = {}) => {
    loader(true);
    let filter = { ...filters, ...p };
    ApiClient.get("user/appointmentslist", filter).then((res) => {
      if (res.success) {
        setdata(res?.data);
        settTotal(res?.total);
        loader(false);
        console.log(parseInt(res?.data[0]?.time?.split("-")[0]));
        let AppointDate = dateFormat(res?.data[0]?.date, "dd-mm-yyyy");
        let AppointHr = parseInt(res?.data[0]?.time?.split("-")[0]);
        let finalTime = AppointDate + "," + AppointHr; 
      }
    });
  };

  const getData2 = (p = {}) => {
    loader(true);
    let filter = { ...filters, ...p };
    ApiClient.get("user/reschedulelist", filter).then((res) => {
      if (res.success) {
        SetresAppoint(res?.data);
        loader(false);
      }
    });
  };

  const handlePagination = (e) => {
    setFilter({ ...filters, page: e });
    getData({ page: e });
  };
  const Search = (e) => {
    e.preventDefault();
    setFilter({ ...filters, search: search });
    getData({ search: search, page: 1 });
  };

  useEffect(() => {
    if (user?.loggedIn) {
      getData2();
      getData();
    }
  }, [filters]);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      setIsScrolled(scrollTop > scrollThreshold);

      clearTimeout(timeoutId);

      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const HandleSubmit = (e) => {
    e.preventDefault();
    ApiClient.post("rating", form).then((res) => {
      if (res.success) {
        toast.success(res.message);
        document.getElementById("CloseReview").click();
        setform({ rating: 0, review: "", rate_to: "" });
      }
    });
  };

  const setAppointId = (id) => {
    const fltr = resAppoint?.filter((itm) => id == itm?.appointment_id);
    console.log(fltr);
    setform({ ...form, date: fltr[0]?.date, time: fltr[0]?.time });
  };

  const HandleSubmitReason = (e) => {
    e.preventDefault();
    ApiClient.put("appointment", {
      reason: form?.reason,
      id: form?.rate_to,
    }).then((res) => {
      if (res.success) {
        toast.success(res.message);
        document.getElementById("CloseReason").click();
        setform({});
      } else {
        document.getElementById('CloseReason').click()
        setform({ reason: '' })
      }
    });
  };
  return (
    <>
      <div className="bg_leaf position-relative">
        <img
          src="/assets/img/therpy/left1.png"
          className="img-fluid leftside"
        />
        <img
          src="/assets/img/therpy/right1.png"
          className="img-fluid rightside"
        />
        <div className={`header-dark-page ${isScrolled ? "scrollsticky" : ""}`}>
          <Header />
        </div>

        <div className="container-fluid p-0 overflow-hidden">
          <div className="row ">
            <div className="col-lg-12">
              <div className="contact-us-bg bg_light_grey">
                <h2>My Appointment</h2>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>

      <section className="p80">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 mx-auto">
              <div className="table-appointment">
                <div className="d-flex justify-content-end tabletop mb-3">
                  <form onSubmit={Search}>
                    <div className="d-flex">
                      <div className="labelnames mr-2">
                        <input
                          value={filters?.search}
                          type="text"
                          onChange={(e) => {
                            setsearch(e.target.value);
                            setFilter({
                              ...filters,
                              status: "",
                              search: e.target.value,
                            });
                          }}
                          className="table_search"
                          placeholder="Search here"
                        />
                      </div>
                      <select
                        value={filters.status}
                        className="table_select"
                        onChange={(e) => {
                          setFilter({
                            ...filters,
                            status: e.target.value,
                            search: "",
                          });

                          setsearch("");
                        }}
                      >
                        <option value="">Select Status</option>
                        <option value="">All</option>
                        <option value="accept">Accepted</option>
                        <option value="reject">Rejected</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                  </form>
                  <button
                    onClick={() => history.push("/appointment")}
                    className="btn btn-primary h60 btn-color btn-lg ml-2"
                  >
                    Book Appointment
                  </button>

                  {Plan?.length > 0 && Plan.some(item => item.name === "chat" && item.isChecked) ? <button
                    onClick={() => {
                      history.push(`/chat?id=${therapistData?.id}`)
                    }}
                    className="btn btn-primary h60 btn-color btn-lg ml-2 position-relative"
                  >
                    Start a chat {unReadCount?.unread_count > 0 && <span  className="counting_msg">{unReadCount?.unread_count}</span>}
                  </button> :
                    <button
                      onClick={() => toast.error("This feature is not included in the plan.")}
                      className="btn btn-primary h60 btn-color btn-lg ml-2"
                    >
                      Start a chat
                    </button>
                  }

                  {Plan?.length > 0 && Plan?.some(item => item.name === "video call" && item.isChecked) && isButtonEnabled ? 
                  <button
                    onClick={() => {
                      // GetCode()
                      handleRemainder()
                      localStorage.setItem("meetingwith", therapistData?.id)
                      // window.scrollTo(0, 740)
                    }} className='btn btn-primary h60 btn-color btn-lg ml-2'>
                    <span className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
                      <path d="M14.3267 4.5V1C14.3267 0.734784 14.2213 0.48043 14.0338 0.292893C13.8462 0.105357 13.5919 0 13.3267 0H1.32666C1.06144 0 0.80709 0.105357 0.619553 0.292893C0.432017 0.48043 0.32666 0.734784 0.32666 1V11C0.32666 11.2652 0.432017 11.5196 0.619553 11.7071C0.80709 11.8946 1.06144 12 1.32666 12H13.3267C13.5919 12 13.8462 11.8946 14.0338 11.7071C14.2213 11.5196 14.3267 11.2652 14.3267 11V7.5L18.3267 11.5V0.5L14.3267 4.5Z" fill="white" />
                    </svg></span>
                    Video Call
                  </button>
                    :
                    <button
                      onClick={() => {
                        if (Plan && Plan?.some(item => item.name == "video call" && !item.isChecked)) {
                          toast.error("This plan cannot include this feature")
                        }
                        if (Plan && Plan?.some(item => item.name == "video call" && item.isChecked) && !isButtonEnabled) {
                          toast.error("Your Appointment is not Right Now.")
                        }
                      }} className='btn btn-primary h60 btn-color btn-lg ml-2'>
                      <span className="mr-1"><svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
                        <path d="M14.3267 4.5V1C14.3267 0.734784 14.2213 0.48043 14.0338 0.292893C13.8462 0.105357 13.5919 0 13.3267 0H1.32666C1.06144 0 0.80709 0.105357 0.619553 0.292893C0.432017 0.48043 0.32666 0.734784 0.32666 1V11C0.32666 11.2652 0.432017 11.5196 0.619553 11.7071C0.80709 11.8946 1.06144 12 1.32666 12H13.3267C13.5919 12 13.8462 11.8946 14.0338 11.7071C14.2213 11.5196 14.3267 11.2652 14.3267 11V7.5L18.3267 11.5V0.5L14.3267 4.5Z" fill="white" />
                      </svg></span>
                      Video Call
                    </button>}
                </div>

                <table class="table  table-striped">
                  {/* <caption>List of Appointments</caption> */}
                  <thead className="table-dark">
                    <tr>
                      <th scope="col">Name</th>
                      <th scope="col">Email</th>
                      <th scope="col">Date</th>
                      <th scope="col">Time</th>
                      <th scope="col">Confirm Status</th>
                      <th scope="col">
                      Complete Status
                        {/* <span className="name_divth">Complete Status</span> */}
                      </th>
                      <th scope="col">Rescheduled</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>


                  <tbody>
                    {data?.map((itm) => {
                      return (
                        <tr>
                          <td><span className="name_div">{itm.name}</span></td>
                          <td><span className="name_div">{itm.email}</span></td>
                          <td><span className="name_div">{dateFormat(itm.date, 'dd-mmm-yyyy')}</span></td>
                          <td className=""><span className="name_div">{itm.time}</span></td>
                          {itm?.appointment_status == "done" ||
                            itm.appointment_status == "missed" ? (
                            <td >
                              <span
                                style={{ color: "black" }}
                                className={`reviewinner  `}
                              >
                                <span className="text_missed">
                                  {itm.appointment_status}
                                </span>

                              </span>
                            </td>
                          ) : (
                            <td>
                              <span className={`reviewinner ${itm.status}`}>
                                <span className={`text_missed ${itm.status}`}>
                                  {itm.status}
                                  {itm.status != "pending" ? "ed" : ""}
                                </span>

                              </span>
                            </td>
                          )}

                          <td>
                            <span
                              className={`reviewinner ${itm.appointment_status}`}
                            >
                              <span className={`text_missed ${itm.appointment_status}`}>
                                {itm.appointment_status}

                              </span>

                            </span>
                          </td>
                          <td className="">
                            {itm?.is_rescheduled ? (
                              <p className="reviewinner text_missed pointer missed mt-3"
                                onClick={() => {
                                  document
                                    .getElementById("OpenModel3")
                                    .click();
                                  setAppointId(itm?._id);
                                }}
                              >
                                Yes
                              </p>
                            ) : (
                              <p className="text_missed m-0">No</p>
                            )}
                          </td>
                          <td className="">
                            {itm?.appointment_status == "done" ? (
                              <span
                                onClick={() => {
                                  setform({ ...form, rate_to:itm?.therapist_id , appointment_id:itm?._id });
                                  document
                                    .getElementById("OpenModel")
                                    .click();
                                }}
                                className="starreview"
                                title="write a review"
                              >
                                <i
                                  class="fa fa-star"
                                  aria-hidden="true"
                                ></i>
                              </span>
                            ) : <span disabled
                              style={{ backgroundColor: 'gray' }}
                              className="starreview"
                              title="write a review"
                            >
                              <i
                              onClick={()=>{
                                toast.warn('Your appointment has not been completed yet. Once it is completed, you can write a review.')
                              }}
                                class="fa fa-star"
                                aria-hidden="true"
                              ></i>
                            </span>}
                            {
                              itm?.appointment_status == 'missed' ? <span
                                className="icon_missed"
                                onClick={() => {
                                  document
                                    .getElementById("OpenModel2")
                                    .click();
                                  setform({ ...form, rate_to: itm?._id });
                                }}
                                title="Reason"
                              >
                                {" "}
                                <i
                                  class="fa fa-comment "
                                  aria-hidden="true"
                                ></i>
                              </span> : null
                              //  <span
                              //   className="icon_missed"
                              //   style={{ backgroundColor: 'gray' }}
                              //   title="Reason"
                              // >
                              //   {" "}
                              //   <i
                              //     class="fa fa-comment "
                              //     aria-hidden="true"
                              //   ></i>
                              // </span>
                            }
                            {/* <button
                        onClick={() => {
                          history.push(`/chat?id=654a1108e290e66bc644db97`);
                        }}
                        className="btn btn-primary starreview"
                      >
                       <i className="fa fa-comments"></i>
                        
                      </button> */}
                          </td>
                          {/* <span
                                  className="icon_missed"
                                  onClick={() => {
                                    document
                                      .getElementById("OpenModel2")
                                      .click();
                                    setform({ ...form, rate_to: itm?._id });
                                  }}
                                  title="chat"
                                >
                                  {" "}
                                  <i
                                    class="fa fa-comment "
                                    aria-hidden="true"
                                  ></i>
                                </span> */}
                        </tr>
                      );
                    })}
                  </tbody>


                </table>
                {data?.length < 1 ?

                  <div

                    className="  d-flex justify-content-center align-item-center no_data"
                  >
                    <span className="text-lg p-3">
                      No data to show here !
                    </span>{" "}
                  </div> : null}
                {total > filters.count ? (
                  <div className="d-flex align-items-center justify-content-between tablebotom mt-3">
                    <select

                      className="table_select"
                      value={filters?.count}
                      onChange={(e) => {
                        setFilter({ ...filters, count: e.target.value });
                      }}
                    >
                      <option value="5">5</option>
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                    </select>
                    <Pagination
                      activePage={filters?.page}
                      itemsCountPerPage={filters?.count}
                      totalItemsCount={total}
                      pageRangeDisplayed={3}
                      onChange={handlePagination}
                    //   hideFirstLastPages={true}
                    />
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <button
        type="button"
        id="OpenModel"
        class="btn btn-primary d-none "
        data-toggle="modal"
        data-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form onSubmit={HandleSubmit}>
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Review
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="col-md-12 mb-3">
                  <label>
                    Rating<span className="star">*</span>
                  </label>
                  <div className="rating_stars">
                    <div id="full-stars-example-two">
                      <div class="rating-group">
                        <input
                          disabled
                          class="rating__input rating__input--none"
                          name="rating3"
                          id="rating3-none"
                          value="0"
                          type="radio"
                        />
                        <label
                          aria-label="1 star"
                          class="rating__label"
                          for="rating3-1"
                        >
                          <i class="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          onClick={() => handleStarClick("1")}
                          class="rating__input"
                          name="rating3"
                          id="rating3-1"
                          value="1"
                          type="radio"
                        />
                        <label
                          aria-label="2 stars"
                          class="rating__label"
                          for="rating3-2"
                        >
                          <i class="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          onClick={() => handleStarClick("2")}
                          class="rating__input"
                          name="rating3"
                          id="rating3-2"
                          value="2"
                          type="radio"
                        />
                        <label
                          aria-label="3 stars"
                          class="rating__label"
                          for="rating3-3"
                        >
                          <i class="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          onClick={() => handleStarClick("3")}
                          class="rating__input"
                          name="rating3"
                          id="rating3-3"
                          value="3"
                          type="radio"
                        />
                        <label
                          aria-label="4 stars"
                          class="rating__label"
                          for="rating3-4"
                        >
                          <i class="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          onClick={() => handleStarClick("4")}
                          class="rating__input"
                          name="rating3"
                          id="rating3-4"
                          value="4"
                          type="radio"
                        />
                        <label
                          aria-label="5 stars"
                          class="rating__label"
                          for="rating3-5"
                        >
                          <i class="rating__icon rating__icon--star fa fa-star"></i>
                        </label>
                        <input
                          onClick={() => handleStarClick("5")}
                          class="rating__input"
                          name="rating3"
                          id="rating3-5"
                          value="5"
                          type="radio"
                        />
                      </div>
                    </div>

                    {/* <div class="wrapper_stars">
                                            <input type="checkbox" id="st1" onClick={() => handleStarClick("5")} value="5" />
                                            <label for="st1"></label>
                                            <input onClick={() => handleStarClick("4")} type="checkbox" id="st2" value="4" />
                                            <label for="st2"></label>
                                            <input onClick={() => handleStarClick("3")} type="checkbox" id="st3" value="3" />
                                            <label for="st3"></label>
                                            <input onClick={() => handleStarClick("2")} type="checkbox" id="st4" value="2" />
                                            <label for="st4"></label>
                                            <input onClick={() => handleStarClick("1")} type="checkbox" id="st5" value="1" />
                                            <label for="st5"></label>
                                        </div> */}
                  </div>
                </div>
                <div className="col-md-12 mb-3">
                  <label>
                    Review<span className="star">*</span>
                  </label>
                  <textarea
                    value={form?.review}
                    onChange={(e) => {
                      setform({ ...form, review: e.target.value });
                    }}
                    name=""
                    className="form-control"
                    id=""
                    cols="10"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseReview"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="OpenModel2"
        class="btn btn-primary d-none "
        data-toggle="modal"
        data-target="#exampleModal2"
      >
        Launch demo modal2
      </button>

      <div
        class="modal fade"
        id="exampleModal2"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <form onSubmit={HandleSubmitReason}>
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Reason
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div className="col-md-12 mb-3">
                  <label>
                    Message<span className="star">*</span>
                  </label>
                  <textarea
                    value={form?.reason}
                    onChange={(e) => {
                      setform({ ...form, reason: e.target.value });
                    }}
                    name=""
                    className="form-control"
                    id=""
                    cols="10"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  id="CloseReason"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button type="submit" class="btn btn-primary">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <button
        type="button"
        id="OpenModel3"
        class="btn btn-primary d-none "
        data-toggle="modal"
        data-target="#exampleModal3"
      >
        Launch demo modal2
      </button>
      <div
        class="modal fade"
        id="exampleModal3"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Rescheduled Appointment Details
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div className="col-md-12 mb-3">
                <label>
                  Date<span className="star">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  disabled
                  className="form-control"
                  id=""
                  value={form?.date}
                />
              </div>

              <div className="col-md-12 mb-3">
                <label>
                  Time<span className="star">*</span>
                </label>
                <input
                  type="text"
                  name=""
                  disabled
                  className="form-control"
                  id=""
                  value={form?.time}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <a id="openRemianderEditModal" data-toggle="modal" data-target="#editModal"></a>
        <div className="modal fade" id="editModal" role="dialog" aria-hidden="true">
            <div className="modal-dialog ">
                <div className="modal-content">
                    {/* <div className="modal-header">
                        <h5 className="modal-title"> Message</h5>
                        <button type="button" id="closeEditModal" className="close" data-dismiss="modal" aria-label="Close" title='Close'>
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div> */}
                  <div className="modal_body mt-3 ">
                      <div className="text-center">
                      <img className="imgreminder" src="/assets/img/therpy/Illustration.png"/>
                      <div className="plinetata mt-4">
                        <p className="mb-0">📢 Join Our Video Call Now!</p>
                       
                      </div>
                      <div>
                      <p className="mt-1 mb-4"> 📹 Click the link in your <b>Email</b> to connect and participate. See you there!</p>
                  
                      </div>
                     
                      </div>
                  </div>
               
                </div>
            </div>
        </div>
      <ToastContainer position="top-right" />
      <Footer />
    </>
  );
}

export default ViewAppointment;
