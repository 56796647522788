import React, { useEffect, useState } from "react"
import Html from "./html"
import ApiClient from '../../../methods/api/apiClient';
import { useHistory, useParams } from "react-router-dom";
import loader from "../../../methods/loader";
import "./style.scss";
import { toast } from "react-toastify";
import countryModel from "../../../models/country.model"
import { useSelector } from "react-redux";
import Layout from "../../../components/global/layout";
const ProductDetail1=()=>{
    const user = useSelector(state => state.user)
    const [filters, setFilter] = useState({ page: 1, count: 50, search: '', regionId: '', countryId: '', continentId: '' })
    const {id}=useParams()
    const [detail,setDetail]=useState()
    const [dynamicPrice,setDynamicPrice]=useState([])
    const [loading,setLoader]=useState([])
    const [selectedDynamicPrice,setSelectedDynamicPrice]=useState({id:''})
    const [tab,setTab]=useState('general')
    const history=useHistory()
    const [image,setImage]=useState('/assets/img/placeholder.png')
    const [categories, setCategories] = useState([])
    const [form, setForm] = useState({category:''})
    const [status, setStatus] = useState('')

    const getData=(tab='',s=status)=>{
        loader(true)
        setLoader(true)
        ApiClient.get('api/product/detail',{id:id,status:s}).then(res=>{
            if(res.success){
                let data=res.data
                let priceOptions=data.priceOptions.sort((a,b)=>{
                   return b.price-a.price
                })
                if(priceOptions.length){
                    data.advertisedPrice=priceOptions[0].price
                }
                setDetail(res.data)
                if(res.data?.images?.length){
                    setImage(res.data.images[0].largeSizeUrl)
                }
                setForm({...form,category:data?.category?._id})
            }
            loader(false)
            setTimeout(() => {
                setLoader(false)
                if(tab) tabChange(tab)
            }, 100);
          
        })
    }
    const filter = (p = {}) => {
        setFilter({ ...filters, page: 1, ...p })
        getData({ page: 1, ...p })
    }
    const [countrydata, setcountrydata] = useState([]);
    const GetCountryData = () => {
        ApiClient.get(`api/country/listing`, { status: 'active', count: 500 }).then(res => {
            if (res.success == true) {
                setcountrydata(res.data)
            }
        })
    }
    const [continentdata, setcontinentdata] = useState([]);
    const GetContinentData = () => {
        ApiClient.get(`api/continent/listing`, { status: 'active', count: 500 }).then(res => {
            if (res.success == true) {
                setcontinentdata(res.data);
            }
        })
    }

    const [regiondata, setregiondata] = useState([])
    const GetRegionData = () => {
        ApiClient.get(`api/region/listing`, { status: 'active', count: 500 }).then(res => {
            if (res.success == true) {
                setregiondata(res.data)
            }
        })
    }
    const [citydata, setcitydata] = useState([])
  
    const Getcitydata = () => {
        ApiClient.get(`api/city/listing`, { status: 'active', count: 500,country:setcit() }).then(res => {
            if (res.success == true) {
                setcitydata(res.data)
            }
        })
    }


useEffect(()=>{
    GetCountryData()
    getDynamicPriceList()
    getCategories()
   
},[])
    const tabChange=(t)=>{
        setTab(t)
        setSelectedDynamicPrice({ id: '' })
        if(t=='calendar'){
            setTimeout(()=>{
                let el=document.getElementById("setCalendar")
                if(el) el.click()
            },1)
        }
    }
  
    useEffect(()=>{
        getData()
    },[])

    const getCategories=()=>{
        ApiClient.get('api/categories/listing',{catType:'64a7d198fa039f179c0320ca',status:'active'}).then(res=>{
            if(res.success){
                setCategories(res.data.map(itm=>{
                    return {value:itm.id,label:itm.name}
                }))
            }
        })
    }

    const categoryVal=(category='')=>{
        let ext=categories&&categories.find(item=>item.value==category)
        return ext?ext:''
    }

    const updateCategory=(category)=>{
        ApiClient.put('api/product/update',{id:id,category:category}).then(res=>{
            if(res.success){
                setForm({...form,category:category})
                toast.success(res.message)
            }
        })
    }

    const editdynamicPricing=(item)=>{
        setTab('dynamicPricing')
        setSelectedDynamicPrice({...item,isCopy:false,isView:false})
        // history.push(`/dynamicprice/${item?.dynamicPricingId?.type}/edit/${item?.id}/false?page=product&productCode=${detail?.productCode}`)
    }

    const viewDynamicPricing=(item)=>{
        setTab('dynamicPricing')
        setSelectedDynamicPrice({...item,isCopy:false,isView:true})
        // history.push(`/dynamicprice/${item?.dynamicPricingId?.type}/edit/${item?.id}/false?page=product&productCode=${detail?.productCode}`)
    }
    

    const deletePricing=(id)=>{
        if(window.confirm("Do you Want to Delete this Dynamic Pricing")){
            ApiClient.delete('api/product/pricing/delete',{id:id}).then(res=>{
                if(res.success){
                    toast.success(res.message)
                    getData()
                }
            })
        }
    }

    const getDynamicPriceList = () => {
        let ext = countryModel.search(user.country)
        let country = 'us'
        if (ext) {
            country = ext.cca2.toLowerCase()
        }
        ApiClient.get(`api/dynamic/pricings`, {country:country}).then(res => {
            if (res.success) {
                setDynamicPrice(res.data)
            }
        })
    }

    const apply=(id)=>{
        let ext = dynamicPrice.find(itm => itm.id == id)
        if (ext){
            // delete ext.id
            // delete ext._id
        }
        setSelectedDynamicPrice({...ext,isCopy:true})

        // history.push(`/dynamicprice/${ext?.type}/edit/${selectedDynamicPrice.id}/true?page=product&productCode=${detail?.productCode}&productId=${id}`)
    }

    const changeStatus=(s)=>{
        setStatus(s)
    }

    return <>
    <Layout>
    <Html
    status={status}
    changeStatus={changeStatus}
    form={form}
    countrydata={countrydata}
    user={user}
    updateCategory={updateCategory}
    categories={categories}
    categoryVal={categoryVal}
    apply={apply}
    tab={tab}
    filter={filter}
    loading={loading}
    getData={getData}
    filters={filters}
    selectedDynamicPrice={selectedDynamicPrice}
    setSelectedDynamicPrice={setSelectedDynamicPrice}
    dynamicPrice={dynamicPrice}
    deletePricing={deletePricing}
    editdynamicPricing={editdynamicPricing}
    viewDynamicPricing={viewDynamicPricing}
    tabChange={tabChange}
    detail={detail}
    image={image}
    setImage={setImage}
    regiondata={regiondata}
    continentdata={continentdata}
    citydata={citydata}
    
    />
    </Layout>
    </>
}

export default ProductDetail1