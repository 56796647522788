import React, { useEffect } from 'react';
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from 'react-toasts';
import { PersistGate } from 'redux-persist/es/integration/react';
import "react-datepicker/dist/react-datepicker.css";
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import configureStore from './config';
import { createBrowserHistory } from 'history';
import Login from './pages/Login';
import "react-pagination-js/dist/styles.css";
import './scss/main.scss';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import SignUp from './pages/Signup';
import Forgotpassword from './pages/Forgotpassword';
import Resetpassword from './pages/Resetpassword';
import Dashboard from './pages/DashboardPages';
import { Auth } from './methods/auth';
import Profile from './pages/Profile';
import EditProfile from './components/Profile/Edit';
import ChangePassword from './components/Profile/ChangePassword';
import Plans from './pages/Plan';
import ProductDetail from './pages/Products/Detail/ProductDetail';
import ProductDetail1 from './pages/Products/Detail1/ProductDetail';
import CompanyDetails from './pages/CompanyDetails';
import BookingSystem from './pages/BookingSystem';
// import Resellerdashboard from './pages/ResellerDashboard';
import ActivePlan from './pages/Plan/ActivePlan';
import Payment from './pages/Payment';
import Calendar from './pages/Calendar';
import SalesData from './pages/SalesDashboard/salesData';
import Reviews from './pages/Reviews';
import Thanku from './pages/Thanku';
export const history = createBrowserHistory();
/************ store configration *********/
import All_review from './pages/reviewsAll';
import OTPpage from './pages/Signup/OTPpage';
import HomePage from './pages/HomePage/index'
import About from './pages/HomePage/About/about';
import Question from './pages/HomePage/Questions/questions';
import ConfirmCode from './pages/ConfirmCode/ConfirmCode';
import TherspistQuestions from './pages/TherspistQuestions/TherspistQuestions';
import NextWelcomeQuestions from './pages/HomePage/Questions/next';
import Questions from './pages/TherspistQuestions/Questions';
import Appointment from './pages/Appointment/Appointment';
import Faq from './pages/FAQs/Faq';
import Advise from './pages/Advise/Advise';
import Review from './pages/Review/Review';
import Contact from './pages/Contact/Contact';
import chat from './pages/Chat/Chat';
import ViewAppointment from './pages/Appointment/ViewAppointment';
import zoomVideo from './pages/Zoom/zoomVideo';
import TermConditions from './pages/TermConditions';
import hotLine from './pages/HotLine/hotLine';
import Reason from './pages/Reason/Reason';
import Childline from './pages/HotLine/Childline';
import PaymentSuccess from './pages/HomePage/Questions/newFile';
import plansSection from './pages/plansSection/planSection';
import privacy from './pages/privacy/privacy';

const { persistor, store } = configureStore(history);

export default () => {

    return (<>
        <Provider store={store}>
            <PersistGate loading={'loading ...'} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <Router>
                        <Switch>
                        <Route exact={true} store={store} path="/plans" component={plansSection} />
                        <Route exact={true} store={store} path="/chat" component={chat} />
                        <Route exact={true} store={store} path="/privacy" component={privacy} />
                        <Route exact={true} store={store} path="/term-condition" component={TermConditions} />
                        <Route exact={true} store={store} path="/hotline" component={hotLine} />
                        <Route exact={true} store={store} path="/childline" component={Childline} />
                        <Route exact={true} store={store} path="/recent" component={zoomVideo} />
                            <Route exact={true} store={store} path="/" component={HomePage} />
                            <Route exact={true} store={store} path="/about" requireAuth={Auth} component={About} />
                            <Route exact={true} store={store} path="/login" component={Login} />
                            <Route exact={true} store={store} path="/questions" component={Question} />
                            <Route exact={true} store={store} path="/otppage" component={OTPpage} />
                            <Route exact={true} requireAuth={Auth} store={store} path="/dashboard" component={Dashboard} />
                            <Route exact={true} requireAuth={Auth} store={store} path="/profile" component={Profile} />
                            <Route exact={true} requireAuth={Auth} store={store} path="/editprofile" component={EditProfile} />
                            <Route exact={true} requireAuth={Auth} store={store} path="/change-password" component={ChangePassword} />
                            <Route exact={true} store={store} path="/calendar" component={Calendar} />
                            <Route exact={true} store={store} path="/activeplan" component={ActivePlan} />
                            <Route exact={true} store={store} path="/payment" component={Payment} />
                            <Route exact={true} store={store} path="/product/detail1/:id" component={ProductDetail} />
                            <Route exact={true} store={store} path="/product/detail/:id" component={ProductDetail1} />
                            <Route exact={true} store={store} path="/company" component={CompanyDetails} />
                            <Route exact={true} store={store} path="/booking" component={BookingSystem} />
                            <Route exact={true} store={store} path="/signup" component={SignUp} />
                            <Route exact={true} store={store} path="/forgotpassword" component={Forgotpassword} />
                            <Route exact={true} store={store} path="/resetpassword" component={Resetpassword} />
                            <Route exact={true} store={store} path="/confirmcode" component={ConfirmCode} />
                            <Route exact={true} store={store} path="/therspistquestions" component={TherspistQuestions} />
                            <Route exact={true} store={store} path="/therapistquest" component={Questions} />
                            <Route exact={true} store={store} path="/next" component={NextWelcomeQuestions} />
                            <Route exact={true} store={store} path="/payment-success" component={PaymentSuccess} />
                            <Route exact={true} store={store} path="/saledata" component={SalesData} />
                            <Route exact={true} requireAuth={Auth}  store={store} path="/appointment" component={Appointment} />
                            <Route exact={true} requireAuth={Auth}  store={store} path="/reason" component={Reason} />     
                            <Route exact={true} requireAuth={Auth}  store={store} path="/myappointment" component={ViewAppointment} />
                            <Route exact={true} requireAuth={Auth}  store={store} path="/term-condition" component={TermConditions} />
                            <Route exact={true} path="/reviews" store={store} requireAuth={Auth} component={Reviews} />
                            <Route exact={true} path="/plans" component={Plans} />
                            <Route exact={true} path="/faq" component={Faq} />
                            <Route exact={true} path="/contact" component={Contact} />
                            <Route exact={true} path="/advise" component={Advise} />
                            <Route exact={true} path="/review" component={Review} />
                            {/* Cards */}
                            <Route exact={true} path="/thanku" store={store} component={Thanku} />
                            <Route exact={true} path="/allreview" store={store} component={All_review} />
                            <Route exact path="/">
                                <Redirect to="/login" />
                            </Route>
                        </Switch>
                    </Router>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
        <div id="loader" className="loaderDiv d-none">
            <div>
                <img src="/assets/img/loader.gif" alt="logo" className="loaderlogo" />
            </div>
        </div>
        <ToastsContainer
            position={ToastsContainerPosition.TOP_RIGHT}
            store={ToastsStore}
        />
    </>
    );
};
