import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Html = ({ ListItemLink, tabclass, urlAllow, route }) => {
  const dispatch = useDispatch();
  const loication = useLocation()
  const user = useSelector(state => state.user)
  const navigate = useHistory();

  return (
    <ul className="nav flex-column siderbar_layout" component="siderbar">
      {/* <li>
      <form className='headerSearch'>
    <input type="text" placeholder="Search..." className="Searchbar"></input>
    <i className="fa fa-search"></i>
     <i className="fa fa-times" aria-hidden="true"></i>
  </form>
      </li> */}
      {user?.subscriptionId || user?.on_trial ?
        <>
          {urlAllow('readDashboard') ? <>
            <ListItemLink to="/dashboard">
              <i class="material-icons  mr-2" title="Dashboard">dashboard</i>
              <span class="side_head">Dashboard</span>
            </ListItemLink>
          </> : <></>}
          {urlAllow('/HomePage') ? <>
            <ListItemLink to="/HomePage">
            <i class="material-icons  mr-2" title="Dashboard">dashboard</i>
              <span class="side_head">Home Page</span>
            </ListItemLink>
          </> : <></>}

          {/* {urlAllow('readSales') ? <>
            <li className="nav-item">
              <a className={`col_side ${tabclass('sales') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#pricing" role="button" aria-expanded="false" aria-controls="pricing">
                <div className="sidees">
                  <i class="material-icons  sidenv mr-2" title="Sales Dashboard">point_of_sale</i>
                  <span >Sales</span>
                </div>
                <div>
                  <span className="side_icon">
                    <i class="material-icons ">keyboard_arrow_right</i>
                  </span>
                </div>
              </a>
              <div id="pricing" class={`collapse ${tabclass('sales') ? 'show' : ''}`}>
                <div class="card card-body sides">
                  <ListItemLink to="/sales" >
                    <i class="material-icons sidenv mr-2" title="Sales Dashboard">point_of_sale</i>
                    <span class="side_head">Sales Dashboard</span>
                  </ListItemLink>
                  <ListItemLink to="/saleData" >
                    <i class="material-icons sidenv mr-2" title="Sales Data">receipt</i>
                    <span class="side_head">Sales Data</span>
                  </ListItemLink>
                </div>
              </div>
            </li>
          </> : <></>} */}

          {/* {urlAllow('readReviews') ? <>
            <li className="nav-item">
              <a className={`col_side ${tabclass('reviews') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#review" role="button" aria-expanded="false" aria-controls="pricing">
                <div className="sidees">
                  <i class="material-icons  sidenv mr-2" title="Reviews">star</i>
                  <span >Reviews</span>
                </div>
                <div>
                  <span className="side_icon">
                    <i class="material-icons ">keyboard_arrow_right</i>
                  </span>
                </div>
              </a>
              <div id="review" class={`collapse ${tabclass('reviews') ? 'show' : ''}`}>
                <div class="card card-body sides">
                  <ListItemLink to="/reviewDashboard">
                    <i class="material-icons sidenv mr-2" title="Review Dashboard">star</i>
                    <span class="side_head">Review Dashboard</span>
                  </ListItemLink>
                  <ListItemLink to="/reviews">
                    <i class="material-icons sidenv mr-2" title="Sales Dashboard">star</i>
                    <span class="side_head">Reviews Data</span>
                  </ListItemLink>

                </div>
              </div>
            </li>
          </> : <></>} */}

          {/* {urlAllow('readProducts') ? <>
            <li className="nav-item">
              <a className={`col_side ${tabclass('productdashboard') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#product" role="button" aria-expanded="false" aria-controls="product">
                <div className="sidees">
                  <i class="material-icons  sidenv mr-2" title="Product Dashboard">space_dashboard</i>
                  <span >Product</span>
                </div>
                <div>
                  <span className="side_icon">
                    <i class="material-icons ">keyboard_arrow_right</i>
                  </span>
                </div>
              </a>
              <div id="product" class={`collapse ${tabclass('productdashboard') ? 'show' : ''}`}>
                <div class="card card-body sides">
                  <ListItemLink to="/productdashboard" >
                    <i class="material-icons sidenv mr-2" title="Product Dashboard">inventory</i>
                    <span class="side_head">Product Dashboard</span>
                  </ListItemLink>

                  <ListItemLink to="/productData" >
                    <i class="material-icons sidenv mr-2" title="Product Data">receipt</i>
                    <span class="side_head">Product Data</span>
                  </ListItemLink>

                </div>
              </div>
            </li>
          </> : <></>} */}

          {/* <li className="nav-item">
            <a class={`col_side ${tabclass('dynamicPricing') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#collapseExampledynamicPricing" role="button" aria-expanded="false" aria-controls="collapseExampledynamicPricing">
              <div className="sidees">
                <i class="material-icons mr-2" title="Dynamic Pricing" >subscriptions</i>
                <span >Dynamic Pricing</span>
              </div>
              <div>
                <span className="side_icon">
                  <i class="material-icons">keyboard_arrow_right</i>
                </span>
              </div>
            </a>

            <div class={`collapse ${tabclass('dynamicPricing') ? 'show' : ''}`} id="collapseExampledynamicPricing">
              <div class="card card-body sides">
                {urlAllow(`dynamicpricelist`) ? <>
                  <ListItemLink to={`/dynamicpricelist`}>
                    <i class="material-icons sidenv" title="Dynamic Pricing">subscriptions</i>
                    <span>Manage Rules </span>
                  </ListItemLink>
                </> : <></>}

              </div>
            </div>
          </li> */}

          {/* <li className="nav-item">
            <a className={`col_side ${tabclass('marketing') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#marketing" role="button" aria-expanded="false" aria-controls="marketing">
              <div className="sidees">
                <i class="material-icons  sidenv mr-2" title="Product Dashboard">line_axis</i>
                <span >Marketing</span>
              </div>
              <div>
                <span className="side_icon">
                  <i class="material-icons ">keyboard_arrow_right</i>
                </span>
              </div>
            </a>
            <div id="marketing" class={`collapse ${tabclass('marketing') ? 'show' : ''}`}>
              <div class="card card-body sides">
                <ListItemLink to="/marketing/dashboard">
                  <i class="material-icons sidenv mr-2" title="Product Dashboard">inventory</i>
                  <span class="side_head">Marketing Dashboard</span>
                </ListItemLink>

                <ListItemLink to="/marketing/google-analytics" disabled>
                  <i class="material-icons sidenv mr-2" title="Product Data">receipt</i>
                  <span class="side_head">Google Analytics</span>
                </ListItemLink>

                <ListItemLink to="/marketing/google-ads" disabled>
                  <i class="material-icons sidenv mr-2" title="Product Data">receipt</i>
                  <span class="side_head">Google Ads</span>
                </ListItemLink>

                <ListItemLink to="/marketing/facebook-ads" disabled>
                  <i class="material-icons sidenv mr-2" title="Product Data">receipt</i>
                  <span class="side_head">Facebook Ads</span>
                </ListItemLink>

              </div>
            </div>
          </li> */}

          {/* <li className="nav-item">
            <a className={`col_side ${tabclass('financial') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#financial" role="button" aria-expanded="false" aria-controls="financial">
              <div className="sidees">
                <i class="material-icons  sidenv mr-2" title="Product Dashboard">line_axis</i>
                <span >Financial</span>
              </div>
              <div>
                <span className="side_icon">
                  <i class="material-icons ">keyboard_arrow_right</i>
                </span>
              </div>
            </a>
            <div id="financial" class={`collapse ${tabclass('financial') ? 'show' : ''}`}>
              <div class="card card-body sides">
                <ListItemLink to="/financial/dashboard">
                  <i class="material-icons sidenv mr-2" title="Product Dashboard">inventory</i>
                  <span class="side_head">Financial Dashboard</span>
                </ListItemLink>

                <ListItemLink to="/financial/data" disabled>
                  <i class="material-icons sidenv mr-2" title="Product Data">receipt</i>
                  <span class="side_head">Financial Data</span>
                </ListItemLink>

                <ListItemLink to="/financial/invoices" disabled>
                  <i class="material-icons sidenv mr-2" title="Product Data">receipt</i>
                  <span class="side_head">Invoices</span>
                </ListItemLink>

              </div>
            </div>
          </li> */}

          {urlAllow('readCompany') ? <>
          </> : <></>}

          {/* {urlAllow('readCRM') ? <>
            <li className="nav-item">
              <a className={`col_side ${tabclass('crm') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#crm" role="button" aria-expanded="false" aria-controls="crm">
                <div className="sidees">
                  <i class="material-icons  mr-2" title="Booking">menu_book</i>
                  <span >CRM</span>
                </div>
                <div>
                  <span className="side_icon">
                    <i class="material-icons">keyboard_arrow_right</i>
                  </span>
                </div>
              </a>
              <div id="crm" class={`collapse ${tabclass('crm') ? 'show' : ''}`}>
                <div class="card card-body sides">

                  <ListItemLink to="/databaseReseller">
                    <i class="material-icons  mr-2">star</i>
                    <span class="side_head">List of Resellers</span>
                  </ListItemLink>
                  <ListItemLink to="/crm">
                    <i class="material-icons  mr-2">star</i>
                    <span class="side_head">List of Contracts</span>
                  </ListItemLink>
                </div>
              </div>
            </li>
          </> : <></>} */}

          {/* <li className="nav-item">
            <a className={`col_side ${tabclass('xero') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#xero" role="button" aria-expanded="false" aria-controls="xero">
              <div className="sidees">
                <i class="material-icons  mr-2" title="Booking">menu_book</i>
                <span >Xero</span>
              </div>
              <div>
                <span className="side_icon">
                  <i class="material-icons">keyboard_arrow_right</i>
                </span>
              </div>
            </a>
            <div id="xero" class={`collapse ${tabclass('xero') ? 'show' : ''}`}>
              <div class="card card-body sides">

                <ListItemLink to="/xero/invoices">
                  <i class="material-icons  mr-2">star</i>
                  <span class="side_head">List of Invoices</span>
                </ListItemLink>
                <ListItemLink to="/xero/transactions">
                  <i class="material-icons  mr-2">star</i>
                  <span class="side_head">List of Transactions</span>
                </ListItemLink>
              </div>
            </div>
          </li> */}

          {/* <li className="nav-item">
            <a className={`col_side ${tabclass('settings') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#settings" role="button" aria-expanded="false" aria-controls="settings">
              <div className="sidees">
                <i class="material-icons  sidenv mr-2" title="Product Dashboard">line_axis</i>
                <span >Settings</span>
              </div>
              <div>
                <span className="side_icon">
                  <i class="material-icons ">keyboard_arrow_right</i>
                </span>
              </div>
            </a>
            <div id="settings" class={`collapse ${tabclass('settings') ? 'show' : ''}`}>
              <div class="card card-body sides">
                <ListItemLink to="/company">
                  <i class="material-icons  mr-2">business</i>
                  <span class="side_head">Company Details</span>
                </ListItemLink>

                <ListItemLink to="/activeplan">
                  <i class="material-icons  mr-2">subscriptions</i>
                  <span class="side_head">Plans</span>
                </ListItemLink>

                <ListItemLink to="/card">
                  <i class="material-icons  mr-2">monetization_on</i>
                  <span class="side_head">Billing</span>
                </ListItemLink>

                <li className="nav-item">
                  <a className={`col_side ${tabclass('api') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#booking" role="button" aria-expanded="false" aria-controls="booking">
                    <div className="sidees">
                      <i class="material-icons  mr-2" title="Booking">menu_book</i>
                      <span >Data Connections</span>
                    </div>
                    <div>
                      <span className="side_icon">
                        <i class="material-icons">keyboard_arrow_right</i>
                      </span>
                    </div>
                  </a>
                  <div id="booking" class={`collapse subMenuDropdown ${tabclass('api') ? 'show' : ''}`}>
                    <div class="card card-body sides">
                      {urlAllow('readBilling') ? <>
                        <ListItemLink to="/pos">
                          <i class="material-icons  mr-2">book</i>
                          <span class="side_head">Booking System</span>
                        </ListItemLink>
                      </> : <></>}
                      <ListItemLink to="/api/review-connected">
                        <i class="material-icons  mr-2">star</i>
                        <span class="side_head">Reviews</span>
                      </ListItemLink>
                      <ListItemLink to="/marketing-analytics">
                        <i class="material-icons  mr-2">star</i>
                        <span class="side_head">Marketing Analytics</span>
                      </ListItemLink>
                      <ListItemLink to="/api/xero-connect">
                        <i class="material-icons  mr-2">star</i>
                        <span class="side_head">Xero Connect</span>
                      </ListItemLink>
                    </div>
                  </div>
                </li>

                <li className="nav-item">
                  <a className={`col_side ${tabclass('users') ? '' : 'collapsed'}`} data-bs-toggle="collapse" href="#users" role="button" aria-expanded="false" aria-controls="users">
                    <div className="sidees">
                      <i class="material-icons  sidenv mr-2" title="Product Dashboard">space_dashboard</i>
                      <span >User Management</span>
                    </div>
                    <div>
                      <span className="side_icon">
                        <i class="material-icons ">keyboard_arrow_right</i>
                      </span>
                    </div>
                  </a>
                  <div id="users" class={`collapse subMenuDropdown ${tabclass('users') ? 'show' : ''}`}>
                    <div class="card card-body sides">
                      <ListItemLink to="/users">
                        <i class="material-icons mr-2">notes</i>
                        <span class="side_head">List of Users</span>
                      </ListItemLink>

                      <ListItemLink to="/roles">
                        <i class="material-icons  mr-2">manage_accounts</i>
                        <span class="side_head">User Roles</span>
                      </ListItemLink>

                    </div>
                  </div>
                </li>


              </div>
            </div>
          </li> */}
        </>
        :
        <>
          <ListItemLink to="/company">
            <i class="material-icons  mr-2">business</i>
            <span class="side_head">Company Details</span>
          </ListItemLink>
          {user?.companyName ?
            <ListItemLink to="/activeplan">
              <i class="material-icons  mr-2">subscriptions</i>
              <span class="side_head">Plans</span>
            </ListItemLink>
            : null}
        </>
      }

    </ul>
  );
}

export default Html