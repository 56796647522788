import React, { useEffect, useState } from "react";
import './Helpline.css';
import Footer from "../HomePage/Footer/Footer";
import Header from "../HomePage/Header";

const Childline = () => {

    return (
        <>
            <div className="header-dark-page ">
                <Header />
            </div>
            <div className="helpline p-60">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-md-8 col-lg-9 mx-auto">
                            <div className=" helpline-container">
                                <div className=" row">
                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="wrapper_helpline">
                                            <h3 className="m-0">Child Helpline</h3>
                                            <div className="helpline-info mt-4">
                                                <p className="fs14">Please call the National Center of Missing & Exploited Children’s</p>
                                                <p className="textp mt-2"> <i className="fa fa-phone"></i>  <a href="tel:18008435678">1800-843-5678</a></p>

                                                {/* You can add more information or options here */}
                                            </div>

                                        </div>
                                    </div>

                                    <div className="col-sm-12 col-md-6 col-lg-6">
                                        <div className="imghelpline">
                                            <img src="/assets/img/helpline.png" className='img-fluid2' />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default Childline;
