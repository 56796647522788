import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../Header";
import Footer from "../Footer/Footer";
import ApiClient from "../../../methods/api/apiClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../../methods/loader";
import { ToastContainer, toast } from "react-toastify";

const Question = () => {
  const history = useHistory();
  const [isScrolled, setIsScrolled] = useState(false);
  const [data, setData] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [questions, AddQuestions] = useState([]);
  const [selectedAnswersData, setSelectedAnswersData] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [id, setId] = useState("");
  const [selectedDropdownValue, setSelectedDropdownValue] = useState("");

  const getData = () => {
    loader(true);
    ApiClient.get("all/questions/list", {
      status: "active",
      sortBy: "order asc",
    }).then((res) => {
      if (res.success) {
        setData(res.data?.data);
      }
      loader(false);
    });
  };

  useEffect(() => {
    getData();

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleDropdownSelection = (e) => {
    setSelectedDropdownValue(e.target.value);
  };

  const handleDropdownNextButtonClick = () => {
    const questionId = data[currentQuestion]?.id;
    const ansId = data[currentQuestion];
    console.log(id);
    const selectedAnswerData = {
      ques_id: questionId,
      ans_id: [id],
    };

    setSelectedAnswersData((prevData) => [...prevData, selectedAnswerData]);
    // setSelectedAnswersData(prevData => [...prevData, selectedAnswerData]);
    const nextQuestion = currentQuestion + 1;
    if (nextQuestion < data.length) {
      if (id) {
        setCurrentQuestion(nextQuestion);
        setSelectedDropdownValue("");
      }
    } else {
      console.log("End of questions reached.");
      localStorage.setItem("questionJson", JSON.stringify(selectedAnswersData));
      history.push("/signup");
    }
  };
  console.log(selectedAnswersData);
  const handleAnswerOptionClick = (answerIndex) => {
    const questionId = data[currentQuestion]?.id;
    const questionType = data[currentQuestion]?.answer_type;
    const selectedOptionId = data[currentQuestion]?.options[answerIndex]?.id;
    console.log(
      data[currentQuestion]?.question,
      data[currentQuestion]?.options[answerIndex]?.id
    );
    if (
      data[currentQuestion]?.question ==
      "When was the last time you thought about suicide ?" &&
      data[currentQuestion]?.options[answerIndex]?.id !=
      "e6f4d841-df8f-414c-b9b4-475ba38ed79a1703487444259"
    ) {
      history.push("/signup");
    }
    if (questionType === "single") {
      const selectedAnswerData = {
        ques_id: questionId,
        ans_id: [selectedOptionId],
      };
      console.log(questionId, "=============", selectedOptionId);
      setSelectedAnswersData((prevData) => [...prevData, selectedAnswerData]);

      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < data.length) {
        setCurrentQuestion(nextQuestion);
        setSelectedAnswer(null);
      } else {
        console.log("End of questions reached.");

        setTimeout(() => {
          localStorage.setItem(
            "questionJson",
            JSON.stringify(selectedAnswersData)
          );
          history.push("/signup");
        }, 1000);
      }
    }
  };

  const handleCheckboxChange = (selectedOptionId) => {
    const currentIndex = selectedOptions.indexOf(selectedOptionId);
    const newSelectedOptions = [...selectedOptions];

    if (currentIndex === -1) {
      newSelectedOptions.push(selectedOptionId);
    } else {
      newSelectedOptions.splice(currentIndex, 1);
    }

    setSelectedOptions(newSelectedOptions);
  };

  // Function to handle clicking the "Next" button for dropdown
  const handleNextButtonClick = () => {
    const questionId = data[currentQuestion]?.id;
    const questionType = data[currentQuestion]?.answer_type;

    if (questionType === "multiple") {
      const selectedAnswerData = {
        ques_id: questionId,
        ans_id: selectedOptions,
      };

      setSelectedAnswersData((prevData) => [...prevData, selectedAnswerData]);

      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < data.length) {
        console.log(selectedOptions, "==================================");
        if (selectedOptions.length != 0) {
          setCurrentQuestion(nextQuestion);
        }
        // setSelectedOptions([]); // Reset selected options for the new question
      } else {
        console.log("End of questions reached.");
        localStorage.setItem(
          "questionJson",
          JSON.stringify(selectedAnswersData)
        );
        history.push("/signup");
      }
    }
    if (questionType === "dropdown") {
      const selectedAnswerData = {
        ques_id: questionId,
        ans_id: selectedOptions,
      };

      setSelectedAnswersData((prevData) => [...prevData, selectedAnswerData]);

      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < data.length) {
        console.log(data, "==========123=123=1=231=231=23=123==");
        if (selectedOptions.length != 0) {
          // setCurrentQuestion(nextQuestion);
        }
        // setSelectedOptions([]); // Reset selected options for the new question
      } else {
        console.log("End of questions reached.");
        localStorage.setItem(
          "questionJson",
          JSON.stringify(selectedAnswersData)
        );
        history.push("/signup");
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const nickname = e.target[0]?.value;

    if (nickname.length < 3) {
      toast.error('Please Enter Nickname Above Three Letter')
    } else {
      setCurrentQuestion(1);
      console.log(nickname);
    }
  };

  useEffect(() => {
    AddQuestions(selectedAnswersData);
    console.log(questions);
  }, [selectedAnswersData]);

  localStorage.setItem("questionJson2", JSON.stringify(questions));

  return (
    <>
      <div className="bg_leaf position-relative">
        <img
          src="/assets/img/therpy/left1.png"
          className="img-fluid leftside"
        />
        <img
          src="/assets/img/therpy/right1.png"
          className="img-fluid rightside"
        />
        <div className={`header-dark-page ${isScrolled ? "scrollsticky" : ""}`}>
          <Header />
        </div>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="ques_wrapper p-60">
                <div className="question_inner">
                  <div className="main-title text-center">
                    <h2 className="h2title">
                      Help us match you to the right therapist
                    </h2>
                    <p className="ptext">
                      It's important to have a therapist who you can establish a
                      personal connection with. The following questions are
                      designed to match you to a licensed therapist based on
                      your therapy needs and personal preferences.
                    </p>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-12 col-sm-12 col-md-10 col-lg-7 mx-auto">
              <div className="starting_quest">
                {data[currentQuestion]?.answer_type == "text"  ?
                  <div className="heading_tiled">
                    <h3 className="h3class">
                    {data[currentQuestion]?.question}
                    </h3>
                    <div className="answing_list">
                      <form onSubmit={handleSubmit}
                      >
                        <div className="onetworadio">
                          <div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Prefered Nickname"
                              required
                              onChange={(e) => {
                                localStorage.setItem('nickname', e.target.value)
                              }}
                            // id={`option-${currentQuestion}-${index}`}
                            // checked={selectedAnswer === index}
                            // onChange={() => handleAnswerOptionClick(index)}
                            />

                          </div>
                        </div>
                        <div className="d-flex justify-content-end">
                          <button className="btn btn-primary" type="submit" onClick={() => { }}>Submit</button>
                        </div>
                      </form>
                    </div>
                  </div> :
                  <div className="heading_tiled">
                    {/* <h3 className='h3class'>What type of therapy you are looking for?</h3> */}
                    <h3 className="h3class">{data[currentQuestion]?.question}</h3>
                    {data[currentQuestion]?.answer_type !== "dropdown" && (
                      <div className="answing_list">
                        {data[currentQuestion]?.options?.map(
                          (answerOption, index) => (
                            <div key={index} className="onetworadio">
                              {data[currentQuestion]?.answer_type === "single" ? (
                                <div>
                                  <input
                                    type="radio"
                                    className="d-none hideradio"
                                    id={`option-${currentQuestion}-${index}`}
                                    checked={selectedAnswer === index}
                                    onChange={() =>
                                      {
                                        console.log()
                                        if( data[currentQuestion]?.isSuicidalQuestion==true && answerOption?.option=='Yes'){
                                          history.push('/hotline')
                                        }else if (
                                           data[currentQuestion]?.isChildAbuse==true && answerOption?.option=='Yes'
                                        ){
                                          history.push('/childline')
                                        }else{

                                          handleAnswerOptionClick(index)
                                        }
                                      }
                                    }
                                  />
                                  <label
                                    htmlFor={`option-${currentQuestion}-${index}`}
                                    className="label_answer"
                                  >
                                    {answerOption.option}
                                  </label>
                                  {/* {currentQuestion == data?.length - 1 && (
                                    <label
                                      onClick={() => {
                                        if(data[currentQuestion].isChildAbuse){

                                          history.push("/childline")
                                        }
                                        handleAnswerOptionClick(index)
                                      }
                                      }
                                      className="label_answer"
                                    >
                                      Yes
                                    </label>
                                  )} */}
                                </div>
                              ) : data[currentQuestion]?.answer_type ===
                                "multiple" ? (
                                <div>
                                  <input

                                    type="checkbox"
                                    className="d-none hideradio"
                                    id={`option-${currentQuestion}-${index}`}
                                    checked={selectedOptions.includes(
                                      answerOption.id
                                    )}
                                    onChange={() =>
                                      handleCheckboxChange(answerOption.id)
                                    }
                                  />
                                  <label
                                    htmlFor={`option-${currentQuestion}-${index}`}
                                    className="label_answer"
                                  >
                                    {answerOption.option}
                                  </label>
                                </div>
                              ) : null}
                            </div>
                          )
                        )}
                        {/* {currentQuestion == data?.length - 2 && (
                          <label
                            onClick={() => {
                              console.log(data[currentQuestion],'==========================123')
                              if (data[currentQuestion].isSuicidalQuestion) {
                                history.push('/hotline')
                              }  handleAnswerOptionClick(5)
                            }}
                            className="label_answer"
                          >
                            Yes, I made specific plan.
                          </label>
                        )} */}
                        {data[currentQuestion]?.answer_type == "single" ? (
                          <div className="questinganweser_disclamier">
                            <div className="disc_icon">
                              <i className="fa fa-info"></i>
                              <span>
                                {data[currentQuestion]?.about_question ||
                                  "This assessment helps you to match with a professional therapist whom you can trust, tailored to your specific needs."}
                              </span>
                            </div>
                          </div>
                        ) : null}
                      </div>
                    )}
                    {data[currentQuestion]?.answer_type == "multiple" && (
                      <div className="buttn_next mb-3 text-end">
                        <button

                          className="btn btn-primary btn-sm"
                          onClick={() => handleNextButtonClick()}
                        >
                          Next <i className="fa fa-angle-right text-white"></i>{" "}
                        </button>
                        <div className="questinganweser_disclamier mt-3">
                          <div className="disc_icon">
                            <i className="fa fa-info"></i>
                            <span style={{ textAlign: "start" }}>
                              {data[currentQuestion]?.about_question ||
                                "This assessment helps you to match with a professional therapist whom you can trust, tailored to your specific needs."}
                            </span>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>}

                {data[currentQuestion]?.answer_type === "dropdown" && (
                  <div className="onetworadio mt-4">
                    <select
                      className="form-control"
                      value={selectedDropdownValue}
                      onChange={(e) => {
                        handleDropdownSelection(e);
                        setId(e.target.value);
                      }}
                    >
                      <option value="">Select Your Age</option>
                      {data[currentQuestion]?.options.map((option, i) => (
                        <option key={i} value={option.id}>
                          {option.option}
                        </option>
                      ))}
                    </select>

                    <div className="buttn_next mb-3 mt-3 text-end">
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={handleDropdownNextButtonClick}
                      >
                        Next <i className="fa fa-angle-right text-white"></i>
                      </button>
                    </div>
                    <div className="questinganweser_disclamier">
                      <div className="disc_icon">
                        <i className="fa fa-info"></i>
                        <span>
                          {data[currentQuestion]?.about_question ||
                            "This assessment helps you to match with a professional therapist whom you can trust, tailored to your specific needs."}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {/* <div className='questinganweser_disclamier'>
                  <div className='disc_icon'>
                    <i className='fa fa-info'></i>
                    <span>This assessment helps us match you to a professional therapist you can trust, based on your specific needs.</span>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <ToastContainer position="top-right" />
    </>
  );
};

export default Question;
