import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../methods/api/apiClient'
import loader from '../../methods/loader'
import { useDispatch, useSelector } from 'react-redux'
import { login_success, logout } from '../../actions/user'
import Img from './../../assets/imgpsh_fullsize_anim.png'
import { Navbar, Dropdown, Button, Form, Col, Row, Modal, Accordion } from "react-bootstrap";
import methodModel from '../../methods/methods'
import environment from '../../environment'


function Header() {
  const location = useLocation()
  const [token, settoken] = useState(true)
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.user)
  const Auth = localStorage.getItem('token')
  useEffect(() => {
    if (localStorage.getItem('token')) {
      settoken(false)
    }
  }, [])
  const Logout = () => {
    dispatch(logout())
    localStorage.removeItem("persist:admin-app")
    localStorage.removeItem("token")
    history.push('/login');
  };
  useEffect(()=>{
    if(user && user?.loggedIn){

      ApiClient.get('user/profile').then((res)=>{
        if(res.success){
          dispatch(login_success(res?.data))
          localStorage.setItem('user',res?.data)
        }
      })
    }
  },[])
  useEffect(() => {
    if (!localStorage.getItem('token') & window.location.pathname != '/privacy' & window.location.pathname != '/plans' & window.location.pathname != '/questions' & window.location.pathname != '/' & window.location.pathname != '/about' & window.location.pathname != '/faq' & window.location.pathname != '/review' & window.location.pathname != '/contact' & window.location.pathname != '/signup' & window.location.pathname != '/term-condition' & window.location.pathname != '/hotline' & window.location.pathname != '/childline') {
      history.push('/login')
      localStorage.removeItem("persist:admin-app")
    }
  }, [Auth])

  return (
    <header className='header_main'>
      <div className='container-fluid p-0'>
        <nav class="navbar navbar-expand-lg w-100 navbar-light ">
          {location?.pathname == "/" || location?.pathname == "/reason" ? <a class="navbar-brand" href="/"><img src="./assets/img/therpy/logo-main.png" className='img-fluid' /></a> :
            <a class="navbar-brand" href="/"><img src="./assets/img/therpy/logo-main.png" className='img-fluid' /></a>}
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse " id="navbarText">
            <ul class="navbar-nav ml-auto">
              <li class={`nav-item ${window.location.pathname == '/' ? "active" : ''}`}>
                <a class="nav-link" href="/">Home </a>
              </li>
              <li class={`nav-item ${window.location.pathname == '/about' ? "active" : ''}`}>
                <a class="nav-link" href="/about">About</a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="#">Advice</a>
              </li> */}
              <li class={`nav-item ${window.location.pathname == '/faq' ? "active" : ''}`}>
                <a class="nav-link" href="/faq">FAQ’s</a>
              </li>
              <li class={`nav-item ${window.location.pathname == '/review' ? "active" : ''}`}>
                <a class="nav-link" href="/review">Reviews</a>
              </li>
              {/* <li class="nav-item">
                <a class="nav-link" href="#">Theraphy Jobs</a>
              </li> */}
              <li class={`nav-item ${window.location.pathname == '/contact' ? "active" : ''}`}>
                <a class="nav-link" href="/contact">Contact</a>
              </li >
            </ul >


            <span class="navbar-text">
              {
                localStorage.getItem('token') ?

                  <div className={`dropdwon_users ${window.location.pathname == '/' & window.location.pathname == '/reason' ? "changeBg" : ''}`}>
                    <Dropdown>
                      <Dropdown.Toggle variant="transparent" id="">
                        <div className='d-flex align-items-center'>
                          <div className='drophead'>
                            <h4>{user.role}</h4>
                            <p>{user.fullName}</p>
                          </div>
                          <img src={user?.image ? `${environment?.api}images/users/` + user?.image : `/assets/img/person.jpg`} className=' profilepic' />
                        </div>
                      </Dropdown.Toggle>

                      <Dropdown.Menu>
                        <Dropdown.Item href="/profile"> <i className='fa fa-user mr-2'></i> Profile</Dropdown.Item>
                        <Dropdown.Item href="/myappointment"> <i className='fa fa-calendar mr-2'></i> My Appointment</Dropdown.Item>
                        <Dropdown.Item href="/change-password"> <i className='fa fa-lock mr-2'></i> Change Password</Dropdown.Item>
                        <Dropdown.Item onClick={Logout}> <i className='fas fa-sign-out-alt mr-2'></i> Logout</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div> : null}


              {
                token ? <>
                  <Link to={'/login'} >
                    <button className='btn btn-white-tranparent mr-3'>Login</button>
                  </Link>

                  <Link to={'/questions'}>
                    <button className='btn btn-white'>Get Started</button>
                  </Link></> : <Link to={'/login'} >
                  {/* <button className='btn btn-white-tranparent mr-3' onClick={Logout}>Log Out</button> */}
                </Link>
              }
            </span >
          </div >
        </nav >
      </div >
    </header >

  )
}

export default Header
