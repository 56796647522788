import React, { useEffect, useState } from "react";
import { DateRange } from 'react-date-range';
import datepipeModel from "../../../models/datepipemodel";
import { useSelector } from 'react-redux';
import "./style.scss";

const DateRangePicker = ({ value, onChange, disabled = false, placeholder = '', isCompare = false }) => {
    const user = useSelector((state) => state.user)
    const [toggle, setToggle] = useState(false)
    const [range, setRange] = useState('')

    const rangeList = [
        { id: 'This Month', name: "This Month" },
        { id: 'This Year', name: "This Year" },
        { id: 'Last Month', name: "Last Month" },
        { id: 'Last Year', name: "Last Year" },
        { id: '', name: "Custom" }
    ]

    const blockDateChange = (e) => {
        onChange({ ...value, ...e, compare: '' })
        if (datepipeModel.datetostring(e.startDate) != datepipeModel.datetostring(e.endDate)) {
            setToggle(false)
        }
    }

    const toggleChange = () => {
        setToggle(!toggle)
    }

    const getBlockValue = () => {
        let v = {
            startDate: value['startDate'] ? new Date(value['startDate']) : new Date(),
            endDate: value['endDate'] ? new Date(value['endDate']) : new Date(),
            key: 'selection'
        }
        return [v]
    }

    const dropdownMenuClick = (e) => {
        // let el = document.getElementById(`daterangeDropdown-${id}`)
        // el.click()
    }

    const rangeClick = (e) => {
        let startDate = ''
        let endDate = ''

        if (e == 'Last Month') {
            let current = new Date()
            let monthdate = current.setDate(0)
            monthdate = datepipeModel.datetostring(monthdate)
            startDate = `${monthdate.split('-')[0]}-${monthdate.split('-')[1]}-01`
            endDate = monthdate

        } else if (e == 'This Month') {
            let current = datepipeModel.datetostring(new Date())
            startDate = `${current.split('-')[0]}-${current.split('-')[1]}-01`

            let month2 = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0)
            endDate = datepipeModel.datetostring(month2)
        }

        else if (e == 'This Year') {
            let current = datepipeModel.datetostring(new Date())
            startDate = `${current.split('-')[0]}-01-01`

            let month2 = new Date(new Date().getFullYear() + 1, 0, 0)
            endDate = datepipeModel.datetostring(month2)
        }
        else if (e == 'Last Year') {
            let current = new Date()
            startDate = `${current.getFullYear() - 1}-01-01`
            let month2 = new Date(current.getFullYear(), 0, 0)
            endDate = datepipeModel.datetostring(month2)
        }
        setRange(e)
        onChange({ ...value, startDate, endDate, compare: '' })
        if (e != '') {
            setToggle(false)
        }
    }

    const getDays = (s, e) => {
        // Define two date objects
        const startDate = new Date(s); // Replace with your start date
        const endDate = new Date(e);   // Replace with your end date
        // Calculate the time difference in milliseconds
        const timeDifference = endDate - startDate;
        // Convert milliseconds to days
        const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        return daysDifference
    }

    const previousYear = () => {
        let start = ''
        let end = ''
        if (value.startDate && value.endDate) {
            let ssplit = value.startDate.split('-')
            let esplit = value.endDate.split('-')
            let year = Number(ssplit[0])
            let eyear = Number(esplit[0])

            let d = new Date(`${year - 1}-${ssplit[1]}-${ssplit[2]}`)
            start = datepipeModel.datetostring(d)

            let ed = new Date(`${eyear - 1}-${esplit[1]}-${esplit[2]}`)
            end = datepipeModel.datetostring(ed)
        }
        return { start, end }
    }

    const previousMonth = () => {
        let start = ''
        let end = ''
        if (value.startDate && value.endDate) {
            // let sMonth=new Date(value.startDate).getMonth()
            // start=new Date(value.startDate).setMonth(sMonth-1)
            // start=datepipeModel.datetostring(start)

            // let eMonth=new Date(value.endDate).getMonth()
            // end=new Date(value.endDate).setMonth(eMonth-1)
            // end=datepipeModel.datetostring(end)

            let current = new Date(value.startDate)
            let monthdate = current.setDate(0)
            monthdate = datepipeModel.datetostring(monthdate)
            start = `${monthdate.split('-')[0]}-${monthdate.split('-')[1]}-01`
            end = monthdate
        }
        return { start, end }
    }

    const previousPeriod = () => {
        let start = ''
        let end = ''
        if (value.startDate && value.endDate) {
            let days = getDays(value.startDate, value.endDate) + 1

            let d = new Date(value.startDate)
            d.setDate(d.getDate() - days)
            start = datepipeModel.datetostring(d)

            let ed = new Date(value.startDate)
            ed.setDate(ed.getDate() - 1)
            end = datepipeModel.datetostring(ed)
        }
        return { start, end }
    }

    const compareChange = (e) => {
        let start = ''
        let end = ''
        if (e == 'Previous Period') {
            start = previousPeriod().start
            end = previousPeriod().end
        } else if (e == 'Previous Year') {
            start = previousYear().start
            end = previousYear().end
        } else if (e == 'Previous Month') {
            start = previousMonth().start
            end = previousMonth().end
        }

        let v = {
            ...value,
            compareStart: start,
            compareEnd: end,
            compare: e
        }
        onChange({ ...v })
    }

    return <>
        <div class="dropdown daterangeDropdown">
            <button class="btn btn-primary dropdown-toggle removeBg " type="button" disabled={disabled} onClick={toggleChange}>
                {!value?.startDate || !value?.endDate ? <>{placeholder || 'Start Date - End Date'}</> : <>{datepipeModel.date(value?.startDate, user?.companyDateFormat)} -  {datepipeModel.date(value?.endDate, user?.companyDateFormat)}</>}
            </button>
            <div class={`dropdown-menu ${toggle ? 'show' : ''}`} onClick={e => dropdownMenuClick(e)}>
                {/* <p className="text-center px-2">{!value?.startDate || !value?.endDate ? <>{placeholder || 'Start Date - End Date'}</> : <>{datepipeModel.date(value?.startDate, user?.companyDateFormat)} -  {datepipeModel.date(value?.endDate, user?.companyDateFormat)}</>}</p> */}
                <div className="filterFlex cld_btn p-2 mb-0">
                    {rangeList.map(itm => {
                        return <button className={`btn ${range == itm.id ? 'btn-primary' : 'btn-outline-primary'} btn-sm`} type="button" onClick={e => rangeClick(itm.id)}>{itm.name}</button>
                    })}

                </div>

                {range ? <></> : <>
                    <DateRange
                        editableDateInputs={true}
                        onChange={e => blockDateChange(e.selection)}
                        moveRangeOnFirstSelection={false}
                        ranges={getBlockValue()}
                    
                    />
                </>}


                {isCompare ? <>
                    <div className="text-center">
                        <div class="btn-group ">
                            <button type="button" className="btn btn-primary parimarybtn dropdown-toggle mb-2" data-toggle="dropdown" aria-expanded="false">
                                Compare
                            </button>
                            <div className="dropdown-menu">
                                <a className={`dropdown-item ${value.compare == 'Previous Month' ? 'active' : ''}`} onClick={e => compareChange('Previous Month')}>Previous Month ({datepipeModel.date(previousMonth().start, user.companyDateFormat)} - {datepipeModel.date(previousMonth().end, user.companyDateFormat)})</a>
                                <a className={`dropdown-item ${value.compare == 'Previous Year' ? 'active' : ''}`} onClick={e => compareChange('Previous Year')}>Previous Year(Same Date) ({datepipeModel.date(previousYear().start, user.companyDateFormat)} - {datepipeModel.date(previousYear().end, user.companyDateFormat)})</a>
                                <a className={`dropdown-item ${value.compare == 'Previous Period' ? 'active' : ''}`} onClick={e => compareChange('Previous Period')}>Previous Period(Custom Dates) ({datepipeModel.date(previousPeriod().start, user.companyDateFormat)} - {datepipeModel.date(previousPeriod().end, user.companyDateFormat)})</a>
                            </div>
                        </div>
                    </div>
                </> : <></>}

            </div>
        </div>
        <div className={`dropdownOverlap ${toggle ? 'd-block' : ''}`} onClick={e => toggleChange(false)}></div>
    </>
}
export default DateRangePicker