import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import { ToastsStore } from 'react-toasts';
import methodModel from '../../../methods/methods';
import { login_success } from '../../../actions/user';
import './style.scss';
import { userType } from '../../../models/type.model';
import Html from './Html';
import { useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import Header from '../../../pages/HomePage/Header';
import Footer from '../../../pages/HomePage/Footer/Footer';

const EditProfile = p => {
  const user = useSelector((state) => state.user);
  const [data, setData] = useState('');
  const [form, setForm] = useState(userType);
  const dispatch = useDispatch();
  const history = useHistory()
  const [submitted, setSubmitted] = useState(false)

  // const formValidation = [
  //   { key: 'ic_number', minLength: 6 },
  //   { key: 'mobileNo', minLength: 10 },
  //   { key: 'dialCode', dialCode: true },
  // ]

  

  const gallaryData = () => {
    loader(true)
    ApiClient.get(`user/profile`, { id: user._id }).then(res => {
      if (res.success) {
        setForm({ form, ...res.data })
        setData(res.data)
      }
      loader(false)
    })
  };

  // const getError = (key) => {
  //   return methodModel.getError(key, form, formValidation)
  // }

  const handleSubmit = e => {

    e.preventDefault();
    setSubmitted(true)
    // let invalid = methodModel.getFormError(formValidation, form)
    if (form.dialCode.length < 1 || form.mobileNo.length < 10 || !form.fullName) return

    let value = { fullName: form.fullName, dialCode: `+${form.dialCode}`, mobileNo: form.mobileNo, image: form.image, email:user.email }

    loader(true)
    ApiClient.put('user/profile', value).then(res => {
      if (res.success) {
        let uUser = { ...user, ...value }
        dispatch(login_success(uUser))
        history.push("/profile")
        ToastsStore.success(res.message)
      }
      loader(false)
    })
  };

  const uploadImage = (e) => {
    setForm({ ...form, baseImg: e.target.value })
    let files = e.target.files
    let file = files.item(0)
    loader(true)
    ApiClient.postFormData('upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {
      if (res.success) {
        let image = res.data?.fullpath
        setForm({ ...form, image: image, baseImg: '' })
      } else {
        setForm({ ...form, baseImg: '' })
      }
      loader(false)
    })
  }

  useEffect(
    () => {
      if (user && user.loggedIn) {
        gallaryData();
      }else{
        history.push('/')
      }
    },
    []
    // here '[]' is used for, loop se bachne k liye
  );


  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <>
      <div className='bg_leaf position-relative'>
        <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
        <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
        <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

          <Header />
        </div>
        <div className="container-fluid p-0 overflow-hidden">
          <div className="row ">
            <div className="col-lg-12">
              <div className="contact-us-bg bg_light_grey">
                <h2>Edit Profile</h2>

              </div>
            </div>
          </div>
        </div>


      </div>
      <Html
        handleSubmit={handleSubmit}
        setForm={setForm}
        form={form}
        uploadImage={uploadImage}
        // getError={getError}
        submitted={submitted}
      />
      <Footer />
    </>
  );
};

export default EditProfile;
