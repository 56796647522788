//src/plugins/openai.js

import axios from 'axios';
import environment from '../environment';

// Replace 'API_URL' with the GPT-4 API endpoint provided in the documentation
// const apiUrl = 'https://api.openai.com/v1/engines/gpt-4/completions';
const apiUrl = 'https://api.openai.com/v1/chat/completions';
const header={
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${environment.OPENAI_API_KEY}`,
}

export async function generateResponse(prompt) {

  const payload={
    // prompt: prompt,
    messages: [
      {"role": "user","content":"provide reply for the below review"},
      {"role": "user", "content": prompt}
      ],
    max_tokens: 500,
    temperature: 1,
    model:'gpt-3.5-turbo',
    n: 1,
    stop: null,
  }

  try {
    const response = await axios.post(
      apiUrl,
      payload,
      {
        headers:header,
      }
    );

    // Assuming the response format is the same as GPT-3, extract the text
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error in GPT-4 request:', error);
    throw error;
  }
}

export async function addFineTune(prompt) {

  const payload={
    training_file:prompt
  }

  try {
    const response = await axios.post(
      'https://api.openai.com/v1/fine-tunes',
      payload,
      {
        headers:header,
      }
    );

    // Assuming the response format is the same as GPT-3, extract the text
    return response;
  } catch (error) {
    console.error('Error in GPT-4 request:', error);
    throw error;
  }
}

export async function uploadFile(file) {

  const payload={
    // prompt: prompt,
    
  }

  try {
    const response = await axios.postForm(
      'https://api.openai.com/v1/files',
      {
        purpose:'fine-tune',
        file:file
      },
      {
        headers:header,
      }
    );

    // Assuming the response format is the same as GPT-3, extract the text
    return response.data.choices[0].message.content;
  } catch (error) {
    console.error('Error in GPT-4 request:', error);
    throw error;
  }
}

export async function fileList() {
  try {
    const response = await axios.get(
      'https://api.openai.com/v1/files',
     {
      headers:header
     }
    );

    // Assuming the response format is the same as GPT-3, extract the text
    return response;
  } catch (error) {
    console.error('Error in GPT-4 request:', error);
    throw error;
  }
}

export async function fineTuneList() {
  try {
    const response = await axios.get(
      'https://api.openai.com/v1/files',
     {
      headers:header
     }
    );

    // Assuming the response format is the same as GPT-3, extract the text
    return response;
  } catch (error) {
    console.error('Error in GPT-4 request:', error);
    throw error;
  }
}

export async function fileContent(fileId) {
  try {
    const response = await axios.get(
      `https://api.openai.com/v1/files/${fileId}/content`,
     {
      headers:header
     }
    );

    // Assuming the response format is the same as GPT-3, extract the text
    return response;
  } catch (error) {
    console.error('Error in GPT-4 request:', error);
    throw error;
  }
}

export async function fileSearch(file,query) {

  const payload={
    "file": file, 
    "query": query, 
    "model": "gpt-4",
    "max_rerank": 5
  }

  try {
    const response = await axios.post(
      'https://api.openai.com/v1/search',
      payload,
      {
        headers:header,
      }
    );

    // Assuming the response format is the same as GPT-3, extract the text
    return response;
  } catch (error) {
    console.error('Error in GPT-4 request:', error);
    throw error;
  }
}