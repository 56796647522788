import React, { useEffect, useState } from 'react';
import Layout from '../../components/global/layout';
import Pagination from "react-pagination-js";
import './style.scss';
import SelectDropdown from "../../components/common/SelectDropdown";
import datepipeModel from '../../models/datepipemodel';
import Select from "react-select";
import DateRangePicker from '../../components/common/DateRangePicker';
import ApiClient from '../../methods/api/apiClient';

const Html = ({
    ailoader,
    getReply,
    user,
    isClear,
    filter,
    setFilter,
    replyData,
    deleteReply,
    replySubmit,
    setform,
    form,
    view,
    sortClass,
    sorting,
    ChangeStatus,
    pageChange,
    filters,
    loaging,
    data,
    handleUpdate,
    exportfun,
    reply,
    products,
    setProducts,
    sync,
    handleProducts,
    clearAll,
    item,

    total = { total }
}) => {
    const [icon, seticon] = useState('')

    const fltr = item.filter((item) => item?.platform == 'Google')
    const log = fltr[0];
    const fltr2 = item.filter((item) => item?.platform == 'Tripadvisor')
    const log2 = fltr2[0]


    const sourceLogo = (p) => {
        let value = '/assets/img/dummy.jpg'
        if (p == 'Google') {
            value = 'https://endpoint.dazhboards.com/img/' + log?.logo
            if (log?.logo == '') {

                value = 'https://www.freepnglogos.com/uploads/google-logo-png/google-logo-png-suite-everything-you-need-know-about-google-newest-0.png'
            }
        }
        if (p == 'Tripadvisor') {
            value = 'https://endpoint.dazhboards.com/img/' + log2?.logo
            if (log2?.logo == '') {
                value = 'https://endpoint.dazhboards.com/img/image-1694902415862.jpg'
            }
        }
        return value
    }

    return (
        <>
            <Layout>
                <div className=''>
                    <div className="d-flex justify-content-between align-items-center">
                        <h3 className="hedding">
                            Reviews Listing
                        </h3>
                        <article className="d-flex">
                            <button className="btn btn-primary mr-2" onClick={() => exportfun()}>
                                Export
                            </button>
                            <div className="dropdown addDropdown mr-2">
                                <button className="btn btn-primary dropdown-toggle removeBg" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {filters.status ? filters.status == "deactive" ? "Inactive" : filters.status : 'All Status'}
                                </button>
                                <div className="dropdown-menu shadow bg_hover" aria-labelledby="dropdownMenuButton">
                                    <a className={filters.status == '' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("")}>All Status</a>
                                    <a className={filters.status == 'active' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("active")} >Active</a>
                                    <a className={filters.status == 'Inactive' ? 'dropdown-item active' : 'dropdown-item'} onClick={() => ChangeStatus("deactive")} >Inactive</a>
                                </div>
                            </div>
                        </article>
                    </div>
                    <hr />
                    <div className="form-row">
                        <div className="mb-3">
                            <form onSubmit={e => { e.preventDefault(); filter() }} className='search_box'>
                                <input
                                    type="text"
                                    value={filters.search}
                                    placeholder="Search by any data..."
                                    onChange={e => setFilter({ ...filters, search: e.target.value })}
                                    className="form-control "
                                />
                                <span class="material-icons">search</span>
                            </form>
                        </div>
                        <button className='btn btn-primary ml-2 mb-3' onClick={e => filter()}>Search</button>
                        {isClear() ? <>
                            <button className='btn btn-secondary ml-2 mb-3' onClick={e => clearAll()}>Clear</button>
                        </> : <></>}
                        <div className="ml-2 mb-3">
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder='Platform'
                                intialValue={filters.platform}
                                result={e => handleUpdate({ platform: e.value })}
                                options={[
                                    { id: 'Tripadvisor', name: 'Tripadvisor' },
                                    { id: 'Google', name: 'Google' },
                                ]}
                            />
                        </div>
                        <div className="ml-2 mb-3">
                            <DateRangePicker
                                value={{
                                    startDate: filters.start,
                                    endDate: filters.end
                                }}
                                onChange={e => { setFilter({ ...filters, start: e.startDate, end: e.endDate }) }}
                            />
                        </div>
                        <div className="ml-2 mb-3">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder='Ratings'
                                intialValue={filters.rating}
                                result={e => handleUpdate({ rating: e.value })}
                                options={[
                                    { id: '1', name: "1 Star" },
                                    { id: '2', name: "2 Star" },
                                    { id: '3', name: "3 Star" },
                                    { id: '4', name: "4 Star" },
                                    { id: '5', name: "5 Star" },
                                ]}
                            />
                        </div>

                        <div className=" ml-2 mb-3">
                            <Select
                                options={products}
                                placeholder="All Products"
                                value={handleProducts()}
                                isClearable={true}
                                name="Product"
                                onChange={e => setProducts(e ? e.value : '')}
                            />
                        </div>

                        <div className="ml-2 mb-3">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder='Type'
                                intialValue={filters.type}
                                result={e => handleUpdate({ type: e.value })}
                                options={[
                                    { id: 'Product', name: "Product" },
                                    { id: 'Site', name: "Site" }
                                ]}
                            />
                        </div>
                        <div className=" ml-2 mb-3">
                            <SelectDropdown
                                isSingle={true}
                                id="statusDropdown"
                                displayValue="name"
                                placeholder='Replied'
                                intialValue={filters.replied}
                                result={e => handleUpdate({ replied: e.value })}
                                options={[
                                    { id: 'Yes', name: "Yes" },
                                    { id: 'No', name: "No" }
                                ]}
                            />
                        </div>
                    </div>

                    <div className="table-responsive table_section">
                        <table class="table table-striped">
                            <thead className='table_head'>
                                <tr className='heading_row'>
                                    <th scope="col" className='table_data pointer' onClick={e => sorting('platform')}>Source <i className={sortClass('platform')}></i></th>
                                    <th scope="col" className='table_data' onClick={e => sorting('productName')}>Product <i className={sortClass('productName')}></i></th>
                                    <th scope="col" className='table_data pointer' onClick={e => sorting('rating')}>Rating <i className={sortClass('rating')}></i></th>
                                    <th scope="col" className='table_data pointer' onClick={e => sorting('reviewDate')}>Date <i className={sortClass('reviewDate')}></i></th>
                                    <th scope="col" className='table_data pointer' onClick={e => sorting('reviewBy')}>Name Of Reviewer <i className={sortClass('reviewBy')}></i> </th>
                                    <th scope="col" className='table_data pointer' onClick={e => sorting('text')}>Review <i className={sortClass('text')}></i> </th>
                                    <th scope="col" className='table_data'>Trip Type</th>
                                    <th scope="col" className='table_data text-center' onClick={e => sorting('ownerResponse')}>Replied <i className={sortClass('ownerResponse')}></i></th>
                                    <th scope="col" className='table_data'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {!loaging && data && data.map((itm, i) => {
                                    return <tr>
                                        <td className='table_dats' onClick={e => view(itm.id)}><img src={sourceLogo(itm.platform) || 'http://localhost:3001/assets/img/person.jpg'} class="wallets_img3 mr-2" />{itm.platform || '--'}</td>
                                        <td className='table_dats'><span class=""> {itm?.product?.name || '--'}</span></td>
                                        <td className='table_dats'>{itm.rating || '--'}<i className='fa fa-star star-icon ml-2'></i> </td>
                                        <td className='table_dats'>{datepipeModel.date(itm.reviewDate, user?.companyDateFormat)}
                                        </td>
                                        <td className='table_dats'><span className='list_product'>{itm?.reviewBy}</span></td>
                                        <td className='table_dats'><span className='list_product'>{itm?.text}</span></td>
                                        <td className='table_dats'>{itm.trip_type || '--'}</td>
                                        <td className='table_dats text-center'>{itm.ownerResponse ? <span className='replied'>Replied</span> : <span className='not_replied'>Not Replied</span>}</td>

                                        {/* dropdown */}
                                        <td className='table_dats'>
                                            <div className="action_icons">
                                                <span className="edit_icon" title={itm?.ownerResponse ? 'Update Reply' : 'Reply'} onClick={e => reply(itm)}>
                                                    <i class="material-icons edit">reply</i>
                                                </span>
                                                <span className="edit_icon" title="View" onClick={e => view(itm.id)}>
                                                    <i class="material-icons edit">visibility</i>
                                                </span>
                                            </div>
                                        </td>
                                        {/* end */}
                                    </tr>
                                })
                                }
                            </tbody>
                        </table>
                    </div>

                    {!loaging && total == 0 && sync ? <div className="py-3 text-center">Syncing...</div> : !loaging && total == 0 && !sync ? <div className="py-3 text-center">No Data Found</div> : <></>}
                    {
                        !loaging && total > filters.count ? <div className='paginationWrapper'>
                            <span>Show {filters.count} from {total} Categories</span>
                            <Pagination
                                currentPage={filters.page}
                                totalSize={total}
                                sizePerPage={filters.count}
                                changeCurrentPage={pageChange}
                            />
                        </div> : <></>
                    }

                    {loaging ? <div className="text-center py-4">
                        <img src="/assets/img/loader.gif" className="pageLoader" />
                    </div> : <></>}
                </div>
            </Layout>


            <button type="button" class="btn btn-primary d-none" data-toggle="modal" id='replyModalBtn' data-target="#replyModal">
                Launch demo modal
            </button>

            <div class="modal fade" id="replyModal" tabindex="-1" role="dialog">
                <div class="modal-dialog modal-dialog-centered" role="document">
                    <div class="modal-content">
                        <div class="modal-header border-0 pb-0 m_modal">
                            <div class="media">
                                <img class="align-self-start mr-3" src="/assets/img/person.jpg" width="50" alt="Generic placeholder image" />
                                <div class="media-body">
                                    <div className='d-flex mb-2 align-items-center'>
                                        <h5 class="mb-0">{replyData?.user?.username || replyData?.user?.displayName}</h5>
                                        <i className='fa fa-star ml-2 mr-1'></i> {replyData?.rating} <span className='text-muted ml-2'>{datepipeModel.date(replyData?.reviewDate, user?.companyDateFormat)}</span>
                                    </div>
                                    {replyData?.text}
                                </div>
                            </div>
                            <button type="button" class="close" data-dismiss="modal" id="reviewModalClose" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <form onSubmit={replySubmit}>
                            <div class="modal-body">
                                <label>Reply</label>
                                <textarea className='form-control' disabled={form.id ? true : false} value={form.comment} onChange={e => setform({ ...form, comment: e.target.value })} required placeholder='Write details your reply...'></textarea>
                            </div>
                            <div class="modal-footer border-0 m_modal">
                                {form.id ? <></> : <>
                                    <button type="submit" disabled={ailoader} class="btn btn-primary">{form.id ? 'Update' : 'Submit'} Reply</button>
                                </>}

                                {ailoader ? <>
                                    <span className='text-success'>𝐒𝐢𝐭 𝐭𝐢𝐠𝐡𝐭, 𝐀𝐈 𝐢𝐬 𝐨𝐧 𝐀𝐮𝐭𝐨 𝐏𝐢𝐥𝐨𝐭 𝐌𝐨𝐝𝐞 <i className='fa fa-spin fa-spinner'></i></span>
                                </> : <></>}

                                <i className='fa fa-comment pointer' title='Get Reply through Chat Gpt' onClick={getReply}></i>
                                {form.id ? <button type="button" class="btn btn-primary" disabled={ailoader} onClick={e => deleteReply(form.reviewId)}>Delete</button> : <></>}

                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Html;
