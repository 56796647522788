import React, { useEffect, useState } from "react";
import './Helpline.css';
import Footer from "../HomePage/Footer/Footer";
import Header from "../HomePage/Header";

const hotLine = () => {

  return (
    <>
      <div className="header-dark-page ">
        <Header />
      </div>
      <div className="helpline p-60">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-9 col-lg-9 mx-auto">
              <div className=" helpline-container">
                <div className=" row">
                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="wrapper_helpline">
                      <h3 className="m-0">Helpline</h3>
                      <div className="helpline-info">
                        <p className="fs14">
                          Please call or text 9-8-8 please call or text Dial 9-8-8 or Call  <a href="tel:1-800-273-8255">1-800-273-8255</a> (1-800-SUICIDE) - 24-hour suicide prevention line that can be called from anywhere in the U.S
                          <p>Text 741-741 - National Crisis Text Line. If you're uncomfortable making a phone call you can Text HOME to 741741 to connect with a Crisis Counselor .
                          </p>
                          <a href="mailto:suicidepreventionlifeline.org">suicidepreventionlifeline.org</a> .
                        </p>

                        {/* You can add more information or options here */}
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-6">
                    <div className="imghelpline">
                      <img src="/assets/img/helpline.png" className='img-fluid2' />
                    </div>
                  </div>

                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default hotLine;
