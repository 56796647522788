import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './style.scss'
import { useSelector } from 'react-redux';
import methodModel from '../../methods/methods';
import Header from '../HomePage/Header';
import Footer from '../HomePage/Footer/Footer';


const TermConditions = () => {

  

  return (
    <>
    <div className='header-dark-page '>
    <Header/>
    </div>
  
        <div className="container">
        <h2>1. Welcome and Introduction</h2>
  <p>Welcome to Therapy Internal ! These Terms and Conditions ("Terms") govern your use of our website and any related services (collectively, the "Service"). Please read these Terms carefully before using the Service. By accessing or using the Service, you agree to be bound by these Terms. If you do not agree to all of the Terms, you may not use the Service.</p>

  <h2>2. Use of the Service</h2>
  <p><strong>2.1 Eligibility:</strong> You must be at least 18 years old to access and use the Service. You may not use the Service if you are prohibited from doing so by law.</p>
  <p><strong>2.2 Prohibited Activities:</strong> You may not use the Service for any unlawful purpose or any purpose that is not expressly permitted by these Terms. You agree not to engage in any of the following prohibited activities:</p>
  <ul>
    <li>Harassing, abusing, or threatening any other user or therapist.</li>
    <li>Impersonating any person or entity, including a therapist or representative of Therapy Internal.</li>
    <li>Providing false or misleading information.</li>
    <li>Uploading or transmitting any content that is illegal, harmful, obscene, offensive, or inappropriate.</li>
    <li>Interfering with or disrupting the operation of the Service.</li>
    <li>Attempting to gain unauthorized access to the Service or any other user's account.</li>
    <li>Using the Service for any commercial or non-personal purpose.</li>
  </ul>
  <p><strong>2.3 Therapist Services:</strong> The therapists available through the Service are independent professionals and are not employees or agents of Therapy Internal. We do not provide therapy services ourselves.</p>
  <p><strong>2.4 Responsibility for Content:</strong> You are solely responsible for the content you submit to the Service. You represent and warrant that you own all rights to the content you submit or have obtained all necessary permissions to use it.</p>

  <p>If you have any questions about these Terms, please contact us at nishant@yopmail.com.</p>
           
        </div>
  <Footer/>
    </>
  );
};

export default TermConditions;
