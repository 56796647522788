import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import loader from '../../methods/loader';
import environment from '../../environment';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';
import Header from '../HomePage/Header';
import Footer from '../HomePage/Footer/Footer';
import './style.css'

function plansSection() {
  const user = useSelector((state) => state.user)
  const history = useHistory()
  useEffect(() => {
    loader(true)
    setTimeout(() => {
      loader(false)
    }, 600);
  })
  const [btn, setbtn] = useState(false)
  const [plan, setplan] = useState([])

  const GetPlan = () => {
    loader(true)
    ApiClient.get('plans?status=active').then((res) => {
      if (res?.success) {
        setplan(res?.data.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)));
        loader(false)
      }
    })
  }

  useEffect(() => {
    window.scrollTo(0, 0);
}, []);

  useEffect(() => {
    GetPlan()
  }, [])

  return (
    <> <div className="header-dark-page "><Header /></div>
        <div className='about-section '>
        <div class="heading-top pt-0 text-center ">
            <h6 class="heading-tittle">Therapy Plans</h6>
            <span class="lineheading d-block"></span>
        </div>
        <div className='container '>
          <div className='row'>
           {plan?.map((itm) => { 
            return(  
            <div className='col-sm-12 col-md-3 col-lg-3'>
              <div className='align_set'>
              <img src={`${environment.api}images/users/` + itm?.image} className='wauto' />
                <div className='text_centered'>
                  <h4>{methodModel.capitalizeFirstLetter(itm?.name)}</h4>
                  <p>{itm?.description}</p>
                  <h4 className='price_main' onClick={()=>history.push('/questions')}>${itm?.price}</h4>
                </div>
              </div>
            </div>)})}
          </div>
        </div>  
        </div>
        
      <Footer />
    </>

  )
}

export default plansSection
