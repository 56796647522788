import React, { useEffect, useState } from 'react'
import Header from '../HomePage/Header'
import Footer from '../HomePage/Footer/Footer'
import { Navbar, Dropdown, Button, Form, Col, Row, Modal, Accordion } from "react-bootstrap";
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';


function Faq() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [faqData, setfaqData] = useState([])

  useEffect(() => {
    ApiClient.get('all/faq').then((res) => {
      setfaqData(res?.data?.data)
    })
  }, [])

  useEffect(() => {
    loader(true)
    setTimeout(() => {
      loader(false)
    }, 600);
  }, [])

  useEffect(()=>{
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
  },[])


  return (
    <>
      <div className='bg_leaf position-relative'>
        <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
        <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
        <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

          <Header />
        </div>
        <div className="container-fluid p-0 overflow-hidden">
          <div className="row ">
            <div className="col-lg-12">
              <div className="contact-us-bg bg_light_grey">
                <h2>Frequently asked questions</h2>

              </div>
            </div>
          </div>
        </div>


      </div>

      <section className='p80'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-8 mx-auto '>
              <div className='faq_inner'>
                {faqData?.length > 0 ? faqData?.map((data) => <Accordion>
                  <Accordion.Item className='mb-3' eventKey="0">
                    <Accordion.Header>{data?.question}</Accordion.Header>
                    <Accordion.Body close>
                      <div dangerouslySetInnerHTML={{ __html: data?.answer }} />
                    </Accordion.Body>
                  </Accordion.Item>
                  {/* <Accordion.Item eventKey="1">
                    <Accordion.Header>Who will be helping me?</Accordion.Header>
                    <Accordion.Body>
                      After you sign up, we will match you to an available therapist who fits your objectives, preferences, and the type of issues you are dealing with. Different therapists have different approaches and areas of focus, so it's important to find the right person who can achieve the best results for you. We have found that we are able to provide a successful match most of the time; however, if you start the process and you feel your therapist isn't a good fit for you, you may elect to be matched to a different therapist.
                    </Accordion.Body>
                  </Accordion.Item> */}
                </Accordion>):
                <>No FAQ</>}
              </div>
            </div>
          </div>
        </div>
      </section>





      <Footer />
    </>
  )
}

export default Faq
