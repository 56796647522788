import axios from 'axios';
import moment from 'moment';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/global/layout';
import environment from '../../environment';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import Select from "react-select";
import DateRangePicker from '../../components/common/DateRangePicker';
import datepipeModel from '../../models/datepipemodel';
import SelectDropdown from '../../components/common/SelectDropdown';
import orderStatusModel from '../../models/orderStatus.model';
import Pagination from "react-pagination-js";
import pipeModel from '../../models/pipeModel';


const SalesData = () => {
    let user = useSelector(state => state.user)
    const searchState = useSelector((state) => state.search);
    const [orderslist, setOrderlist] = useState([])
    const [products, setProducts] = useState([])
    const [categories, setCategories] = useState([])
    const [resellers, setResellers] = useState([])
    const [total, setTotal] = useState(0)

    const [filters, setFilter] = useState({ page: 1, count: 50, search: '', status: '', product: '', category: '', travelStartDate: '', travelEndDate: '', reseller: '' })
    const history = useHistory()

    const getOrders = (p = {}) => {
        loader(true)
        let filter = { ...filters, ...p }
        ApiClient.get('api/orders', filter).then(res => {
            if (res.success) {
                let data = res.data.map(itm => {
                    let totalQuantity = 0
                    itm?.items?.map(oitm => {
                        totalQuantity = totalQuantity + oitm.totalQuantity
                    })
                    return {
                        ...itm,
                        totalQuantity: totalQuantity
                    }
                })
                setOrderlist(data)
                setTotal(res.total)
            }
            loader(false)
        })
    }


    // useEffect(() => {
    //     if (user && user.loggedIn) {
    //         setFilter({ ...filters, search: searchState.data })
    //         getOrders({ search: searchState.data, page: 1 })
    //     }
    // }, [searchState])

    useEffect(() => {
        let salesDataFilter = localStorage.getItem('salesDataFilter')
        if (salesDataFilter) {
            let f = JSON.parse(salesDataFilter)
            console.log("salesDataFilter", f)
            filter(f)
        } else {
            getOrders()
        }

        getPtoducts()
        getResellers()
        getCategories()
    }, [])

    const getPtoducts = () => {
        ApiClient.get('api/products/listing', {}).then(res => {
            if (res.success) {
                setProducts(res.data.map(itm => {
                    return { value: itm.productCode, label: itm.name }
                }))
            }
        })
    }

    const getCategories = () => {
        ApiClient.get('api/categories/listing', { catType: '64a7d198fa039f179c0320ca', status: 'active' }).then(res => {
            if (res.success) {
                setCategories(res.data.map(itm => {
                    return { value: itm.id, label: itm.name }
                }))
            }
        })
    }

    const getResellers = () => {
        ApiClient.get('api/reseller/listing', { status: 'active' }).then(res => {
            if (res.success) {
                setResellers(res.data.map(itm => {
                    return { value: itm.id, label: itm.name }
                }))
            }
        })
    }

    const vieworder = (id) => {
        history.push("/dashboard/orderdetail/" + id)
    }


    const filter = (p = {}) => {
        let f = { ...filters, ...p }
        localStorage.setItem('salesDataFilter', JSON.stringify(f))
        setFilter({ ...f })
        getOrders(p)
    }

    const resetDates=()=>{
        let f={
            startDate:'',
            endDate:'',
            travelEndDate: '',
        travelStartDate: ''
        }
        filter(f)
    }

    const clearKey = {
        search: '',
        status: '',
        // startDate: '',
        // endDate: '',
        product: '',
        category: '',
        reseller: '',
        // travelEndDate: '',
        // travelStartDate: ''
    }

    const isClear = () => {
        let value = false

        Object.keys(clearKey).map(itm => {
            if (filters[itm]) value = true
        })
        return value
    }

    const clear = () => {
        let p = clearKey
        Object.keys(clearKey).map(itm => {
            p[itm] = ''
        })
        let f = { ...filters, ...p, page: 1 }
        localStorage.setItem('salesDataFilter', JSON.stringify(f))
        setFilter({ ...f })
        getOrders({ ...p, page: 1 })
    }

    const exportfun = async () => {
        const token = await localStorage.getItem("token");
        const req = await axios({
            method: "get",
            url: `${environment.api}api/orders/export`,
            responseType: "blob",
            body: { token: token }
        });
        var blob = new Blob([req.data], {
            type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Sales Data.xlsx`;
        link.click();
    }

    const productVal = () => {
        let ext = products && products.find(item => item.value == filters.product)
        return ext ? ext : ''
    }

    const resellerVal = () => {
        let ext = resellers && resellers.find(item => item.value == filters.reseller)
        return ext ? ext : ''
    }

    const categoryVal = () => {
        let ext = categories && categories.find(item => item.value == filters.category)
        return ext ? ext : ''
    }

    const onRangeChange = (e, type = 'order') => {

        let f = {
            startDate: datepipeModel.datetostring(e.startDate),
            endDate: datepipeModel.datetostring(e.endDate),
        }

        if (type == 'travel') {
            f = {
                travelStartDate: datepipeModel.datetostring(e.startDate),
                travelEndDate: datepipeModel.datetostring(e.endDate),
            }
        }
        if (datepipeModel.datetostring(e.startDate) != datepipeModel.datetostring(e.endDate)) {
            filter({ ...f })
        } else {
            setFilter({ ...filters, ...f })
        }
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getOrders({ page: e })
    }

    const sortClass=(key)=>{
        let cls='fa-sort'
        if(filters.key==key && filters.sorder=='asc') cls='fa-sort-up'
        else  if(filters.key==key && filters.sorder=='desc') cls='fa-sort-down'
        return 'fa '+cls
      }

      const sorting=(key)=>{
        let sorder='asc'
        if(filters.key==key){
          if(filters.sorder=='asc'){
            sorder='desc'
          }else{
            sorder='asc'
          }
        }
        

        let sortBy=`${key} ${sorder}`;
        setFilter({...filters,sortBy,key,sorder})
        getOrders({sortBy})
      }

    return (
        <Layout>
            <div className="">
                <div className='d-flex justify-content-between pb-3'>
                    <div>
                    <h3 className='hedding '>Sales Data</h3>
                    <p className='text_p '>Here  you can see all about your product statistics</p>
                    </div>
                
                </div>
                
          
            <div className='table_section'>
            <div className='div_dropdown mt-3 mb-3'>
                    <div className=' search_form'>
                        {/* <label>Search by Reseller Name</label> */}
                        <form onSubmit={e=>{e.preventDefault();filter()}}>
                        <input className='form-control input_search' value={filters.search} onChange={e => setFilter({ ...filters, search: e.target.value })} placeholder='Search for Order or Customer' />
                        </form>
                        <i class="fa fa-search" aria-hidden="true"></i>
                    </div>
                    <div className=''>
                        <button className='btn btn-primary' onClick={e => filter()}>Search</button>
                    </div>
                    <div className='dropDownSales'>
                        <Select
                            options={products}
                            placeholder="All Product"
                            value={productVal()}
                            isClearable={true}
                            name="Product"
                            onChange={e => filter({ product: e ? e.value : '' })}
                        />
                    </div>
                    <div className=''>
                        <DateRangePicker
                        placeholder='Order Date'
                            value={{
                                startDate: filters.startDate,
                                endDate: filters.endDate
                            }}
                            onChange={e => onRangeChange(e)}
                        />
                        {filters.startDate?<a className='text-primary' onClick={e=>filter({startDate:'',endDate:''})}>Reset Dates</a>:<></>}
                         
                    </div>
                    <div className=''>
                        <DateRangePicker
                          placeholder='Travel Date'
                            value={{
                                startDate: filters.travelStartDate,
                                endDate: filters.travelEndDate
                            }}
                            onChange={e => onRangeChange(e, 'travel')}
                        />
                        {filters.travelStartDate?<a className='text-primary' onClick={e=>filter({travelStartDate:'',travelEndDate:''})}>Reset Dates</a>:<></>}
                    </div>
                   
                   {/* <button className='btn btn-primary' onClick={e=>resetDates()}>Reset Dates</button> */}
                    <div className='dropDownSales'>
                        <Select
                            options={resellers}
                            placeholder="All Resellers"
                            value={resellerVal()}
                            isClearable={true}
                            name="Resellers"
                            onChange={e => filter({ reseller: e ? e.value : '' })}
                        />
                    </div>
                    <div className='dropDownSales'>
                        <Select
                            options={categories}
                            placeholder="All Categories"
                            value={categoryVal()}
                            isClearable={true}
                            name="Categories"
                            onChange={e => filter({ category: e ? e.value : '' })}
                        />
                    </div>

                    <div className=''>
                        <SelectDropdown
                            isSingle={true}
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="All Order Status"
                            intialValue={filters.status}
                            result={e => filter({ status: e ? e.value : '' })}
                            options={orderStatusModel.list}
                        />
                    </div>

                    <div className=''>
                        <SelectDropdown
                            isSingle={true}
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="All Coupon Code"
                            intialValue={filters.coupon}
                            result={e => { }}
                            options={[

                            ]}
                        />
                    </div>

                    <div className=''>
                        <SelectDropdown
                            isSingle={true}
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="All Waiver Status"
                            intialValue={filters.waiverStatus}
                            result={e => { }}
                            options={[

                            ]}
                        />
                    </div>
                    <button className='btn btn-primary download_icon' onClick={e => exportfun()}><i class="fa fa-download mr-2" aria-hidden="true"></i>Export CSV</button>
                    {isClear() ? <button className='btn btn-secondary ml-2' onClick={e => clear()}>Clear</button> : <></>}
                </div>
            <div className=" table-responsive ">
                <table class="table table_price table-striped">
                    <thead className='table_head'>
                        <tr className='heading_row'>
                            <th scope="col" className='pointer' onClick={e=>sorting('orderNumber')}>Order Id <i className={sortClass('orderNumber')} /></th>
                            <th scope="col" className='pointer' onClick={e=>sorting('dateCreated')}>Order Date & Time <i className={sortClass('dateCreated')} /></th>
                            <th scope="col" className='pointer' onClick={e=>sorting('customerName')}>Customer Name <i className={sortClass('customerName')} /></th>
                            <th scope="col" className='pointer' onClick={e=>sorting('customerEmail')}>Customer Email/Phone <i className={sortClass('customerEmail')} /></th>
                            <th scope="col" className='pointer list_product' >Product Name </th>
                            <th scope="col" className='pointer' >Number of Pax</th>
                            <th scope="col" className='pointer' onClick={e=>sorting('orderAmount')}>Order Amount <i className={sortClass('orderAmount')} /></th>
                            <th scope="col" className='pointer' onClick={e=>sorting('Discount')} >Discounts<i className={sortClass('Discount')}></i> </th>
                            <th scope="col" className='pointer'>Travel Date & Time</th>
                            <th scope="col" className='pointer' onClick={e=>sorting('sourceChannel')}>Reseller<i className={sortClass('sourceChannel')} /></th>
                            <th scope="col" className='pointer' onClick={e=>sorting('sourceChannel')}>Reseller Commissions <i className={sortClass('sourceChannel')} /></th>
                            <th scope="col" className='pointer' >Order Status</th>
                            <th scope="col" className='pointer' >Waiver Status </th>
                            <th scope="col" className='table_data'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {orderslist && orderslist.map((itm, i) => {
                            return <tr className='data_row' onClick={e => vieworder(itm.id)}>
                                <td className='table_dats' onClick={e => vieworder(itm.id)}>{itm.orderNumber}</td>
                                <td className='table_dats'>{datepipeModel.date(itm.dateCreated,user?.companyDateFormat,false,itm?.productDetail?.timezone)}<p className='phone_no'>{datepipeModel.time(itm.dateCreated,itm?.productDetail?.timezone)}</p></td>
                                <td className='table_dats'><span class="list_product"> {itm?.customerName}</span></td>
                                <td className='table_dats'>{itm?.customer.email}
                                {itm?.customer.phone?<><p className='phone_no'>+{itm?.customer.phone}</p></>:<></>}
                                
                                
                                </td>
                                <td className='table_dats'><span className=''>{itm?.items[0]?.productName}</span></td>
                                <td className='table_dats text-right'>{itm.totalQuantity}</td>
                                <td className='table_dats text-right'> {pipeModel.currency(Number(itm.totalPaid) - Number(itm.commission),'',user.companyCurrencyFormat)}</td>
                                <td className='table_dats'>--</td>
                                <td className='table_dats'>{datepipeModel.date(itm?.items?.[0]?.startTime,user.companyDateFormat,false,itm?.productDetail?.timezone)}<p className='phone_no'>{datepipeModel.time(itm?.items?.[0]?.startTime,itm?.productDetail?.timezone)}</p></td>
                                <td className='table_dats'>{itm?.sourceChannel || "Direct"}</td>
                                <td className='table_dats text-right'><p className='phone_no'>{pipeModel.currency(Number(itm.commission),'',user.companyCurrencyFormat)} </p></td>
                                <td className='table_dats'> <div className={`user_hours ${itm.status}`}>
                                    <span className='contract'>
                                        {orderStatusModel.name(itm.status)}
                                    </span>
                                </div></td>
                                <td className='table_dats'>--</td>
                                <td className='table_dats'><i class="material-icons" onClick={e => vieworder(itm.id)}>visibility</i></td>
                            </tr>
                        })
                        }
                    </tbody>
                </table>
            </div>
</div>
            {!total ? <div className="py-3 text-center">No Data</div> : <></>}
            {total > filters.count ? <div className='paginationWrapper'>
                    <span>Show {filters.count} from {total} Cards</span>
                    <Pagination
                        currentPage={filters.page}
                        totalSize={total}
                        sizePerPage={filters.count}
                        changeCurrentPage={pageChange}
                    />
                </div> : <></>
            }
              </div>
        </Layout>
    );
};

export default SalesData;
