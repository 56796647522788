import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import methodModel from "../../methods/methods";
import loader from "../../methods/loader";
import Header from "../HomePage/Header";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Dropdown } from "react-bootstrap";

const OTPpage = () => {
    const dispatch = useDispatch();
    const email = localStorage.getItem('SignUpEmail')
    const [id, setid] = useState('')
    const token = localStorage.getItem('token')
    const user = useSelector((state)=>state.user)
    const user_id = window.location.search;
    console.log(user_id,'=============')
    let ID = user_id.split('?user_id=')
    console.log(ID)
    useEffect(()=>{
        if(ID){
            setid(ID[1])
        }
    },[])
   
    // const id = localStorage.getItem('SignUpId')
    const [otp, setotp] = useState()
    const history = useHistory()
    // useEffect(() => {
    //     if (!token) {
    //         history.goBack()
    //     }
    // }, [token])
    useEffect(() => {


        const SignUpId = localStorage.getItem('SignUpId')
        setid(SignUpId)

    })
    useEffect(() => {
        loader(true)
        setTimeout(() => {
            loader(false)
        }, 600);
    }, [])
    const handleOTP = (e) => {
        e.preventDefault()

        ApiClient.post(`user/verify-email`, { id: id, verificationCode: otp }).then(res => {
            if (res.success) {
                // setTimeout(() => {
                //     toast.success(res.message)
                // }, 100);
                // ApiClient.post(`api/auto/login`, { id: id }).then(res => {
                //     if (res.success) {
                localStorage.setItem("token", res.data.access_token);
                dispatch(login_success(res.data));
                localStorage.removeItem('SignUpEmail')
                localStorage.removeItem('SignUpId')
                if (res?.data?.is_plan) {
                    history.push(`/appointment`)
                } else {
                    history.push(`/next`)
                }

                // }
                // })
            }
        })
    }

    const resendCode = () => {
        loader(true)
        ApiClient.post(`user/resend-otp`, { id: id }).then(res => {
            if (res.success) {
                toast.success(res.message)
            }
            loader(false)
        })
    }

    return (
        <>
         <header className='header_main'>
      <div className=' container-fluid p-0'>
        <nav class="navbar navbar-expand-lg bg-transparent  w-100 navbar-light ">
          {location?.pathname == "/" ? <a class="navbar-brand" href="/"><img src="./assets/img/therpy/logo-main.png" className='img-fluid' /></a> :
            <a class="navbar-brand" href="/"><img src="./assets/img/therpy/logo-blue.png" className='img-fluid' /></a>}
          <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse " id="navbarText">
            <ul class="navbar-nav ml-auto">
            
              <li class={`nav-item ${window.location.pathname == '/contact' ? "active" : ''}`}>
            
                <button onClick={()=>{
                    history.goBack()
                }}  className='btn btn-primary'>Go Back</button>
               
              </li >
            </ul >
          </div >
        </nav >
      </div >
    </header >
            <div className="centerbody bg_leaf position-relative">
               
                <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
                <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
                <div className="container-fluid">
                    <div className="row align-items-center mx-0">

                        <div className="col-10 col-md-5 mx-auto p-0">
                            <div className="">
                                <form className="width50_auto_boxshaodow text-center" onSubmit={handleOTP}>

                                    <div className="mb-4">
                                        <h3 className="lgtext">We have just emailed you.</h3>
                                        <p>Please enter the code we emailed to you.<br />{email}</p>
                                    </div>
                                    <div className="inner_login mb-5">
                                        <label className="mb-4">Enter verification code</label>
                                        <input type='text' className="form-control" value={otp} onChange={e => setotp(methodModel.isNumber(e))} minLength={4} maxLength={4} placeholder="Please enter verification code" required />
                                    </div>
                                    <div className="m-auto w-100">
                                        <button className="btn btn-primary loginclass w-100 ">Next</button>
                                        <div className="text-center pt-3">
                                            <a onClick={e => resendCode()}>Resend verification code</a>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer position="top-right" />
        </>
    )
}
export default OTPpage