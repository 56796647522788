import React, { useEffect, useState } from 'react'
import Header from '../HomePage/Header'
import Footer from '../HomePage/Footer/Footer'
import ApiClient from '../../methods/api/apiClient'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector } from 'react-redux'

function Reason() {
    const [form, setform] = useState({})
    const history = useHistory()
    const user = useSelector((state) => state.user)
    console.log(user)
    useEffect(()=>{
if(user.reason){
    history.push('/next')
}
    },[])
    const HandleSubmit = (e) => {
        e.preventDefault()
        ApiClient.put('user/profile', { reason: form?.reason }).then((res) => {
            if (res.success) {
                history.push('/next')
            }
        })
    }
    return (
        <>
            <Header />
            <div className='col-12 col-sm-12 col-md-10 col-lg-7 mx-auto'>
                <div className='starting_quest'>
                   

                    <form onSubmit={HandleSubmit} >


                        {/* <div className="col-md-6 mb-3"> */}
                        <label style={{ fontSize: '20px' }} className='text-md'>
                            What brings you here?<span className="star">*</span>
                        </label>
                        <div className='questinganweser_disclamier'>
                        <div className='disc_icon mb-4'>
                            <i className='fa fa-info'></i>
                            <span> Join the 7,919 people that started therapy with their tailored therapist match this week. Let your therapist know what you’d like to work on below. The more information you provide, the better your therapist will understand where to begin. </span>
                        </div>
                    </div>  
                        <textarea
                            onChange={(e) => {
                                setform({ ...form, reason: e.target.value })
                            }}
                            type="text"
                            name=""
                            cols={5}
                            required
                            rows={5}
                            className="form-control"
                            id=""
                            value={form?.reason}
                            placeholder='What brings you here...?'
                        />
                        {/* </div> */}
                        <div className='buttn_next mt-3 text-end'>
                            <button type='submit' className='btn btn-primary btn-sm' >Submit </button>
                        </div>

                        {/* <button className='btn btn-primary mt-4'>Submit</button> */}
                    </form>
                </div>
            </div>

            <Footer />
        </>
    )
}

export default Reason
