import React, { useEffect, useState } from 'react';
import "./style.css"
import Header from '../HomePage/Header';

import Footer from '../HomePage/Footer/Footer';
import ApiClient from '../../methods/api/apiClient';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const TherspistQuestions = () => {

    const [isScrolled, setIsScrolled] = useState(false);
    const [data, setData] = useState([])
    const [currentQuestion, setCurrentQuestion] = useState(0);
    const [selectedAnswer, setSelectedAnswer] = useState(null);
  const history = useHistory()
    const getData = () => {
      // setLoader(true)
      ApiClient.get('all/questions/list').then(res => {
        if (res.success) {
          setData(res.data?.data)
        }
        // setLoader(false)
      })
    }
  
    useEffect(() => {
      getData()
    }, [])

    useEffect(() => {
        let timeoutId;

        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const scrollThreshold = 150;

            // Check if the scroll position is beyond the threshold
            setIsScrolled(scrollTop > scrollThreshold);

            // Clear any existing timeout
            clearTimeout(timeoutId);

            // Set a new timeout to add the class after 5 seconds
            timeoutId = setTimeout(() => {
                setIsScrolled(false);
            }, 8000);
        };

        // Add scroll event listener
        window.addEventListener('scroll', handleScroll);

        // Clean up the event listener and timeout on component unmount
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearTimeout(timeoutId);
        };
    }, []);
 const handleAnswerOptionClick = (answerIndex) => {
    const questionType = data[currentQuestion]?.answer_type;
    const selectedOptions = selectedAnswer ? [...selectedAnswer] : [];

    if (questionType === 'single') {
      setSelectedAnswer(answerIndex);
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < data.length) {
        setCurrentQuestion(nextQuestion);
        setSelectedAnswer(null); 
      } else {
        console.log('End of questions reached.');
        history.push("/signup");
      }
    } else if (questionType === 'multiple') {
      const selectedIndex = selectedOptions.indexOf(answerIndex);
      if (selectedIndex === -1) {
        selectedOptions.push(answerIndex);
      } else {
        selectedOptions.splice(selectedIndex, 1);
      }
      setSelectedAnswer(selectedOptions);
    }
  };

    return <>

        <section className='sec-one bg-theripst-img'>
            <Header />
            <div className='heaigt100_center'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-12 col-sm-12 col-md-10 col-lg-8 mx-auto'>
                            <div className='theripst_head text-center mb-4'>
                                <h2>Join 14,923 therapists who are earning more with <span className='bold_white'> Internal Therapy!</span></h2>
                                <p>Select an option to get started</p>
                            </div>

                            <div className='thepiest_radio width60'>
                                <div className='inner_radio_thepst onetworadio text-center'>
                                    <input type="radio" class="d-none hideradio" id="one_therpsit" />
                                    <label for="one_therpsit" class="label_answer label_answer_therpist" onClick={(e)=>{
                                        localStorage.setItem('role',e.target.value)
                                        history.push('/therapistquest')
                                    }}> Clinical Social Worker
                                        <p className='m-0'>(LCSW, LICSW, LISW, LICSW, etc.)</p>
                                    </label>
                                </div>
                            </div>

                            <div className='thepiest_radio width60'>
                                <div className='inner_radio_thepst onetworadio text-center'>
                                    <input type="radio" class="d-none hideradio" id="one_therpsit" />
                                    <label onClick={(e)=>{
                                        localStorage.setItem('role','Marriage & Family Therapist')
                                        history.push('/therapistquest')
                                    }} for="one_therpsit" class="label_answer label_answer_therpist ">Marriage & Family Therapist
                                        <p className='m-0'>(LMFT, LCMFT, LIMFT, etc.)</p>
                                    </label>
                                </div>
                            </div>

                            <div className='thepiest_radio width60'>
                                <div className='inner_radio_thepst onetworadio text-center'>
                                    <input type="radio" class="d-none hideradio" id="one_therpsit" />
                                    <label onClick={(e)=>{
                                        localStorage.setItem('role','Mental Health Counselor')
                                        history.push('/therapistquest')
                                    }} for="one_therpsit" class="label_answer label_answer_therpist">Mental Health Counselor
                                        <p className='m-0'>(LMHC, LCMHC, LMHP, LPMHC etc.)</p>
                                    </label>
                                </div>
                            </div>

                            <div className='thepiest_radio width60'>
                                <div className='inner_radio_thepst onetworadio text-center'>
                                    <input type="radio" class="d-none hideradio" id="one_therpsit" />
                                    <label onClick={(e)=>{
                                        localStorage.setItem('role','Professional Counselor')
                                        history.push('/therapistquest')
                                    }} for="one_therpsit" class="label_answer label_answer_therpist">Professional Counselor
                                        <p className='m-0'>(LPC, LPCC, LCPC, etc.)</p>
                                    </label>
                                </div>
                            </div>

                            <div className='thepiest_radio width60'>
                                <div className='inner_radio_thepst onetworadio text-center '>
                                    <input type="radio" class="d-none hideradio" id="one_therpsit" />
                                    <label onClick={(e)=>{
                                        localStorage.setItem('role','Psychologist')
                                        history.push('/therapistquest')
                                    }} for="one_therpsit" class="label_answer label_answer_therpist d-flex justify-content-center align-items-center">Psychologist

                                    </label>
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
            
            </div>



        </section>


        <section className='p80'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-6'>
                        <div className='privt_sch_inner'>
                            <h3>You decide how much you want to work & when you're available. We'll take care of the client referrals & billing details.</h3>
                            <div className='mt-4 icons-text'>
                                <div className='gap_ing'>
                                    <img src="/assets/img/therpy/therpist1.png" className='img-fluid' />
                                    <p >$450-650 monthly Health Insurance Stipend *1</p>
                                </div>
                                <div className='gap_ing'>
                                    <img src="/assets/img/therpy/therpist2.png" className='img-fluid' />
                                    <p >Bonuses & Incentives for High Performers</p>
                                </div>

                                <div className='gap_ing'>
                                    <img src="/assets/img/therpy/therpist3.png" className='img-fluid' />
                                    <p>Free Internal Therapy Membership</p>
                                </div>

                                <div className='gap_ing'>
                                    <img src="/assets/img/therpy/therpist4.png" className='img-fluid' />
                                    <p>Free Internal Therapy Membership</p>
                                </div>

                            </div>
                            <div className='mt-4 ul_lists'>
                                <ul className='ulclass'>
                                    <li>*1 Eligibility for the Health Insurance Stipend requires a continuous commitment of 25-30 hours a week.</li>
                                    <li>*2 Internal Therapy does not guarantee a min. amount of earnings, the above calculator is an estimate based on prevailing factors & incentives on the platform.</li>
                                    <li>*3 Terms & conditions apply. Apply to learn more.</li>
                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className='col-md-6'>
                        <div className='slider_range_theripst'>
                            <div className='slide_inner text-center'>
                                <h3>Estimated Earnings with Internal Therapy</h3>
                                <div className='radio_fulltime'>
                                    <p>I want to work:</p>
                                    <p className='rao_rights'>
                                        <span className='radio_custom'>
                                            <label class="custom-radio">
                                                <input type="radio" name="customRadio" />
                                                <span class="radio-icon"></span>
                                                Full Time
                                            </label>

                                            <label class="custom-radio">
                                                <input type="radio" name="customRadio" />
                                                <span class="radio-icon"></span>
                                                Part Time
                                            </label>
                                        </span>
                                    </p>
                                </div>


                            </div>

                            <div className='range_laysot'>
                                <input type="range" className='custom_range' />
                                <div className='d-flex justify-content-between'>
                                    <span>30</span>
                                    <span>Hrs per week</span>
                                    <span>50</span>
                                </div>
                            </div>

                            <div className='text-center'>
                                <h4 className='headingh4'>Estimated Annual Earnings:  <span className='font300'>$100,100</span></h4>
                                <div className='mt-4'>
                                    <button className='btn btn-primary'>Get Started</button>
                                </div>
                            </div>




                        </div>
                    </div>
                </div>
            </div>
        </section>



        {/* table pricing */}
        <section className='p80 shapebeforeafter bg_blue'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-10 col-lg-10 col-xl-10 mx-auto'>
                        <div className='table_cusomt'>
                            <div className='table_head text-center'>
                                <h2 className='h2class text-white'>Internal Therapy vs. Traditional in-office therapy</h2>
                            </div>
                            <div className='table-responsive'>
                                <table className='table table-hover counselor-table'>
                                    <thead>
                                        <tr>
                                            <th className='first-column'></th>
                                            <th className='logo-therpy'>
                                                <div className='imglogo'>
                                                    <img src="/assets/img/therpy/logo-main.png" className='img-fluid' />
                                                </div>
                                            </th>
                                            <th className='last-column'>In-Office</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='table-comparison-text'>Work from anywhere</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/cross.png" className='img-fluid' /></td>
                                        </tr>

                                        <tr>
                                            <td className='table-comparison-text'>Set your own hours</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/question.png" className='img-fluid' /></td>
                                        </tr>

                                        <tr>
                                            <td className='table-comparison-text'>See clients in-person</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/cross.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                        </tr>

                                        <tr>
                                            <td className='table-comparison-text'>Clinical autonomy</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                        </tr>

                                        <tr>
                                            <td className='table-comparison-text'>No overhead or marketing costs</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/question.png" className='img-fluid' /></td>
                                        </tr>

                                        <tr>
                                            <td className='table-comparison-text'>No insurance paneling or paperwork</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/question.png" className='img-fluid' /></td>
                                        </tr>
                                        <tr>
                                            <td className='table-comparison-text '>Get paid weekly</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/question.png" className='img-fluid' /></td>
                                        </tr>
                                        <tr>
                                            <td className='table-comparison-text'>Free membership</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/question.png" className='img-fluid' /></td>
                                        </tr>
                                        <tr>
                                            <td className='table-comparison-text'>Compensated for phone calls, messages, intake, etc.</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/question.png" className='img-fluid' /></td>
                                        </tr>
                                        <tr>
                                            <td className='table-comparison-text'>Access to the world's largest therapy network</td>
                                            <td className='table-comparison-icon active_table'> <img src="/assets/img/therpy/check.png" className='img-fluid' /></td>
                                            <td className='table-comparison-icon'> <img src="/assets/img/therpy/question.png" className='img-fluid' /></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>



        <section className='p80 videotag'>
            <div className='container'>
                <div className='row'>
                    <div className='col-12 col-sm-12 col-md-5 col-lg-5'>
                        <div className='textquest'>
                            <img className='img-fluid' src="/assets/img/therpy/question-black.png" />
                            <h4>See what other <br></br> <span className='bluetext'>therapists</span> have to say <span className='d-block'> about us</span></h4>
                        </div>
                    </div>

                    <div className='col-12 col-sm-12 col-md-7 col-lg-7'>
                        <div className='video_tag'>
                            <img src="/assets/img/therpy/video_tag.png" className='img-fluid' />
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className='p80 requirments pt-0'>
            <div className='container'>
                <div className='row'>
                    <div className='reuints_text'>
                        <h5 className='bluetext'>Requirements</h5>
                        <ul>
                            <li>Valid license to provide clinical therapy issued by a state board (e.g., LCSW, LMFT, LPC, PsyD, or similar credentials)</li>
                            <li>Individual NPI number</li>
                            <li>3+ years of experience in therapy for adults, couples, and/or teens</li>
                            <li>Desktop or laptop computer with a reliable internet connection and a webcam</li>
                            <li>Currently residing in the U.S.</li>
                        </ul>
                        <p>Note: Unfortunately, if you are an intern or require supervision to provide therapy services, you cannot be a provider at Internal Therapy at this time. Therapists are not Internal Therapy employees, but independent providers.</p>
                    </div>

                    <div className='largebtn text-center mt-4'>
                        <button className='btn btn-primary btn-lg'>Start Application</button>
                    </div>


                </div>
            </div>
        </section>


        <section className='p80 pt-0'>
            <div className='container'>
                <div className='row'>
                    <div className='actiondanger'>
                        <div className="disc_icon">
                            <img className='img-fluid' src="/assets/img/therpy/fraud.png" />
                            <div className=''>
                                <h6><b>Fraud Policy</b></h6>
                                <p className='m-0'>Internal Therapy does not require you to install an application to your computer, nor do we support chat apps such as Wire, WhatsApp, or Signal for any legitimate use. Therapists are not required to pay fees or incur any costs to join us.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


        <section className='p80 pt-0'>
            <div className='container'>
                <div className='row'>


                    <div className="footerarea d-flex justify-content-between align-items-center">



                        <p className='m-0'>If you are in a crisis or any other person may be in danger - don't use this site. <span className='d-block'> <b className='bluecolor'>These resources</b> can provide you with immediate help.</span></p>
                        <img className='img-fluid' src="/assets/img/therpy/securepayment.png" />
                    </div>



                </div>
            </div>
        </section>






        <Footer />
    </>
};

export default TherspistQuestions;
