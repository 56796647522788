import React from 'react';
import SelectDropdown from "../../components/common/SelectDropdown"
import datepipeModel from '../../models/datepipemodel';
import dynamicPriceModel from '../../models/dynamicPrice.model';
import pipeModel from '../../models/pipeModel';
import SingleCalendar from './SingleCalendar';
const Html = ({yearsList, filters,user, yearChange, years, views, viewChange, calendarList, monthChange, selectedDate, detail, editdynamicPricing, deletePricing,viewDynamicPricing, handleDateClick, handleEventClick, initialDate, events, exta, monthInitialView, next, back, colors }) => {

  return (
    <>
      <ul class="nav nav-tabs">
        {views.map(itm => {
          return <li class="nav-item">
            <a class={`nav-link ${itm.id == filters.view ? 'active' : ''}`} onClick={e => viewChange(itm.id)}>{itm.name}</a>
          </li>
        })}
      </ul>

      <div className="row mt-3">
        <div className="col-md-8">
          <div className='calendarFilter'>
            <SelectDropdown
              isSingle={true}
              id="statusDropdown"
              displayValue="name"
              placeholder="Year"
              noDefault={true}
              intialValue={filters.year}
              result={e => { yearChange(e.value) }}
              options={yearsList(filters.year)}
            />

<div className='d-flex justify-content-between relativeCls'>
              <i className='fa fa-chevron-left' onClick={e => back()}></i>
                <i className='fa fa-chevron-right' onClick={e => next()}></i>
              </div>
          </div>
          {filters.view != 'year' ? <>
            <div id="calendar"></div>
          </> : <>
            <div className="row w-100 pt-4 m-0">
              {calendarList()?.map(itm => {
                return <>
                  <div className="col-md-12 mb-3">
                    <div className='shadow bg-white p-3'>
                      <SingleCalendar
                        id={`cal1_${itm.id}`}
                        initialDate={monthInitialView(itm.id)}
                        handleDateClick={handleDateClick}
                        handleEventClick={handleEventClick}
                        events={events}
                        exta={exta}
                      />
                    </div>
                  </div>
                </>
              })}

            </div>
          </>}
        </div>
        <div className="col-md-4">
          <div className='shadow p-3 mb-3'>
            {/* <div className='colorItem'>
              <div className={`color overrideBg`}></div>
              <span>Overlap</span>
            </div> */}
            {colors && colors.map(itm => {
              return <div className='colorItem'>
                <div className={`color ${itm.color}`}></div>
                <span>{itm.name}</span>
              </div>
            })}
          </div>
          <div className='shadow p-2'>
            {selectedDate ? <>
              <h5>{datepipeModel.date(selectedDate,user?.companyDateFormat)}</h5>
              <label className='font-weight-bold'>Dynamic Pricing</label>
              {detail?.dynamicPricing?.map((item,i) => {
                // return <div className='shadow p-2 mb-3 dpricingCard'>
                //   <div className="text-right">
                //     <a onClick={e => editdynamicPricing(itm)}><i className="fa fa-pen"></i></a>
                //     <i className="fa fa-trash m-2" onClick={e => deletePricing(itm.id)}></i>
                //   </div>
                //   {detail?.dynamicPricing?.length>1?<>  
                //   <label>Overlap</label>
                //   <p className='mb-2'>{i==0?'Yes':'No'}</p>
                //   </>:<></>}
                //   <label>Name of Rule</label>
                //   <p className='mb-2'>{itm.name}</p>
                //   <label>Date Applied</label>
                //   <p className='mb-2'>{datepipeModel.date(itm.dateApplied,user?.companyDateFormat)}</p>
                //   <label>Rule Valid date</label>
                //   <p className='mb-2'>{itm.changesApply == 'now' ? datepipeModel.date(new Date(),user?.companyDateFormat) : datepipeModel.date(itm.changesDate,user?.companyDateFormat)}</p>
                //   <label>Status</label>
                //   <p className='mb-2 text-capitalize'>{itm.status}</p>
                //   <label>Percent Or Amount</label>
                //   {itm.type=='lastminuteinventory'||itm.type=='earlybirdinventory'?<>
                //   <p className='mb-2 text-capitalize'>
                //   {itm.applyEarlyBirdPricing.map(aitm=>{
                //     return <span className='perAmSpan'>{aitm.percentOrAmount=='per'?'':'$'}{aitm.percentOrAmountValue||'0'}{aitm.percentOrAmount=='per'?'%':''}</span>
                //   })}
                //   </p>
                //   </>:<>
                //   <p className='mb-2 text-capitalize'>{itm.amountOrPercent=='per'?'':'$'}{itm.number||'0'}{itm.amountOrPercent=='per'?'%':''}</p>
                //   </>}
                  
                //   <label>Price</label>
                //     <p>{itm.price}</p>
                //   {itm?.priceTypes?.length && itm.applyPriceType == 'no' ? <>
                //     {itm?.priceTypes.map(pitm => {
                //       return <>
                //         <label>{pitm.label}</label>
                //         <p className='mb-2 text-capitalize'>{itm[pitm.label]}</p>
                //       </>
                //     })}
                   
                //   </> : <></>}

                // </div>
                return <div className={`box_details mb-3 ${dynamicPriceModel.color(item.type)} borderLeft`}>
                        <div className="heading_details border_heading">
                          <div className="heading_details justify-content-between w-100 flex-wrap">
                            <div>
                              <p class="general">{item?.name}</p>
                              <p class="text_general">{dynamicPriceModel.name(item.type)} </p>
                            </div>
                            <div className="tab_icons mt-2">
                              <span class="material-icons color1" onClick={e => viewDynamicPricing(item)}>visibility</span>
                              <span class="material-icons color1" onClick={e => editdynamicPricing(item)}>edit</span>
                              <span class="material-icons color2" onClick={e => deletePricing(item.id)}>delete_outline</span>
                            </div>
                          </div>
                        </div>
                        <div className="">
                          <div className='table_layout table-responsive mb-3'>
                            <table className='table table-striped'>
                              <tbody>

                                <tr className='data_row'>
                                  <td className="vertical">
                                    <p>Name of Rule</p>
                                    <h1> {item?.name}</h1>
                                  </td>
                                  </tr>

                                  <tr className='data_row'>
                                  <td className="vertical">
                                    <p>Date Applied</p>
                                    <h1>{datepipeModel.date(item.createdAt,user?.companyDateFormat)}</h1>
                                  </td>
                                  </tr>

                                  <tr className='data_row'>
                                  <td className="vertical">
                                    <p>Updated Date</p>
                                    <h1>{item.updatedAt ? datepipeModel.date(item.updatedAt,user?.companyDateFormat): ''}</h1>
                                  </td>
                                  </tr>

                                  <tr className='data_row'>
                                  <td className="vertical">
                                    <p>Rule Valid Date</p>
                                    <h1>{item.changesApply == 'now'  || item.type=='earlybirdtimeframes'? datepipeModel.date(new Date(),user?.companyDateFormat) : datepipeModel.date(item.changesDate,user?.companyDateFormat)}</h1>
                                  </td>
                                  </tr>

                                  <tr className='data_row'>
                                  <td className="vertical">
                                    <p>Applied By</p>
                                    <h1>{item.addedBy?.fullName}</h1>
                                  </td>
                                  </tr>

                                  <tr className='data_row'>
                                  <td className="vertical">
                                    <p>Status</p>
                                    <button className="btn_table text-capitalize">{item.status}</button>
                                  </td>
                                </tr>

                              </tbody>
                            </table>

                          </div>
                          <div className="p-3">
                            <h4 className="heading_4">Details</h4>

                            <p class="mb-3 mt-1 detail_data">
                                        <span class="material-icons">
                                          emoji_symbols
                                        </span>
                                        Percent Or Amount:
                                        <span>
                                          {item.type == "lastminuteinventory" ||
                                          item.type == "earlybirdinventory" ? (
                                            <>
                                              <p className="mb-2 text-capitalize">
                                                {item.applyEarlyBirdPricing.map(
                                                  (aitm) => {
                                                    return (
                                                      <span className="perAmSpan">
                                                        {aitm.percentOrAmount == "per"?aitm.percentOrAmountValue:pipeModel.currency(aitm.percentOrAmountValue,user.companyCurrencyFormat)}
                                                        {aitm.percentOrAmount =="per" ? "%" : ""}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <p className="mb-2 text-capitalize">
                                                {item.amountOrPercent == "per"?item.number:pipeModel.currency(item.number,user.companyCurrencyFormat)}
                                                {item.amountOrPercent == "per" ? "%": ""}
                                              </p>
                                            </>
                                          )}
                                        </span>
                                      </p>
                              <p class="mb-3 mt-1 detail_data">
                                        <span class="material-icons">
                                        emoji_symbols
                                        </span>
                                        Discount or Premium:  <span>
                                          {item.type == "lastminuteinventory" ||
                                          item.type == "earlybirdinventory" ? (
                                            <>
                                              <p className="mb-2 text-capitalize">
                                                {item.applyEarlyBirdPricing.map(
                                                  (aitm) => {
                                                    return (
                                                      <span className="perAmSpan">
                                                     
                                                        {aitm.discOrPre||'--'}
                                                      </span>
                                                    );
                                                  }
                                                )}
                                              </p>
                                            </>
                                          ) : (
                                            <>
                                              <p className="mb-2 text-capitalize">
                                                {item.discOrPre||'--'}
                                              </p>
                                            </>
                                          )}
                                        </span>
                                      </p>
                            <p class="mb-3 mt-1 detail_data">
                              <span class="material-icons">calendar_today</span>
                              Travel Date: <span>{datepipeModel.date(item.lastMinutePricingFromDate,user?.companyDateFormat)} - {datepipeModel.date(item.lastMinutePricingToDate,user?.companyDateFormat)}</span></p>
                            <p class="mb-3 mt-1 detail_data">
                              <span class="material-icons">calendar_today</span>
                              Block-Out Dates: <span> 
                                {item?.blackOutDates?.map(bitm=>{
                                  return <span>{datepipeModel.date(bitm.startDate,user?.companyDateFormat)} - {datepipeModel.date(bitm.endDate,user?.companyDateFormat)}</span>
                                })}
                               </span>
                              </p>
                            <p class="mb-3 mt-1 detail_data">
                              <span class="material-icons">access_time</span>
                              Applied to All Time Slot:  <span>{item.applyToDaysTimeSlot=='yes'?'Yes':'No'}</span></p>
                            <p class="mb-3 mt-1 detail_data">
                              <span class="material-icons">local_fire_department</span>
                              Not Applicable on:  <span>
                              {item?.notApplicableFor?.map(nitm=>{
                                return <span className="spanBtn_1 d-inline-block mb-1">{nitm}</span>
                              })}
                                </span></p>
                          </div>
                        </div>
                      </div>
              })}



              {!detail?.dynamicPricing?.length ? <>
                <p className='mb-2'>Dynamic Pricing is not Applied for this date</p>
              </> : <></>}

            </> : <>
              <h5>Select Date</h5>
            </>}

          </div>
        </div>
      </div>

    </>
  );
};

export default Html;
