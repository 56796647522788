import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import methodModel from "../../methods/methods";
import "./style.scss";

const Thanku=()=>{
    useEffect(()=>{
        localStorage.removeItem('print')
    },[])
    return <>
    <div className="mainThanku">
        <div className="container text-center">
            <h2 className="thankuText mb-3 text-capitalize">You've finished signing!</h2>
            <p>Your Submission has beed recieved.</p>
            <p>We will be in touch and contact you soon.</p>
            <div className="text-center">
                <Link to={`/crm/products/${methodModel.getPrams('contractId')}?custom=true&print=true`}>Download Contract template</Link>
            </div>
        </div>
    </div>
    </>
}

export default Thanku