import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../components/global/layout";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { useSelector } from "react-redux";
import methodModel from "../../methods/methods";

const All_review = () => {
  return (
    <Layout>

    <h3 className="totale_review">(All Reviews 206)</h3><hr></hr>
      <div className="d-flex justify-content-end">
        <div class="selectDropdown newdrop mr-3">
          <div class="dropdown addDropdown">
            <button
              class="btn btn-primary dropdown-toggle removeBg w-100"
              type="button"
              id="dropdownMenuButtonstatusDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Latest
            </button>
            <div
              class="dropdown-menu shadow bg_hover leftAlignDrop"
              aria-labelledby="dropdownMenuButtonstatusDropdown"
            >
              <a class="dropdown-item">Select </a>
              <a class="dropdown-item">sales1</a>
            </div>
          </div>
        </div>

        <div class="selectDropdown newdrop mr-3">
          <div class="dropdown addDropdown">
            <button
              class="btn btn-primary dropdown-toggle removeBg w-100"
              type="button"
              id="dropdownMenuButtonstatusDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Latest
            </button>
            <div
              class="dropdown-menu shadow bg_hover leftAlignDrop"
              aria-labelledby="dropdownMenuButtonstatusDropdown"
            >
              <a class="dropdown-item">Select </a>
              <a class="dropdown-item">sales1</a>
            </div>
          </div>
        </div>

        <div class="selectDropdown newdrop mr-3">
          <div class="dropdown addDropdown">
            <button
              class="btn btn-primary dropdown-toggle removeBg w-100"
              type="button"
              id="dropdownMenuButtonstatusDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Latest
            </button>
            <div
              class="dropdown-menu shadow bg_hover leftAlignDrop"
              aria-labelledby="dropdownMenuButtonstatusDropdown"
            >
              <a class="dropdown-item">Select </a>
              <a class="dropdown-item">sales1</a>
            </div>
          </div>
        </div>


        <div class="selectDropdown latestdropcls mr-3">
          <div class="dropdown addDropdown">
            <button
              class="btn btn-primary dropdown-toggle removeBg w-100"
              type="button"
              id="dropdownMenuButtonstatusDropdown"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fa fa-filter" aria-hidden="true"></i> Latest
            </button>
            <div
              class="dropdown-menu shadow bg_hover leftAlignDrop"
              aria-labelledby="dropdownMenuButtonstatusDropdown"
            >
              <a class="dropdown-item">Select </a>
              <a class="dropdown-item">sales1</a>
            </div>
          </div>
        </div>
      </div>
<div className="row">
<div className="col-md-1">
<div className="review_img mt-2">
<img src="/assets/img/loginimg.png" className="reviewsimg"/>
</div>
  
</div>
<div className="col-md-5 mt-3 pl-0">
<div className="d-flex">
<h3 className="personname mr-2">akanksah</h3>
<i class="fa fa-star mr-2" aria-hidden="true"></i>
<i class="fa fa-star mr-2" aria-hidden="true"></i>
<i class="fa fa-star mr-2" aria-hidden="true"></i>
<i class="fa fa-star mr-2" aria-hidden="true"></i>
<i class="fa fa-star-o" aria-hidden="true"></i>

<h3 className="dayscl ml-2">3 day ago</h3>
</div>
<p className="reviewpaira">Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </p>
  <button className="replybtn"><i class='far fa-comment-dots'></i> Reply?</button>
</div>

</div>
      
    </Layout>
  );
};

export default All_review;
