function currency(num,c='',cf='.') {
   // let currency=c
   let currency=''
    let value='0'
    if(num){
      value=Number(num).toFixed(2)
      if(cf==','){
         value=value.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
      }else if(cf=='.'){
         value=value.replace('.',',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.')
      } else{
         value=value.replace('.',',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
      }
    } 
    
    return currency?`${value} ${currency}`:`$${value}  `
 }

 function number(num,nofloat=false) {
    let value=''
    if(num){
      if(nofloat){
         value=String(num).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }else{
         value=Number(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
       }
    }
   
    return value||'0'
 }

 const capitalizeFirstLetter=(str)=> {
   return str.charAt(0).toUpperCase() + str.slice(1);
 }

 const pipeModel={currency,number,capitalizeFirstLetter}
 export default pipeModel