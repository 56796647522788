import React, { useState, useEffect } from 'react';
import Layout from '../../components/global/layout';
import './style.scss'
import Html from './html';
import { companyType } from '../../models/type.model';
import methodModel from '../../methods/methods';
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import loader from '../../methods/loader';
import { useHistory } from 'react-router-dom';
import environment from '../../environment';
import { useDispatch, useSelector } from 'react-redux';
import { login_success } from '../../actions/user';
import addressModel from '../../models/address.model';

const CompanyDetails = () => {
  const user = useSelector((state) => state.user)
  const [form, setForm] = useState({ ...companyType })
  const [detail, setDetail] = useState()
  const [analyticsform, setAnalyticsForm] = useState({ propertyId: '', private_key: '' })
  const [tab, setTab] = useState('')
  const [years, setYears] = useState([])
  const [addressSellected, setAddressSellected] = useState(true)
  const [currency, setcurrency] = useState([])
  const formValidation = [
    { key: 'companymobileno', minLength: 10 },
  ]
  const history = useHistory()
  const dispatch = useDispatch()
  const [submitted, setSubmitted] = useState(false)
  const [categories, setCategories] = useState([])
  const [timezoneLoader, setTimezoneLoader] = useState(false)
  const [contacts, setContacts] = useState([])

  const getError = (key) => {
    return methodModel.getError(key, form, formValidation)
  }

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true)
    let invalid = methodModel.getFormError(formValidation, form)
    if (invalid && (!addressSellected && form.address) || timezoneLoader) return
    let value = { ...form,contacts:contacts}
    loader(true)
    ApiClient.put('api/user/profile', value).then(res => {
      if (res.success) {
        let uUser = { ...user, ...value }
        dispatch(login_success(uUser))
        toast.success(res.message)
        setTab('')
        if(!user?.on_trial && !user?.subscriptionId){
          history.push(`/plans`)
        }
      }
      loader(false)
    })
  };

  const getCategories = () => {
    ApiClient.get('api/categories/listing', { catType: environment.productTypeId, status: 'active' }).then(res => {
      if (res.success) {
        setCategories(res.data)
        
      }
    })
  }

  const getCurrency = () => {
    ApiClient.get(`api/currency/listing`, { status: 'active' }).then(res => {
      if (res.success) {
        setcurrency(res.data)
      }
    })
  }

  const tabChange = (t) => {
    let value = t
    if (tab == t) value = ''
    setTab(value)
  }

  const categoryname = (p) => {
    let value = '--'
    let ext = categories.find(itm => itm.id == p)
    if (ext) value = ext?.name
    return value
  }

  const currencyName = (p) => {
    let value = '--'
    let ext = currency.find(itm => itm.id == p)
    if (ext) value = ext?.currency
    return value
  }

  const addressResult = async (e) => {
    let address = {}
    if (e.place) {
      address = addressModel.getAddress(e.place)
      setAddressSellected(true)
    } else {
      setAddressSellected(false)
    }
    setForm({
      ...form,
      companyAddress: e.value,
      country: address.country || '',
      city: address.city || '',
      state: address.state || '',
      zipcode: address.zipcode || '',
      lat: address.lat || '',
      lng: address.lng || ''
    })
    if (e.place) {
      setTimezoneLoader(true)
      const apires = await addressModel.gettimeZone(e.place)
      setTimezoneLoader(false)
      setForm({
        ...form,
        companyAddress: e.value,
        country: address.country || '',
        city: address.city || '',
        state: address.state || '',
        zipcode: address.zipcode || '',
        lat: address.lat || '',
        lng: address.lng || '',
        companytimezone: apires?.data?.timeZoneId || ''
      })
    }
  }

  const getUserProfile = () => {
    loader(true)
    ApiClient.get('api/user/profile', { id: user._id }).then(res => {
      if (res.success) {
        let payload = companyType
        let value = res.data
        Object.keys(payload).map(itm => {
          payload[itm] = value[itm]
        })
        if(value.contacts?.length){
          setContacts(value.contacts)
        }else{
          addContact()
        }
        setForm(payload)
        setDetail(value)
      }
      loader(false)
    })
  }

  useEffect(() => {
    // getCategories()
    getCurrency()
    let year = new Date().getFullYear()
    let arr = []
    for (let i = 1; i <= 10; i++) {
      arr.push({
        id: year,
        name: year
      })
      year = year - 1
    }
    setYears([...arr])
    getUserProfile()
  }, [])

  const uploadJson=(e)=>{
    let files=e.target.files
    let file=files.item(0)
    ApiClient.postFormData('api/upload/jsonfile',{file:file}).then(res=>{
      if(res.fileName){
        setAnalyticsForm({...analyticsform,private_key:res.fileName})
        document.getElementById("jsonfile").value=''
      }
    })
  }

  const uploadImage = (e) => {
    setForm({ ...form, baseImg: e.target.value })
    
    let files = e.target.files
    let file = files.item(0)
   
    loader(true)
    ApiClient.postFormData('api/upload/image?modelName=users', { file: file, modelName: 'users' }).then(res => {
      if (res.fileName) {
        let image = res.fileName
        setForm({ ...form, logo: image, baseImg: '' })

        ApiClient.put('api/user/profile',{id:user._id,logo:image}).then(res=>{
          if(res.success){
            let uUser = { ...user,logo:image}
            dispatch(login_success(uUser))
            toast.success("Image is Uploaded")
          }
        })

      } else {
        setForm({ ...form, baseImg: '' })
      }
      loader(false)
    })
  }


  const addContact=()=>{
    contacts.push({
      personName:'',
      designation:'',
      contactNumber:'',
      email:''
    })
    setContacts([...contacts])
  }

  const removeContact=(index)=>{
    setContacts([...contacts.filter((itm,i)=>i!=index)])
  }

  const updateContact=(index,key,value)=>{
    let arr=contacts
    arr[index][key]=value
    setContacts([...arr])
  }

  return (
    <Layout>
      <Html
      contacts={contacts}
      addContact={addContact}
      updateContact={updateContact}
      removeContact={removeContact}
      detail={detail}
      uploadJson={uploadJson}
        setAnalyticsForm={setAnalyticsForm}
        analyticsform={analyticsform}
        user={user}
        years={years}
        timezoneLoader={timezoneLoader}
        addressSellected={addressSellected}
        addressResult={addressResult}
        categoryname={categoryname}
        currencyName={currencyName}
        form={form}
        setTab={tabChange}
        tab={tab}
        setform={setForm}
        uploadImage={uploadImage}
        handleSubmit={handleSubmit}
        getError={getError}
        submitted={submitted}
        categories={categories}
        currency={currency}
      />
    </Layout>
  );
};

export default CompanyDetails;
