import React, { useEffect, useRef, useState } from 'react'
import Header from '../HomePage/Header'
import Footer from '../HomePage/Footer/Footer'
import "./style.css"
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';
import { ConnectSocket, SocketURL } from './socket';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import moment from 'moment';
import environment from '../../environment';
import ImageView from "react-single-image-viewer";
import "react-single-image-viewer/dist/index.css";
import { ToastContainer, toast } from 'react-toastify';
import { login_success } from '../../actions/user';

const chat = (p) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const paramValue = queryParams.get('id');
  const [isScrolled, setIsScrolled] = useState(false);
  const history = useHistory()
  const dispatch = useDispatch()
  let user = useSelector(state => state.user)
  const router = useHistory()
  const [chat, setChat] = useState([]);
  const [contract, setContract] = useState({});
  const [imageName, setImageName] = useState('');
  const [documentName, setDocumentName] = useState('');
  const [chatMsg, setChatMsg] = useState("");
  const [roomId, setRoomId] = useState("");
  const [chatWith, setChatWith] = useState("");
  const [filters, setFilter] = useState({ search: "" });
  const [isImage, setImage] = useState(false);
  const [chatList, setChatList] = useState([]);
  const [status, setStatus] = useState("");
  const [isOnline, setIsOnline] = useState(false);
  const [activeData, setActiveData] = useState([])
  const [AppointTime, SetAppointTime] = useState([])
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
  const [timeRemaining, setTimeRemaining] = useState('');
  const [isButtonEnabled, setIsButtonDisabled] = useState(false);
  const [resheduleAppointTime, SetResheduleAppointTime] = useState([])
  const totalAppointment = [...resheduleAppointTime, ...AppointTime]
  const now = new Date();
  let appointmentStart
  let appointmentEnd

  useEffect(() => {
    let interval;
    interval = setInterval(() => {
      calculateTimeRemaining();
    }, 1000)

    return () => clearInterval(interval);
  }, [timeRemaining]);

  useEffect(() => {
    if (timeRemaining != 'No Ongoing Appointment' && timeRemaining == '0h 0m 0s') {
      ApiClient.get('user/profile').then((res) => {
        if (res.success) {
          dispatch(login_success(res?.data))
          history.push('/myappointment')
          // setData(res.data)
        }

      })
      history.push('/next')
    }
  }, [timeRemaining])

  const upcomingAppointment = totalAppointment?.find((appointment) => {
    const [startHour, endHour] = appointment.time.split('-');
    let cleanedEndHour = endHour.replace(/[^\d]/g, '');

    if (endHour.includes('PM')) {
      cleanedEndHour = parseInt(cleanedEndHour) + 12;
    }

     appointmentStart = new Date(appointment.date + 'T' + parseInt(startHour) + ':00:00');
     appointmentEnd = new Date(appointment.date + 'T' + parseInt(cleanedEndHour) + ':00:00');

    return now >= appointmentStart && now <= appointmentEnd;
  });

  const calculateTimeRemaining = () => {
    if (upcomingAppointment) {
      const timeDiff = appointmentEnd - now;
      console.log(timeDiff, "bbbb")
      const hours = Math.floor(timeDiff / (1000 * 60 * 60));
      const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
      const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
      setIsButtonDisabled(true)

      setTimeRemaining(`${hours}h ${minutes}m ${seconds}s`);
    } else {
      setIsButtonDisabled(false)
      // router.push('/myappointment')
      setTimeRemaining('No Ongoing Appointment');
    }
  };

  const gallaryData = () => {
    loader(true)
    ApiClient.get(`user/profile`).then(res => {
      if (res.success) {
        dispatch(login_success(res?.data))
        // setData(res.data)
      }
      loader(false)

    })
  };

  const activeUser = activeData?.room_members?.flatMap((data) => {
    return (
      {
        "user_id": data?.user_id,
        "user_image": data?.user_image,
        "user_name": data?.user_name,
        "room_name": activeData?.room_name,
        "room_id": activeData?.room_id,
        "isOnline": data?.isOnline
      })
  })

  const bootemel = useRef();
  const scrollToBottom = () => {
    bootemel?.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chat]);

  const deleteChatEveryone = (msgId) => {
    let payload = {
      type: "delete_for_everyone",
      message_id: msgId,
    };

    ConnectSocket.emit("delete-message", payload);
    const updatedMessages = chat.filter(message => message._id !== msgId);
    setChat(updatedMessages);
  };

  const deleteChatForme = (msgId) => {
    let payload = {
      type: "delete_for_me",
      message_id: msgId,
    };

    ConnectSocket.emit("delete-message", payload);
    const updatedMessages = chat.filter(message => message._id !== msgId);
    setChat(updatedMessages);
  };

  const getAcceptData = (p = {}) => {
    loader(true)
    // let filter = { ...filters, ...p }
    ApiClient.get('user/appointmentslist', { sortBy: "date asc", status: "accept" }).then(res => {
      if (res.success) {
        loader(false)
        SetAppointTime(res?.data)
      }

    })
  }

  useEffect(() => {
    getAcceptData()
  }, [])

  const TypingStatus = () => {
    let payload = {
      "typing": true
    };

    ConnectSocket.emit("typing", payload);
  };

  const profileData = () => {
    loader(true)
    //   if (id) {
    ApiClient.get(`therapist`).then((res) => {
      if (res.success) {
        loader(false)
        setChatWith({ ...res.data });
      }
    });
    //   }
  };

  const getResheduleAcceptData = (p = {}) => {
    loader(true)
    // let filter = { ...filters, ...p }
    ApiClient.get('user/reschedulelist', { sortBy: "date asc" }).then(res => {
      if (res.success) {
        loader(false)
        SetResheduleAppointTime(res?.data)
      }
    })
  }

  useEffect(() => {
    profileData();
    getResheduleAcceptData()
  }, []);

  const getChatList = (p = {}) => {

    let url = `${SocketURL}chat/user/recent-chats/all?user_id=${user?.id}&login_user_id=${user?.id}&search=${!p?.search ? "" : p?.search}`
    axios.get(url).then((res) => {
      if (res.data.success) {
        setChatList(res?.data?.data?.data)
      }
    });
  };

  const deleteMember = (userId, roomId) => {
    const payload = {
      "user_id": userId,
      "room_id": roomId
    }
    let url = `${SocketURL}chat/user/group/remove-member`
    axios.put(url, payload).then((res) => {
      if (res.data.success) {
        getGroupListMember(roomId)
      }
    });
  };

  useEffect(() => {
    if (!activeUser) {
      setActiveData(chatList[0])
    };
  }, [chat]);

  const filter = (p = {}) => {
    setFilter({ ...filters, ...p })
    getChatList({ ...p });
  };

  useEffect(() => {
    if (!user?.access_token) {
      router.push("/login");
    }
  }, []);

  const getDay = (time) => {
    let today = new Date();

    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    let todayform = moment(today).format("DD-MM-YYYY");
    let Yesterdayform = moment(yesterday).format("DD-MM-YYYY");
    let dateform = moment(time).format("DD-MM-YYYY");

    let date =
      todayform == dateform
        ? "Today"
        : Yesterdayform == dateform
          ? "Yesterday"
          : dateform;

    return date;
  };

  const isURL = (text) => {
    const containsDocuments = text.includes("documents/");
    return containsDocuments;
  };

  useEffect(() => {
    const handleReceiveMessage = (newdata) => {
      const data = newdata.data;
      const payload = {
        sender_name: data?.fullName,
        sender_image: data?.image,
        chat_file: data?._doc?.chat_file,
        content: data?._doc?.content,
        createdAt: data?._doc?.createdAt,
        invitation_id: data?._doc?.invitation_id,
        media: data?._doc?.media,
        room_id: data?._doc?.room_id,
        sender: data?._doc?.sender,
        type: data?._doc?.type,
        updatedAt: data?._doc?.updatedAt,
        _id: data?._doc?._id,
      }
      if (data?._doc?.room_id == localStorage.getItem("activeRoom")) {
        setChat(prevChat => [...prevChat, payload])
      };
    };

    ConnectSocket.on('receive-message', handleReceiveMessage);
  }, []);


  useEffect(() => {
    ConnectSocket.on(`delete-message`, (data) => {
      userMessage(data?.data?.room_id)
      getChatList()
    });
  }, [])

  useEffect(() => {
    ConnectSocket.emit("user-online", { user_id: user?.id });
    ConnectSocket.on(`user-online`, (data) => {
      const newdata = data?.data?.user_id;
      setIsOnline(true);
      setStatus(newdata);
    });
  }, []);

  const joinRoom = (jId) => {
    const payload = {
      room_id: jId,
      user_id: user.id,
    };
    ConnectSocket.emit("join-room", payload);
    ConnectSocket.emit("read-all-messages", { room_id: jId })
  };

  const uploadImage = (e) => {
    let files = e.target.files;
    let file = files.item(0);
    const fileExtension = file?.name.split(".").pop().toLowerCase();
    let isTrue = imageExtensions.includes(fileExtension);


    let url = isTrue
      ? "upload/image?modelName=users"
      : "single/documents";

    setImage(true);
    ApiClient.postFormData(url, { file: file }).then((res) => {
      if (res?.success) {
        let image = res.data.fullpath;
        // setFileName(file?.name)
        const payload = {
          roomId: roomId,
          type: "TEXT",
          sender: user.id,
          content: isTrue ? `images/users/${image}` : `documents/${res?.data?.imagePath}`,
          // proposal_id: proposaldata?._id,
          // campaign_id: proposaldata?.campaign_id,
          // chat_file:fileName
        };
        ConnectSocket.emit(`send-message`, payload);
        setChatMsg("");
      }
      setImage(false);
    });
  };

  const uploadImageContract = (e, typ) => {
    let files = e.target.files;
    let file = files.item(0);

    let url = typ == 'img' ? "upload/image?modelName=users" : "single/documents?modelName=Users";
    loader(true);
    ApiClient.postFormData(url, { file: file }).then((res) => {
      if (res.success) {
        let image = res.data.imagePath;
        if (typ == 'img') {
          setImageName(file?.name)
          setContract({ ...contract, image: image });
        } else {
          setDocumentName(file?.name)
          setContract({ ...contract, document: image });

        }
      }
      loader(false);
    });
  };

  const handelSubmit = (e) => {
    e.preventDefault();

    if (!chatMsg) {
      return
    }

    if (!isButtonEnabled) {
      toast.error('No Ongoing Appointment')
      return
    }

    const payload = {
      roomId: roomId,
      type: "TEXT",
      sender: user.id,
      content: chatMsg,
      // proposal_id: proposaldata?._id,
      // campaign_id: proposaldata?.campaign_id,
      // chat_file:fileName
    };
    ConnectSocket.emit(`send-message`, payload)
    setChatMsg("");
  };

  const userMessage = (roomuid) => {
    axios
      .get(
        `${SocketURL}chat/user/message/all?room_id=${roomuid}`
      )
      .then((res) => {
        if (res?.data.success) {
          setChat(res.data.data.data);
          // chatRef.current = res.data.data.data;
        }
      });
  };

  const getData = () => {
    const payload = {
      chat_by: user?.id,
      chat_with: paramValue,
    };
    // loader(true);

    axios.post(`${SocketURL}chat/user/join-group`, payload).then((res) => {
      if (res?.data?.success) {
        const data = res.data;
        setRoomId(res.data.data.room_id);
        userMessage(data.data.room_id);
        joinRoom(data.data.room_id);
        ConnectSocket.emit("read-all-messages", { room_id: data.data.room_id })
        localStorage.setItem("activeRoom", data?.data?.room_id)
        // loader(false);
      }
    });
  };

  useEffect(() => {
    getChatList();
  }, [chatMsg]);

  useEffect(() => {
    getData()
  }, [paramValue]);

  // const handleUserId = (id) => {
  //   localStorage.setItem("chatId", id)
  // }


  return <>
    <section className='bg_leaf position-relative'>
      <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
      <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
      <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

        <Header />
      </div>
    </section>


    {/* chats section start */}

    <section className='p80'>
      <div className='container'>
        <div className='chatsingle'>
          <i onClick={() => {
            history.push('/myappointment')
          }} class="fa fa-arrow-left" title='Go Back' aria-hidden="true"></i>
          <div className=" row">
            <div className=" col-md-10 mx-auto">

              <div className="chat">
                <div className=' chat-header clearfix'>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className='d-flex align-items-center' >
                      <img src={chatWith?.image ? `${environment?.api}images/users/${chatWith?.image}` : "https://www.pngkey.com/png/detail/950-9501315_katie-notopoulos-katienotopoulos-i-write-about-tech-user.png"} alt="avatar" />
                      <div className="chat-about">
                        <div className="chat-with">{chatWith?.fullName}</div>
                        <div className="chat-num-messages">{activeUser?.[0]?.isOnline ? "online" : "offline"}</div>
                      </div>

                    </div>

                    <div className='timer_chat'>
                      <span><i className='fa fa-clock mr-2'></i>{timeRemaining}</span>
                    </div>
                  </div>


                </div>


                <div className="chat-history">
                  <ul>
                    {chat.map((itm) => {
                      const fileExtension = itm?.content?.split(".").pop().toLowerCase()
                      let isTrue = user?.id == itm?.sender;
                      let isImage = imageExtensions.includes(fileExtension);
                      const date = new Date(itm.updatedAt);
                      const hours = ('0' + date.getHours()).slice(-2);
                      const minutes = ('0' + date.getMinutes()).slice(-2);
                      const timeWithoutSeconds = hours + ':' + minutes;
                      return <li className="clearfix">
                        <div className={isTrue ? "message-data align-right" : "message-data"} ref={bootemel}>
                          <span className="message-data-time" >{getDay(itm.updatedAt)} {timeWithoutSeconds}</span> &nbsp; &nbsp;
                          <span className="message-data-name" >{itm?.sender_name}</span> <i className="fa fa-circle me"></i>

                        </div>
                        <div className={isTrue ? "message other-message float-right" : "message my-message"}>
                          {isImage ? (
                            <ImageView width={"50px"} height={"50px"} src={`${environment?.api}${itm?.content}`} />
                            // <img
                            //   src={`${environment?.api}${itm?.content}`}
                            //   className="download_img_chat"
                            //   alt=""
                            // />
                          ) : isURL(itm.content) ? (
                            <div className="pdf_btn">
                              <div className="pdf_inner_layout ">
                                <span className="pdficon">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="65" height="65" viewBox="0 0 65 65" fill="none">
                                    <path d="M43.3332 0H21.6665C18.6873 0 16.2498 2.4375 16.2498 5.41667V48.75C16.2498 51.7292 18.6873 54.1667 21.6665 54.1667H54.1665C57.1457 54.1667 59.5832 51.7292 59.5832 48.75V16.25L43.3332 0ZM54.1665 48.75H21.6665V5.41667H40.6248V18.9583H54.1665V48.75ZM10.8332 10.8333V59.5833H54.1665V65H10.8332C7.854 65 5.4165 62.5625 5.4165 59.5833V10.8333H10.8332ZM27.0832 27.0833V32.5H48.7498V27.0833H27.0832ZM27.0832 37.9167V43.3333H40.6248V37.9167H27.0832Z" fill="black" />
                                  </svg>
                                </span>
                              </div>

                              <div className="hoverdonload ">
                                {isURL(itm.content) ? (
                                  <a
                                    href={`${environment?.api}${itm.content}`}
                                    download="document.pdf"
                                  >
                                    {" "}
                                    <i className="fa fa-download"></i>{" "}

                                  </a>
                                ) : null}
                              </div>

                            </div>
                          ) : (
                            itm.content
                          )}
                        </div>
                      </li>
                    })}

                  </ul>
                  {isImage && <div className="image_loader">
                    <div class="loader"></div>
                  </div>}
                </div>

                <div className="chat-message clearfix">
                  <div className='d-flex align-items-center w-100'>
                    <div className='position-relative w-100'>
                      <textarea
                        type="text"
                        className='w-100 textrea_list mr-3'
                        value={chatMsg}
                        onChange={(e) => setChatMsg(e.target.value)}
                        onKeyDown={(e) => {
                          e.key === 'Enter' && handelSubmit(e)
                        }}
                        disabled={chat?.length > 0 && chat[0]?.rooms_details?.blocked_admin ? true : false} >
                      </textarea>

                      <label className="pointer_upload">
                        <input
                          id="bannerImage"
                          type="file"
                          className="d-none"
                          accept="file/*"
                          onChange={(e) => {
                            uploadImage(e);
                          }}
                          disabled={chat?.length > 0 && chat[0]?.rooms_details?.blocked_admin ? true : false}
                        />
                        <i class="fa fa-paperclip" aria-hidden="true"></i>

                      </label>
                    </div>

                    <div className='sendmsgebtn'>
                      {isButtonEnabled ? <button className='btn btn-transparent p-0'
                        onClick={(e) => handelSubmit(e)}
                        disabled={chat?.length > 0 && chat[0]?.rooms_details?.blocked_admin ? true : false}
                      ><i className='fa fa-paper-plane icon_blue' ></i></button>
                        :
                        <button className='btn btn-transparent p-0'
                          onClick={() => toast.error('No Ongoing Appointment')}
                        ><i className='fa fa-paper-plane icon_blue' ></i></button>
                      }
                    </div>


                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>
      </div>
    </section>
    <ToastContainer position="top-right" />
  </>;
};

export default chat;
