import React, { useEffect, useState } from "react";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer/Footer";
import "./style.css";
import ReactDatePicker from "react-datepicker";
import "rc-time-picker/assets/index.css";
import dayjs from "dayjs";
import { ToastContainer, toast } from "react-toastify";
import ApiClient from "../../methods/api/apiClient";
import { TimePicker } from "antd";
import { ToastsStore } from "react-toasts";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import loader from "../../methods/loader";
import PhoneInput from "react-phone-input-2";
import { all } from "q";
import moment from "moment";
import methodModel from "../../methods/methods";
import { ConnectSocket } from "../Chat/socket";
import dateFormat, { masks } from "dateformat";

function Appointment() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [dates, setDates] = useState([]);
  const history = useHistory()
  const [userData,setUser] = useState({})
  const user = useSelector((state) => state.user)
  const [selectedDate, setSelectedDate] = useState(null);
  const [time, settime] = useState('Select Time Slot')
  const [AllDates, setAlldate] = useState([])
  const [slot, setslot] = useState([])
  const [therapistData, setTherapistData] = useState([])
  const [form, setform] = useState({therapist_id:therapistData?.id , name: user?.fullName })
  const [data, setdata] = useState([])
  const [Plan, setPlan] = useState([])
  const [appointments, SetAppointTime] = useState([])
  const [TotalSlot, SetTotalSlot] = useState([])
  const availableDates = [
    "2023-12-07",
    "2023-12-08",
    "2023-12-09",
    "2023-12-10",
    "2023-12-11",
    "2023-12-12",
  ];

  const [isButtonEnabled, setIsButtonDisabled] = useState(false);

  const today = new Date();
  let FilteredSlots = [];

  const checkAppointments = () => {
    appointments.forEach((appointment) => {
      const appointmentDate = new Date(appointment.date);
      const [startHour, endHour] = appointment.time.split("-");

      appointmentDate.setHours(parseInt(startHour), 0, 0, 0);
      const endTime = new Date(appointmentDate);
      endTime.setHours(parseInt(endHour));

      if (
        appointmentDate.getDate() === today.getDate() &&
        appointmentDate.getMonth() === today.getMonth() &&
        appointmentDate.getFullYear() === today.getFullYear()
      ) {
        if (today >= appointmentDate && today <= endTime) {
          setIsButtonDisabled(true)
        } else {
          setIsButtonDisabled(false)
        }
      }
    });
  };

  useEffect(() => {
    checkAppointments();
  }, [appointments]);

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 600);
  }, []);

  useEffect(() => {
    ConnectSocket.connect();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setform({ ...form, name: user?.fullName, email: user?.email,therapist_id:therapistData?.id,subscription_id:user?.subscription_details?.id})
    }, 1000);
  }, []);

  const getData = (p = {}) => {
    loader(true);
    // let filter = { ...filters, ...p }
    ApiClient.get("user/appointmentslist", {
      sortBy: "date asc",
      status: "accept",
    }).then((res) => {
      if (res.success) {
        // setdata(res?.data)
        // settTotal(res?.total)
        // console.log(res, "============+++++++")
        loader(false);
        SetAppointTime(res?.data);
        // console.log(dateFormat(Date.now(), "dd-mm-yyyy,HH"))
        // if (dateFormat(finalTime, "dd-mm-yyyy,HH") === dateFormat("14-12-2023,18", "dd-mm-yyyy,HH")) {
        //     console.log("=============>>>>>>>>>>>>>>>>")
        // }
      }
    });
  };

  const DataDates = data?.map((itm) => itm?.date?.replace(/\//g, '-')?.split("-").reverse().join("-"))

  useEffect(() => {
    if (!user.is_plan) {
      history.push("/next");
    } else {
      history.push("/appointment");
    }
  }, [user]);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  });

  useEffect(() => {
    getData();
  }, []);

  const GetTherapistData = () => {
    ApiClient.get("therapist").then((res) => {
      if (res) {
        setTherapistData(res?.data);
      }
    });
  };

  useEffect(() => {
    GetTherapistData();
  }, []);

  const GetAvailablity = () => {
    ApiClient.get("admin/availabilitylist").then((res) => {
      res?.data?.map((itm) => AllDates.push(itm));
      setdata(res?.data);
    });
  };

  useEffect(() => {
    setform({ ...form, date: moment(selectedDate).format("yyyy-MM-DD") });
    if (
      moment(selectedDate).format("yyyy-MM-DD") ==
      dateFormat(Date.now(), "yyyy-mm-dd")
    ) {
      setslot(TotalSlot);
      FilteredSlots = TotalSlot;
    }
    // console.log(moment(selectedDate).format('yyyy-MM-DD'))
    // if (moment(selectedDate).format('yyyy-MM-DD') == dateFormat(Date.now(), 'yyyy-mm-dd')) {
    //   let date = dateFormat(Date.now(), 'DD-MM-YYYY,HH')
    //   const hour = date.split(',')[1]
    //   console.log(parseInt(hour), "============================")
    //   const fltr = slot?.filter((itm) => parseInt(itm?.split('-')[0]) > parseInt(hour))
    //   const maping = slot?.map((itm) => {
    //     console.log(itm)
    //   })
    //   // setslot(fltr)
    //   console.log(fltr)
    // }
  }, [selectedDate]);

  useEffect(() => { }, [form]);

      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinutes = currentTime.getMinutes();
      const currentPeriod = currentHour >= 12 ? "PM" : "AM";

      const filteredSlots = slot?.filter(slot => {
        const [slotRange, slotPeriod] = slot.split(' ');
        const [slotStartHour, slotStartMinute] = slotRange.split('-')[0].split(':').map(Number);
        let slotStartHour24 = slotStartHour;

        if (slotPeriod === "PM" && slotStartHour !== 12) {
          slotStartHour24 += 12;
        }

        if (
          (slotPeriod === currentPeriod && slotStartHour24 > currentHour) ||
          (slotPeriod === "PM" && currentPeriod === "AM") || 
          (slotPeriod === currentPeriod && slotStartHour === currentHour && slotStartMinute > currentMinutes) 
        ) {
          return true; 
        }
        return false;
      });

  useEffect(() => {
    if (
      moment(selectedDate).format("yyyy-MM-DD") ==
      dateFormat(Date.now(), "yyyy-mm-dd")
    ) {
      setslot(filteredSlots)
    }
  }, [filteredSlots])

  const dateToString = date => date.toISOString().split('T')[0];

  const disabledDates = availableDates.filter(
    (date) => !availableDates.includes(dateToString(new Date(date)))
  );

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const SelecetDate = () => {
    let fltr = data?.filter((itm) => itm.date == form?.date?.split("-").reverse().join("-")
    )
    SetTotalSlot(fltr[0]?.time)
    setslot(fltr[0]?.time)
  }

  useEffect(() => {
      SelecetDate()
  }, [form])

  const HandleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    if (form?.phone == "" || form?.time == "" || form?.feature == "") {
      toast.warn("Please fill all the fields");
      loader(false);
      return;
    }
    ApiClient.post('appointment', {...form,therapist_id:therapistData?.id,subscription_id:userData?.subscription_details?.id}).then((res) => {
      if (res?.success) {
        toast.success(res?.message)
        setSelectedDate(null)
        window.scrollTo(0, 0)
        history.push('/myappointment')
        setform({ name: '', description: '', email: '', department: '', phone: '', date: '', time: '' , feature: 'Select feature' })
        loader(false)
      }
    });
  };
  const GetPlan = () => {
    ApiClient.get(`user/profile`).then((res) => {
      if (res.success) {
        setUser(res?.data)
        setPlan(res?.data?.plan_details?.features);
      }
    });
  };
  useEffect(() => {
    GetAvailablity();
    GetPlan();
    setform({ ...form, name: user.name, email: user.email });
    window.scrollTo(0, 0);
  }, []);

  const GetCode = (e) => {
    window.open(
      `https://zoom.us/oauth/authorize?response_type=code&client_id=VA3DVHVCSie6QIooYnQA&redirect_uri=https://therapy.jcsoftwaresolution.in/recent`
    );
  };

  return (
    <>
      <section className="bg_leaf position-relative">
        <img
          src="/assets/img/therpy/left1.png"
          className="img-fluid leftside"
        />
        <img
          src="/assets/img/therpy/right1.png"
          className="img-fluid rightside"
        />
        <div className={`header-dark-page ${isScrolled ? "scrollsticky" : ""}`}>
          <Header />
        </div>

        <div className="container p80">
          <div className="bggrey-apmt">
            <div className="row">
              <div className="col-sm-12 col-12 co-md-4 col-lg-4 ">
                <div className="imgprofile">
                  <img
                  src={methodModel.noImg(therapistData?.image,'images/users')}
                    // src="/assets/img/therpy/profile.jpg"
                    className="img-fluid "
                  />
                </div>
              </div>
              <div className="col-sm-12 col-12 co-md-8 col-lg-8 ">
                <div className="profile_data">
                  <div className="title_head">
                    <h6>
                      {methodModel.capitalizeFirstLetter(
                        therapistData?.fullName
                      )}
                    </h6>
                    <p className="bluecolor">
                      {therapistData?.experience} Experienced
                    </p>
                    <p>{therapistData?.about}</p>
                  </div>
                  <div className="profile_email">
                    <div className="phone_img">
                      <img
                        src="/assets/img/therpy/phone.png"
                        className="img-fluid "
                      />
                      <div className="emailname">
                        <span>CALL NOW:</span>
                        <h5>{therapistData?.dialCode}{therapistData?.mobileNo}</h5>
                      </div>
                    </div>
                    <div className="phone_img">
                      <img
                        src="/assets/img/therpy/email.png"
                        className="img-fluid "
                      />
                      <div className="emailname">
                        <span>EMAIL NOW:</span>
                        <h5>{therapistData?.email}</h5>
                      </div>
                    </div>
                  </div>
                  <div className='buttonvideo'>
{/* 
                    {Plan?.length > 0 && Plan?.some(item => item.name === "video call" && item.isChecked) && isButtonEnabled ? <button
                      onClick={() => {
                        GetCode()

                        localStorage.setItem("meetingwith", therapistData?.id)
                        // window.scrollTo(0, 740)
                      }} className='btn btn-primary'>
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="19" height="12" viewBox="0 0 19 12" fill="none">
                        <path d="M14.3267 4.5V1C14.3267 0.734784 14.2213 0.48043 14.0338 0.292893C13.8462 0.105357 13.5919 0 13.3267 0H1.32666C1.06144 0 0.80709 0.105357 0.619553 0.292893C0.432017 0.48043 0.32666 0.734784 0.32666 1V11C0.32666 11.2652 0.432017 11.5196 0.619553 11.7071C0.80709 11.8946 1.06144 12 1.32666 12H13.3267C13.5919 12 13.8462 11.8946 14.0338 11.7071C14.2213 11.5196 14.3267 11.2652 14.3267 11V7.5L18.3267 11.5V0.5L14.3267 4.5Z" fill="white" />
                      </svg></span>
                      Video Call
                    </button> :
                      <button
                        onClick={() => {
                          if (
                            Plan &
                            Plan?.some(
                              (item) =>
                                item.name == "video call" && !item.isChecked
                            )
                          ) {
                            toast.error(
                              "This plan cannot include this feature"
                            );
                          }
                          if (
                            Plan &
                            Plan?.some(
                              (item) =>
                                item.name == "video call" && item.isChecked
                            ) &&
                            !isButtonEnabled
                          ) {
                            toast.error("Your Appointment is not Right Now.");
                          }
                        }}
                        className="btn btn-primary"
                      >
                        <span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="19"
                            height="12"
                            viewBox="0 0 19 12"
                            fill="none"
                          >
                            <path
                              d="M14.3267 4.5V1C14.3267 0.734784 14.2213 0.48043 14.0338 0.292893C13.8462 0.105357 13.5919 0 13.3267 0H1.32666C1.06144 0 0.80709 0.105357 0.619553 0.292893C0.432017 0.48043 0.32666 0.734784 0.32666 1V11C0.32666 11.2652 0.432017 11.5196 0.619553 11.7071C0.80709 11.8946 1.06144 12 1.32666 12H13.3267C13.5919 12 13.8462 11.8946 14.0338 11.7071C14.2213 11.5196 14.3267 11.2652 14.3267 11V7.5L18.3267 11.5V0.5L14.3267 4.5Z"
                              fill="white"
                            />
                          </svg>
                        </span>
                        Video Call
                      </button>} */}

                    {/* {Plan?.length > 0 && Plan.some(item => item.name === "chat" && item.isChecked) ? <button onClick={() => {
                      history.push(`/chat?id=${therapistData?.id}`)
                    }} className='btn btn-primary bgchat'>
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                        <path d="M10 0C15.5 0 20 3.58 20 8C20 12.42 15.5 16 10 16C8.76 16 7.57 15.82 6.47 15.5C3.55 18 0 18 0 18C2.33 15.67 2.7 14.1 2.75 13.5C1.05 12.07 0 10.13 0 8C0 3.58 4.5 0 10 0Z" fill="white" />
                      </svg></span>
                      Chat
                    </button> :
                      <button onClick={() => { toast.error("This plan cannot include this feature") }} className='btn btn-primary bgchat'>
                        <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="18" viewBox="0 0 20 18" fill="none">
                          <path d="M10 0C15.5 0 20 3.58 20 8C20 12.42 15.5 16 10 16C8.76 16 7.57 15.82 6.47 15.5C3.55 18 0 18 0 18C2.33 15.67 2.7 14.1 2.75 13.5C1.05 12.07 0 10.13 0 8C0 3.58 4.5 0 10 0Z" fill="white" />
                        </svg></span>
                        Chat
                      </button>} */}

                    <button onClick={() => {
                      window.scrollTo(0, 680)
                    }} className='btn btn-primary'>
                      <span><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M10 17.2C7.5 17.2 5.29 15.92 4 14C4.03 12 8 10.9 10 10.9C12 10.9 15.97 12 16 14C14.71 15.92 12.5 17.2 10 17.2ZM10 3C10.7956 3 11.5587 3.31607 12.1213 3.87868C12.6839 4.44129 13 5.20435 13 6C13 6.79565 12.6839 7.55871 12.1213 8.12132C11.5587 8.68393 10.7956 9 10 9C9.20435 9 8.44129 8.68393 7.87868 8.12132C7.31607 7.55871 7 6.79565 7 6C7 5.20435 7.31607 4.44129 7.87868 3.87868C8.44129 3.31607 9.20435 3 10 3ZM10 0C8.68678 0 7.38642 0.258658 6.17317 0.761205C4.95991 1.26375 3.85752 2.00035 2.92893 2.92893C1.05357 4.8043 0 7.34784 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 4.47 15.5 0 10 0Z" fill="white" />
                      </svg></span>
                      Schedule Meet
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='p80 pt-0'>

        <div className='container'>
          <div className='row'>
            <div className='col-12 col-sm-12 col-md-7'>
              <div className='appointment_head'>
                <h5 className='h5class'>Book an appoinment</h5>
                <p>Take charge of your life and start by scheduling an appointment with one of qualified therapists.</p>


                <div className='fomr_fileds'>
                  <form className='w-100' onSubmit={HandleSubmit}>
                    <div className='labelnames'>
                      <input required maxLength={20} value={form?.name} onChange={(e) => setform({ ...form, name: e.target.value })} type="text" className='form-control' placeholder='Name' />
                    </div>

                    <div className="labelnames">
                      <input
                        required
                        value={form?.email}
                        type="email"
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>

                    <div className="labelnames">
                      <div className="row">
                        <div className="col-md-12">
                          <PhoneInput
                            country={"us"}
                            required
                            enableSearch={true}
                            placeholder="Enter phone number"
                            value={form?.phone}
                            countryCodeEditable={true}
                            onChange={(e) => {
                              setform({ ...form, phone: e });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="labelnames"></div>

                    <div className="labelnames">
                      <ReactDatePicker
                        required
                        className="form-control"
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        minDate={new Date()}
                        includeDates={DataDates?.map((date) => new Date(date))}
                        placeholderText="Select an available date"
                      />
                    </div>
                    <div class="labelnames">
                      <select
                        className=" form-control"
                        value={form?.time}
                        onChange={(e) => {
                          setform({ ...form, time: e.target.value });
                          settime(e.target.value);
                        }}
                      >
                        <option className="" value={""}>
                          Select Time Slot
                        </option>
                        {slot?.map((itm) => {
                          return (
                            <option className="" value={itm}>
                              {itm}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {/* <div class="labelnames" >
                      <select

                        className="form-control"
                        value={form?.feature}
                        onChange={(e) => {
                          setform({ ...form, feature: e.target.value })
                          settime(e.target.value)

                        }}
                      >
                        <option className='' value={''}>Select Feature</option>
                        {Plan &&
                          Plan?.map((item) => {
                            return (
                              <>
                                {
                                  item?.isChecked ? <option className='' value={item?.name}>{item?.name}</option> : null
                                }
                              </>

                            )
                          })
                        }

                      </select>

                    </div> */}

                    <div className="labelname">
                      <textarea
                        required
                        value={form?.description}
                        className="form-control"
                        onChange={(e) =>
                          setform({ ...form, description: e.target.value })
                        }
                        placeholder="Description"
                        cols="10"
                        rows="5"
                      ></textarea>
                    </div>

                    <div className="labelname mt-4">
                      <button className="btn btn-primary w-100">
                        Book Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-12 col-md-5">
              <div className="img_hours">
                <img
                  src="/assets/img/therpy/hours.png"
                  className="img-fluid  "
                />
              </div>
              <div className="bgblue_more">
                <div className="text-center questo_text text-white">
                  <h6>Didn’t Find a Question?</h6>
                  <p>
                   Please use the button below and let us know how we can help you. 
                  </p>
                  <button onClick={()=>{
                    history.push('/contact')
                  }} className="btn btn-primary btnblack">
                    More Questions
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      {/* <section className='p80'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='proicing_table'>
                <div className='row'>



                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <ToastContainer position="top-right" />
      <Footer />

      <button
        type="button"
        class="btn btn-primary d-none"
        id="OpenModel"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Launch demo modal
      </button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Book an appoinment
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body w-100">
              <div className="appointment_head w-100">
                <h5 className="h5class">Book an appoinment</h5>
                <p>
                  Lorem Ipsum is simply dummy text of printing and typesetting
                  industry standard dummy text the with the release.
                </p>

                <div className="fomr_fileds">
                  <form className="w-100" onSubmit={HandleSubmit}>
                    <div className="labelnames">
                      <input
                        required
                        value={form?.name}
                        onChange={(e) =>
                          setform({ ...form, name: e.target.value })
                        }
                        type="text"
                        className="form-control"
                        placeholder="Name"
                      />
                    </div>

                    <div className="labelnames">
                      <input
                        required
                        value={form?.email}
                        type="email"
                        onChange={(e) =>
                          setform({ ...form, email: e.target.value })
                        }
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>

                    <div className="labelnames">
                      <div className="row">
                        <div className="col-md-12">
                          <PhoneInput
                            country={"us"}
                            required
                            enableSearch={true}
                            placeholder="Enter phone number"
                            value={form?.phone}
                            countryCodeEditable={true}
                            onChange={(e) => {
                              setform({ ...form, phone: e });
                            }}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="labelnames"></div>

                    <div className="labelnames">
                      <ReactDatePicker
                        required
                        className="form-control"
                        selected={selectedDate}
                        onChange={(date) => setSelectedDate(date)}
                        includeDates={DataDates?.map((date) => new Date(date))}
                        placeholderText="Select an available date"
                      />
                      {/* <TimePicker onChange={(e) =>{

                      setform({
                        ...form, time: e.$H
                          + ":" + e.$m
                      })
                      console.log(e)} } className='form-control mt-2' use12Hours format={format} /> */}
                    </div>
                    <div class="btn-group timelabel" style={{ width: "100%" }}>
                      <button
                        type="button"
                        className="btn btn-primary text-left parimarybtn dropdown-toggle  mb-2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {time}{" "}
                      </button>
                      <div className="dropdown-menu">
                        {slot?.map((itm) => {
                          return (
                            <a
                              onClick={() => {
                                setform({ ...form, time: itm });
                                settime(itm);
                              }}
                              className="dropdown-item"
                            >
                              {itm}{" "}
                            </a>
                          );
                        })}
                      </div>
                    </div>
                    <div class="btn-group timelabel" style={{ width: "100%" }}>
                      <button
                        type="button"
                        className="btn btn-primary text-left parimarybtn dropdown-toggle  mb-2"
                        data-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {form?.feature}{" "}
                      </button>
                      <div className="dropdown-menu">
                        {Plan &&
                          Plan?.map((item) => {
                            return (
                              <>
                                {item?.isChecked ? (
                                  <a
                                    onClick={() => {
                                      setform({ ...form, feature: item.name });
                                    }}
                                    className="dropdown-item"
                                  >
                                    {item.name}
                                  </a>
                                ) : null}
                              </>

                            )
                          })
                        }

                      </div>
                    </div>
                    <div className="labelname">
                      <textarea
                        required
                        value={form?.description}
                        className="form-control"
                        onChange={(e) =>
                          setform({ ...form, description: e.target.value })
                        }
                        placeholder="Description"
                        cols="10"
                        rows="5"
                      ></textarea>
                    </div>

                    <div className="labelname mt-4">
                      <button className="btn btn-primary w-100">
                        Book Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                id="CloseModel"
                data-bs-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" class="btn btn-primary">Save changes</button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Appointment;
