
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom';
import DateRangePicker from '../../components/common/DateRangePicker';
import Layout from '../../components/global/layout'
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import methodModel from '../../methods/methods';
import datepipeModel from '../../models/datepipemodel';
import "./style.scss";
import { useSelector } from 'react-redux';
import SelectDropdown from '../../components/common/SelectDropdown';

export default function Dashboard() {
  const [orders, setOrder] = useState([])
  const [data, setdata] = useState()
  const user = useSelector((state) => state.user)
  const [filters, setFilter] = useState({ page: 1, count: 50 })
  const [chartFilter, setChartFilter] = useState({ type: 'daily' })
  const [loading, setLoader] = useState()
  const history = useHistory()
  const btnref = useRef(null);
  const [dlet, setdlet] = useState(false)




  const ShowModel = () => {
    const modelbtn = document.getElementById("model").click()

  }
  useEffect(() => {

    if (user.financialYear == '') {
      ShowModel()
    }

  }, [user])

  useEffect(() => {
    // getDashboard()
  }, [])

  const getDashboard = () => {
    loader(true)
    ApiClient.get('api/user/dashboard', { ...chartFilter }).then(res => {
      if (res.success) {
        setdata(res)
        if (res.totalOrders) {
          let data = res.totalOrders.map(itm => {
            return {
              x: datepipeModel.date(new Date((itm.year || 1), (itm.month || 1), (itm.day || 1))),
              y: itm.totalOrders
            }
          })
          setOrder(data)
        }
      }
      loader(false)
    })
  }

  const vieworder = (id) => {
    history.push("/dashboard/orderdetail/" + id)
  }


  const route = (r) => {
    history.push(r)
  }

  return (
    <div>
      <Layout>

        {/* Pop Model For Empty Financial Year */}
        <button
          type="button"
          class="btn btn-primary"
          ref={btnref}
          style={{ display: 'none' }}
          id="model"
          data-bs-toggle="modal"
          data-bs-target="#exampleModal"
        >
          Open modal
        </button>
        <div
          class="modal fade "
          style={{ marginTop: "10vh" }}
          id="exampleModal"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Financial Year is not set - Click below Button to Visit Company Setting
                </h5>
              </div>

              <div class="modal-footer">

                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-dismiss="modal"
                  onClick={() => {
                    history.push('/company')
                  }}>
                  Change Now
                </button>
              </div>
            </div>
          </div>
        </div>







        <div className='container'>
          <div className='dashboard_row'>
            <div>
              <h1>Dashboard</h1>
              <p>Here  you can see all about your product statistics</p>
            </div>
            <div className='btn-flex'>
              <button className='btn btn-primary parimarybtn2 '><i class="fa fa-clock icon_ibtn2 mr-2" aria-hidden="true"></i>View Insights</button>
              <button className='btn btn-primary btn_row  ml-3'><i class="fa fa-window-maximize icon_ibtn  mr-2" aria-hidden="true"></i> View Recommendation</button>
            </div>
          </div>
          <div className='new_div'>
            <div className='filterFlex'>
              <div class="btn-group">
                <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                  <span className='drow_text'>Data by:</span>  By Travel Date By
                </button>
                <div className="dropdown-menu">
                  <a className="dropdown-item" href="#">2023</a>
                  <a className="dropdown-item" href="#">2024</a>
                  <a className="dropdown-item" href="#">2025</a>
                  <div className="dropdown-divider"></div>
                  <a className="dropdown-item" href="#">Separated link</a>
                </div>
              </div>


              {/* <button className='btn btn-primary parimarybtn btn_btn ml-2 mb-2'>Exclude forward bookings<div class="form-check form-switch switch_btn">
              <input className="form-check-input switch_check" type="checkbox" role="switch" id="flexSwitchCheckDefault" />
            </div> </button> */}
              {/* <div class="btn-group btn-group-toggle main-btn ml-2 mb-2">
              <label className='btn btn-light btn_color'>
                Financial year
              </label>
              <label className='btn btn-light labelbtn'>
                Calendar year
              </label>
            </div> */}
              <div className='btn_button ml-3'>
                <h1 className='tab_btn tab_btn2'> Financial year</h1>
                <h1 className='tab_btn'> Calendar year</h1>
              </div>

              <DateRangePicker

                value={{
                  startDate: '',
                  endDate: ''
                }}
                onChange={e => { }}
              />
              <button className='btn btn-primary download_icon mb-2'><i class="fa fa-download mr-2" aria-hidden="true"></i>Export pdf </button>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">
                <p>Total Sales</p>
                <div className="">
                  <h2>$1,000,000</h2>
                  <p className='mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'>Compare to previous month</span></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">
                <p>Total orders</p>
                <div className="">
                  <h2>12,756</h2>
                  <p className='mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'>Compare to previous month</span></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">
                <p>Total Pax </p>
                <div className="">
                  <h2>22,756</h2>
                  <p className='mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'>Compare to previous month</span></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">
                <p >Additional Revenue </p>
                <div className="">
                  <h2>$43,998</h2>
                  <p className='mb-0'><span className='red_icon'><span class="material-icons mr-1">trending_down</span>-9.98%</span><span className='text_color'>Compare to previous month</span></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">
                <p>Average Order Value</p>
                <div className="">
                  <h2>$42.90</h2>
                  <p className='mb-0'><span className='red_icon'><span class="material-icons mr-1">trending_down</span>-9.98%</span><span className='text_color'>Compare to previous month</span></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">
                <p>Total orders</p>
                <div className="">
                  <h2>$63,435</h2>
                  <p className='mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'>Compare to previous month</span></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">
                <p>Lead Times</p>
                <div className="">
                  <h2>120</h2>
                  <p className='mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'>Compare to previous month</span></p>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-md-4 col-lg-3 mb-3">
              <div className="Sales_row">

                <div className="className_box">
                  <div className='box_span'>
                    <p>Direct</p>
                    <h2>80%</h2>
                    <p className='mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'></span></p>
                  </div>
                  <div className='box_span'>
                    <p>Reseller</p>
                    <h2>20%</h2>
                    <p className='mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'></span></p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-md-12'>
              <div className='layout mb-3'>
                <div className='header_list mb-3'>
                  <h1 className='layout_heading mb-2'>Sales Revenue</h1>
                  <div className="btn_flex">
                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle " data-toggle="dropdown" aria-expanded="false">
                        Chart options
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>

                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-3" data-toggle="dropdown" aria-expanded="false">
                        This Month
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">1</a>
                        <a className="dropdown-item" href="#">2</a>
                        <a className="dropdown-item" href="#">3</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='layout mb-3'>
                <div className='header_list'>
                  <h1 className='layout_heading mb-2'>Sales by Country of Origin (Top 4)</h1>
                  <div className="btn_flex">
                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                        This Month
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>
                  </div>

                </div>
                <div className='table_layout table-responsive mb-3'>
                  <table className='table table-striped'>
                    <thead class="table_head">
                      <tr className='data_row'>
                        <th className='table_data'>Name</th>
                        <th className='table_data'>Booking</th>
                        <th className='table_data'>AVG. Order Value</th>
                        <th className='table_data'>Lead Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3" />Unites states of america</td>
                        <td>34,097</td>
                        <td>$67.90</td>
                        <td>4 days</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3" />Malaysia</td>
                        <td>34,097</td>
                        <td>$67.90</td>
                        <td>4 days</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3" />Colombia</td>
                        <td>34,097</td>
                        <td>$67.90</td>
                        <td>4 days</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3" />Brazil</td>
                        <td>34,097</td>
                        <td>$67.90</td>
                        <td>4 days</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3" />Canada</td>
                        <td>34,097</td>
                        <td>$67.90</td>
                        <td>4 days</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3" />Canada</td>
                        <td>34,097</td>
                        <td>$67.90</td>
                        <td>4 days</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='footer_chart pt-4'>
                  <a className='border_layout border-0' href="#">View all countries data  <span class="material-icons">east</span></a>
                </div>

              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='layout mb-3'>
                <div className='header_list'>
                  <h1 className='layout_heading mb-2'>Financial data</h1>
                  <div className="btn_flex">
                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                        This Month
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='data_layout mb-3'>
                  <div className='form-row'>
                    <div className='col-sm-6 col-md-6 mb-3'>
                      <div className='data'>

                        <p>Invoices raises</p>
                        <h1>$65,00</h1>
                        <p className='mt-1 mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'></span></p>
                      </div>
                    </div>

                    <div className='col-sm-6 col-md-6 mb-3'>
                      <div className='data'>

                        <p>Invoices paid</p>
                        <h1>$65,00</h1>
                        <p className='mt-1 mb-0'><span className='green_icon'><span class="material-icons mr-1">trending_up</span>+6,28%</span><span className='text_color'></span></p>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-6 mb-3'>
                      <div className='data'>

                        <p>Invoices due</p>
                        <h1>$65,00</h1>
                        <p className='mt-1 mb-0'><span className='red_icon'><span class="material-icons mr-1">trending_down</span>-9.98%</span><span className='text_color'></span></p>
                      </div>
                    </div>
                    <div className='col-sm-6 col-md-6 mb-3'>
                      <div className='data'>

                        <p>Forward bookings</p>
                        <h1>$65,00</h1>
                        <p className='mt-1 mb-0'><span className='red_icon'><span class="material-icons mr-1">trending_down</span>-9.98%</span><span className='text_color'></span></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='footer_chart pt-4'>
                  <a href="#">View all Financial Data<span class="material-icons">east</span></a>
                </div>

              </div>
            </div>
            <div className='col-md-12'>
              <div className='layout mb-3'>
                <div className='header_list'>
                  <h1 className='layout_heading mb-2'>Top products</h1>
                  <div className="btn_flex">
                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                        This Month
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='table_layout table-responsive mb-3'>
                  <table className='table table-striped'>
                    <thead class="table_head">
                      <tr className='data_row'>
                        <th className='table_data name_th'>Name</th>
                        <th className='table_data'>Sales</th>
                        <th className='table_data'>Orders</th>
                        <th className='table_data'>Total Pax</th>
                        <th className='table_data'>Addi. <br />Revenue</th>
                        <th className='table_data'>AVG. Order<br />Value</th>
                        <th className='table_data'>Commissions<br /> Paid</th>
                        <th className='table_data'>Lead Time</th>
                        <th className='table_data'>Reseller</th>
                        <th className='table_data'>Direct</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Milford Sound Cruise</td>
                        <td>$900,222</td>
                        <td>657</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>65 days</td>
                        <td>80%</td>
                        <td>20%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Milford Sound Cruise from Queenstown</td>
                        <td>$900,222</td>
                        <td>76</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>13 days</td>
                        <td>60%</td>
                        <td>40%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Milford Sound Fly</td>
                        <td>$900,222</td>
                        <td>34</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>12 days</td>
                        <td>90%</td>
                        <td>10%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Cruise</td>
                        <td>$900,222</td>
                        <td>76</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>23 days</td>
                        <td>75%</td>
                        <td>25%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Fly</td>
                        <td>$900,222</td>
                        <td>35</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>10 days</td>
                        <td>55%</td>
                        <td>45%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='footer_chart pt-4'>
                  <a className='border_layout border-0' href="#">View all products data    <span class="material-icons">east</span></a>
                </div>

              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='layout mb-3'>
                <div className='header_list'>
                  <h1 className='layout_heading mb-2'>Sales by resellers</h1>
                  <div className="btn_flex">
                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                        Chart options
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>
                    {/* <div class="btn-group">
                    <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                      This Month
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">Separated link</a>
                    </div>
                  </div> */}
                  </div>
                </div>
                <div className='orders_layout mb-3'>
                  <div className='value_orders'>
                    <p>Total Sales</p>
                    <h1>$670,890</h1>
                  </div>
                  <div className='value_orders'>
                    <p>orders</p>
                    <h1>$670,890</h1>
                  </div>
                  <div className='value_orders'>
                    <p>Total pax</p>
                    <h1>234</h1>
                  </div>
                  <div className='value_orders'>
                    <p>Average Order Value</p>
                    <h1>$54</h1>
                  </div>
                  <div className='value_orders'>
                    <p>Lead Times</p>
                    <h1>$54</h1>
                  </div>
                </div>
                <div className='progress_layout'>
                  <div className='progress_list'>
                    <div className='line_row '>
                      <img src="/assets/img/dummy.jpg" className='img_layout'></img>
                      <p>Trip advisor</p>
                    </div>
                    <div className="progress progress_row">
                      <div className="progress-bar progress_list" role="progressbar" style={{ width: ' 100%', background: '#B0AEEF' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      <span className='progress_value'>$12,033</span>
                    </div>

                  </div>
                  <div className='progress_list'>
                    <div className='line_row'>
                      <img src="/assets/img/dummy.jpg" className='img_layout'></img>
                      <p>Viator</p>
                    </div>
                    <div className="progress progress_row">
                      <div className="progress-bar progress_list" role="progressbar" style={{ width: '70%', background: '#E6C6F4' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      <span className='progress_value'>$12,033</span>
                    </div>

                  </div>
                  <div className='progress_list'>
                    <div className='line_row'>
                      <img src="/assets/img/dummy.jpg" className='img_layout'></img>
                      <p>Klook</p>
                    </div>
                    <div className="progress progress_row">
                      <div className="progress-bar progress_list" role="progressbar" style={{ width: ' 60%', background: '#80D9CB' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      <span className='progress_value'>$12,033</span>
                    </div>

                  </div>
                  <div className='progress_list'>
                    <div className='line_row'>
                      <img src="/assets/img/dummy.jpg" className='img_layout'></img>
                      <p>Experience Oz</p>
                    </div>
                    <div className="progress progress_row">
                      <div className="progress-bar progress_list" role="progressbar" style={{ width: ' 40%', background: '#80CCE4' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      <span className='progress_value'>$7,033</span>
                    </div>

                  </div>
                  <div className='progress_list'>
                    <div className='line_row'>
                      <img src="/assets/img/dummy.jpg" className='img_layout'></img>
                      <p>GetYourGuide</p>
                    </div>
                    <div className="progress progress_row">
                      <div className="progress-bar progress_list" role="progressbar" style={{ width: ' 30%', background: '#FFCFA2' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      <span className='progress_value'> $6,033</span>
                    </div>

                  </div>
                </div>
                <div className='footer_chart pt-4'>
                  <a class="border_layout border-0" href="#">View all countries data  <span class="material-icons">east</span></a>
                </div>
              </div>
            </div>
            <div className='col-md-12 col-lg-6'>
              <div className='layout mb-3'>
                <div className='header_list'>
                  <h1 className='layout_heading mb-2 mb-3'>Reviews <span className='span_text'>4.4 (120k review)</span></h1>
                </div>
                <div className='review_main2 mb-4'>
                  <h2 className='mr-2'>4,7</h2>
                  <div className='icons_div'>
                    <span className='icon_span'>
                      <span class="material-icons">star</span>
                      <span class="material-icons">star</span>
                      <span class="material-icons">star</span>
                      <span class="material-icons">star_border</span>
                      <span class="material-icons">star_border</span>
                    </span>
                    <p>Based on 1690 ratings</p>
                  </div>
                </div>
                <div className='review'>
                  <div className='review_layout mb-3'>
                    <div>
                      <img src="/assets/img/dummy.jpg" className="wallets_img2" />
                    </div>
                    <div className='review_text'>
                      <div className='id_text'>
                        <h1> Mc Donald<span className='time_text'>3 min ago</span>
                          <span className='icon_span'>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star_border</span>
                            <span class="material-icons">star_border</span>
                          </span></h1>
                        <p>It's user friendly. Easy to search, browse, look up orders, track shipping progress, update profile settings, etc</p>
                      </div>
                      <p className='date_time'>12 July,2023</p>
                    </div>
                  </div>

                  <div className='review_layout mb-3'>
                    <div>
                      <img src="/assets/img/dummy.jpg" className="wallets_img2" />
                    </div>
                    <div className='review_text'>
                      <div className='id_text'>
                        <h1> Mc Donald<span className='time_text'>3 min ago</span>
                          <span className='icon_span'>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star_border</span>
                            <span class="material-icons">star_border</span>
                          </span></h1>
                        <p>It's user friendly. Easy to search, browse, look up orders, track shipping progress, update profile settings, etc</p>
                      </div>
                      <p className='date_time'>12 July,2023</p>
                    </div>
                  </div>
                  <div className='review_layout mb-3'>
                    <div>
                      <img src="/assets/img/dummy.jpg" className="wallets_img2" />
                    </div>
                    <div className='review_text'>
                      <div className='id_text'>
                        <h1> Mc Donald<span className='time_text'>3 min ago</span>
                          <span className='icon_span'>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star_border</span>
                            <span class="material-icons">star_border</span>
                          </span></h1>
                        <p>It's user friendly. Easy to search, browse, look up orders, track shipping progress, update profile settings, etc</p>
                      </div>
                      <p className='date_time'>12 July,2023</p>
                    </div>
                  </div>
                  <div className='review_layout mb-3'>
                    <div>
                      <img src="/assets/img/dummy.jpg" className="wallets_img2" />
                    </div>
                    <div className='review_text'>
                      <div className='id_text'>
                        <h1> Mc Donald<span className='time_text'>3 min ago</span>
                          <span className='icon_span'>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star</span>
                            <span class="material-icons">star_border</span>
                            <span class="material-icons">star_border</span>
                          </span></h1>
                        <p>It's user friendly. Easy to search, browse, look up orders, track shipping progress, update profile settings, etc</p>
                      </div>
                      <p className='date_time'>12 July,2023</p>
                    </div>
                  </div>
                </div>

                <div className='footer_chart pt-4'>
                  <a class="border_layout border-0" href="#">View all Reviews  <span class="material-icons">east</span></a>
                </div>
              </div>
            </div>
            <div className='col-md-12'>
              <div className='layout mb-3'>
                <div className='header_list mb-3'>
                  <div>
                    <h1 className='layout_heading mb-0'>Marketing Graph</h1>
                  </div>
                  <div className="btn_flex">
                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                        Chart options
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>

                    {/* <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                      Show/Hide Trend-line
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div> */}

                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                        This Month
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">2023</a>
                        <a className="dropdown-item" href="#">2024</a>
                        <a className="dropdown-item" href="#">2025</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>

                  </div>

                </div>
                <div className='footer_chart pt-4'>
                  <a class="border_layout border-0" href="#">View all Marketing data    <span class="material-icons">east</span></a>
                </div>
              </div>
            </div>

            <div className='col-md-12'>
              <div className='layout mb-3'>
                <div className='header_list'>
                  <h1 className='layout_heading mb-2'>Top Categories</h1>
                  <div className="btn_flex">
                    <div class="btn-group">
                      <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                        This Month
                      </button>
                      <div className="dropdown-menu">
                        <a className="dropdown-item" href="#">Action</a>
                        <a className="dropdown-item" href="#">Another action</a>
                        <a className="dropdown-item" href="#">Something else here</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#">Separated link</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='table_layout table-responsive mb-3'>
                  <table className='table table-striped'>
                    <thead class="table_head">
                      <tr className='data_row'>
                        <th className='table_data name_th'>Name</th>
                        <th className='table_data'>Sales</th>
                        <th className='table_data'>Orders</th>
                        <th className='table_data'>Total Pax</th>
                        <th className='table_data'>Addi. <br />Revenue</th>
                        <th className='table_data'>AVG. Order<br />Value</th>
                        <th className='table_data'>Commissions<br /> Paid</th>
                        <th className='table_data'>Lead Time</th>
                        <th className='table_data'>Reseller</th>
                        <th className='table_data'>Direct</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Milford Sound Cruise</td>
                        <td>$900,222</td>
                        <td>657</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>65 days</td>
                        <td>80%</td>
                        <td>20%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Milford Sound Cruise from Queenstown</td>
                        <td>$900,222</td>
                        <td>76</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>13 days</td>
                        <td>60%</td>
                        <td>40%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Milford Sound Fly</td>
                        <td>$900,222</td>
                        <td>34</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>12 days</td>
                        <td>90%</td>
                        <td>10%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Cruise</td>
                        <td>$900,222</td>
                        <td>76</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>23 days</td>
                        <td>75%</td>
                        <td>25%</td>
                      </tr>
                      <tr className='data_row'>
                        <td><img src="/assets/img/dummy.jpg" class="wallets_img3 mr-2" />Fly</td>
                        <td>$900,222</td>
                        <td>35</td>
                        <td>34,097</td>
                        <td>$32,080</td>
                        <td>$67.90</td>
                        <td>$32,080</td>
                        <td>10 days</td>
                        <td>55%</td>
                        <td>45%</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className='footer_chart pt-4'>
                  <a className='border_layout border-0' href="#">View all categories data  <span class="material-icons">east</span></a>
                </div>

              </div>
            </div>
            {/* <div className='col-md-12'>
              <div className='layout mb-3'>
                <div className='header_list mb-3'>
                  <h1 className='layout_heading mb-2'>Top products</h1>
                  <div className="btn_flex">

                  <div class="btn-group">
                    <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                      This Month
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">Separated link</a>
                    </div>
                  </div>
                  </div>
                </div>
                <div className='table_layout table-responsive mb-3'>
                <table className='table table-striped'>
                <thead class="table_head">
                  <tr className='data_row'>
                  <th className='table_data'>Name</th>
                  <th className='table_data'>Sales</th>
                  <th className='table_data'>Orders</th>
                  <th className='table_data'>Pax</th>
                  <th className='table_data'>Addi. revenue</th>
                  <th className='table_data'>AVG. order value</th>
                  <th className='table_data'>Commissions paid</th>
                  <th className='table_data'>Lead Times</th>
                  <th className='table_data'>Direct</th>
                  <th className='table_data'>REseller</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Milford Sound Cruise</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td>20% </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Milford Sound Cruise from Quee...</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td>20% </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Fly Cruise Fly Milford Sound</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td>20% </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Kayaking in Milford Sound - Half Day</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td>20% </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Kayaking in Milford Sound - Half Day</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td>20% </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Kayaking in Milford Sound - Full Day</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td>20% </td>
                    <td> 20%</td>
                  </tr>
                 </tbody>
                </table>
              </div>
                <div className='footer_chart'>
                  <a href="#">View all Marketing Data <span class="material-icons">east</span></a>
                </div>
              </div>
            </div> */}

            {/* <div className='col-md-12'>
              <div className='layout mb-3'>
                <div className='header_list mb-3'>
                  <h1 className='layout_heading mb-2'>Top products</h1>
                  <div className="btn_flex">

                  <div class="btn-group">
                    <button type="button" className="btn btn-primary parimarybtn dropdown-toggle ml-2 mb-2" data-toggle="dropdown" aria-expanded="false">
                      This Month
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href="#">Action</a>
                      <a className="dropdown-item" href="#">Another action</a>
                      <a className="dropdown-item" href="#">Something else here</a>
                      <div className="dropdown-divider"></div>
                      <a className="dropdown-item" href="#">Separated link</a>
                    </div>
                  </div>
                  </div>
                </div>
                <div className='table_layout table-responsive mb-3'>
                <table className='table table-striped'>
                <thead class="table_head">
                  <tr className='data_row'>
                  <th className='table_data'>Name</th>
                  <th className='table_data'>Sales</th>
                  <th className='table_data'>Orders</th>
                  <th className='table_data'>Pax</th>
                  <th className='table_data'>Addi. revenue</th>
                  <th className='table_data'>AVG. order value</th>
                  <th className='table_data'>Commissions paid</th>
                  <th className='table_data'>Lead times</th>
                  <th className='table_data'>D Vs re</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Scenic Cruise</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Scenic Flight</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Kayaking</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Day Tour</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td> 20%</td>
                  </tr>
                  <tr className='data_row'>
                    <td><img src="/assets/img/dummy.jpg" class="wallets_img3"/>Rafting</td>
                    <td>$12,00</td>
                    <td>123</td>
                    <td>23</td>
                    <td>$578</td>
                    <td> $32</td>
                    <td> $12</td>
                    <td>2d </td>
                    <td> 20%</td>
                  </tr>
                 </tbody>
                </table>
              </div>
                <div className='footer_chart'>
                  <a href="#">View all Marketing Data <span class="material-icons">east</span></a>
                </div>
              </div>
            </div> */}
          </div>
        </div>

      </Layout>
    </div>
  )
}
