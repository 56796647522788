import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ApiClient from '../../methods/api/apiClient';
import './style.scss';
import { ToastsStore } from 'react-toasts';
import loader from '../../methods/loader';
import userTableModel from '../../models/table.model';
import Html from './html';
import { CategoryType } from '../../models/type.model';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import datepipeModel from '../../models/datepipemodel';
import { getAssistantResponse } from '../../plugins/aiAssistant';
const Reviews = (p) => {
    let user = useSelector(state => state.user)
    const[item,setitem]=useState([])
    const searchState = useSelector((state) => state.search);
    const [filters, setFilter] = useState({ page: 1, count: 50, search: '', catType: '' })
    const [data, setData] = useState([])
    const [tab, setTab] = useState('list')
    const [total, setTotal] = useState(0)
    const [loaging, setLoader] = useState(true)
    const [tableCols, setTableCols] = useState([])
    const [form, setform] = useState({reviewId:'',comment:''})
    const [products, setproducts] = useState([])
    const [replyData, setReplyData] = useState()
    const [ailoader, setApiloader] = useState(false)
    const history = useHistory()
    const [sync, setsync] = useState()

    useEffect(() => {
        let cols = []
        for (let i = 0; i <= 2; i++) {
            cols.push(userTableModel.category[i])
        }
        setTableCols(cols)
        getProducts()
    }, [])

    useEffect(() => {
        if (user && user.loggedIn) {
            setFilter({ ...filters, search: searchState.data })
            getData({ search: searchState.data, page: 1 })
        }
    }, [searchState])

    const uTableCols = () => {
        let exp = []
        if (tableCols) exp = tableCols
        let value = []
        userTableModel.category.map(itm => {
            if (itm != exp.find(it => it.key == itm.key)) {
                value.push(itm)
            }
        })
        return value
    }

    const addCol = (itm) => {
        setTableCols([...tableCols, itm])
    }

    const removeCol = (index) => {
        let exp = tableCols
        exp.splice(index, 1);
        setTableCols([...exp])
    }

    const getData = (p = {}) => {
        setLoader(true)
        ApiClient.get(`api/user/profile?id=${user._id}`).then(res => {
            if (res.success) {
                setsync(res.data.tripAdvisorConnection)
            }
        })
        let filter = { ...filters, ...p }
        ApiClient.get('api/reviews/listing', filter).then(res => {
            if (res.success) {
                setData(res.data.map(itm => {
                    itm.id = itm._id
                    return itm
                }))
                setTotal(res.total)
            }
            setLoader(false)
        })
    }

    const clear = () => {
        setFilter({ ...filters, search: '', page: 1 })
        getData({ search: '', page: 1 })
    }

    const deleteItem = (id) => {
        if (window.confirm("Do you want to delete this")) {
            loader(true)
            ApiClient.delete('api/pos/delete', { id: id }).then(res => {
                if (res.success) {
                    ToastsStore.success(res.message)
                    clear()
                }
                loader(false)
            })
        }
    }

    const refresh = (id) => {
        loader(true)
        ApiClient.put('api/pos/refresh', { id: id }).then(res => {
            if (res.success) {
                toast.success(res.message)
                clear()
            }
            loader(false)
        })
    }

    const pageChange = (e) => {
        setFilter({ ...filters, page: e })
        getData({ page: e })
    }

    const ChangeRole = (e) => {
        setFilter({ ...filters, catType: e, page: 1 })
        getData({ catType: e, page: 1 })
    }
    const ChangeStatus = (e) => {
        setFilter({ ...filters, status: e, page: 1 })
        getData({ status: e, page: 1 })
    }

    const exportCsv = () => {
        loader(true)
        ApiClient.get('user/csv').then(res => {
            if (res.success) {
                let url = res.path
                let downloadAnchor = document.getElementById("downloadJS")
                downloadAnchor.href = url
                downloadAnchor.click()
            }
            loader(false)
        })
    }

    const colClick = (col, itm) => {
        if (col.key == 'healthClinicId') {
        }
    }

    const statusChange = (itm) => {
        let modal = 'category'
        let status = 'active'
        if (itm.status == 'active') status = 'deactive'

        if (window.confirm(`Do you want to ${status == 'active' ? 'Activate' : 'Deactivate'} this`)) {
            loader(true)
            ApiClient.put(`api/pos/status/change`, { id: itm.id, status }).then(res => {
                if (res.success) {
                    getData()
                }
                loader(false)
            })
        }
    }
    const view = (id) => {
        history.push("/reviewdetail/" + id)
    }

    const tabChange = (tab) => {
        setTab(tab)
    }

    const handleUpdate = (p = {}) => {
        setFilter({ ...filters, ...p })
        getData({ ...p })
    }

    const dateconvert = (date) => {
        if (date == null) {
            getData({ dateFrom: "", dateTo: "" })
        }
        else {
            const startdatedate = date[0];
            const enddate = date[1];
            const convertedstart = datepipeModel.datetostring(startdatedate);
            const convertedend = datepipeModel.datetostring(enddate);
            let filter = { dateFrom: convertedstart, dateTo: convertedend }
            setFilter({ ...filters, ...filter })
            getData({ dateFrom: convertedstart, dateTo: convertedend })
        }
    }

    const reply = (itm) => {
        setReplyData(itm)
        let comment=itm.ownerResponse?.comment?itm.ownerResponse?.comment:''
        setform({...form,reviewId:itm.reviewId,comment:comment,id:itm?.ownerResponse?.comment})
        if(itm.platform=='Google'){
            document.getElementById('replyModalBtn').click()
        }else{
            window.open(itm.url)
        }
    }

    const deleteReply=(id)=>{
        if(window.confirm('Do you want to Delete this Reply')){
            loader(true)
            ApiClient.delete('api/google/reply',{reviewId:id}).then(res=>{
                if(res.success){
                    toast.success(res.message)
                    getData()
                    document.getElementById('reviewModalClose').click()
                }
                loader(false)
            })
        }
    }

    const getProducts = () => {
        ApiClient.get(`api/products/listing?status=active`).then(res => {
            if (res.success) {
                setproducts(res.data.map(item => {
                    return ({ ...res.data, value: item._id, label: item.name })
                }))
            }
        })
    }

    const setProducts = (e) => {
        setFilter({ ...filters, productId: e })
        getData({ productId: e })
    }

    const handleProducts=()=>{
        let ext=products&&products.find(item=>item.value==filters.productId)
        return ext?ext:''
    }

    const clearKey={
        search:'',
        productId:'',
        rating:'',
        platform:'',
        start:'',
        end:'',
        type:'',
        replied:''
    }

    const isClear=()=>{
        let value=false
        Object.keys(clearKey).map(itm=>{
            if(filters[itm]) value=true
        })

        return value
    }

    const clearAll=()=>{
        let f=clearKey
        Object.keys(f).map(itm=>{
            f[itm]=''
        })
        setFilter({...filters,...f })
        getData({ ...f })
    }

    const sortClass=(key)=>{
        let cls='fa-sort'
        if(filters.key==key && filters.sorder=='asc') cls='fa-sort-up'
        else  if(filters.key==key && filters.sorder=='desc') cls='fa-sort-down'
        return 'fa '+cls
      }

      const sorting=(key)=>{
        let sorder='asc'
        if(filters.key==key){
          if(filters.sorder=='asc'){
            sorder='desc'
          }else{
            sorder='asc'
          }
        }
        

        let sortBy=`${key} ${sorder}`;
        setFilter({...filters,sortBy,key,sorder})
        getData({sortBy})
      }

      const filter=(p={})=>{
        setFilter({...filters,...p})
        getData(p)
      }

      const replySubmit=(e)=>{
        e.preventDefault()
     
        let method='post'
        if(form.id){
            return
            method='put'
        } 

        loader(true)
        ApiClient.allApi('api/reply/review',form,method).then(res=>{
            if(res.success){
               toast.success(res.message) 
               getData()
               document.getElementById('reviewModalClose').click()
            }
            loader(false)
        })
      }


    const getData2 = (p = {}) => {
        let filter = { ...filters, ...p };
        let url = 'api/review/platforms';
        ApiClient.get(url, filter).then((res) => {
            console.log(res)
         setitem(res.data)
          if (res.success) {
            
          setitem(res.data)
    }
    
});
};
const sourceLogo=(p)=>{
    let value='/assets/img/dummy.jpg'
    if(p=='Google') value=item[0].logo
    if(p=='Tripadvisor') value=item[0].logo
    return value
}
useEffect(()=>{
    getData2()
},[])


const getReply=async ()=>{
    if(ailoader) return
    let message=replyData?.text
    console.log("replyData?.text",replyData)
    if(!message){
        setform({...form,comment:'Thank You for the review.'})
      return  
    }
    setApiloader(true)
    const assistantResponse = await getAssistantResponse(message);
    console.log("assistantResponse",assistantResponse)
    setform({...form,comment:assistantResponse})
    setApiloader(false)
}


    return <><Html
    ailoader={ailoader}
    getReply={getReply}
    deleteReply={deleteReply}
    setFilter={setFilter}
    sourceLogo={sourceLogo}
    isClear={isClear}
    filter={filter}
    user={user}
    item={item}
        view={view}
        setform={setform}
        form={form}
        replySubmit={replySubmit}
        replyData={replyData}
        sortClass={sortClass}
        sorting={sorting}
        dateconvert={dateconvert}
        handleUpdate={handleUpdate}
        products={products}
        setProducts={setProducts}
        handleProducts={handleProducts}
        colClick={colClick}
        tabChange={tabChange}
        tab={tab}
        clearAll={clearAll}
        refresh={refresh}
        ChangeRole={ChangeRole}
        ChangeStatus={ChangeStatus}
        pageChange={pageChange}
        addCol={addCol}
        deleteItem={deleteItem}
        exportCsv={exportCsv}
        uTableCols={uTableCols}
        removeCol={removeCol}
        filters={filters}
        tableCols={tableCols}
        loaging={loaging}
        data={data}
        total={total}
        statusChange={statusChange}
        reply={reply}
        sync={sync}
    />
    </>;
};

export default Reviews;
