let host = document.location.host;
let apiUrl ='https://api.incogtherapy.com/'

if (window.location.protocol != "https:") {
apiUrl="https://apis.incogtherapy.com/"

}


// if(host.includes('localhost')){
//   apiUrl='http://endpoint.jcsoftwaresolution.com:6030/'
//   apiUrl='https://endpoint.dazhboards.com/'
  
// }else if(host.includes('stage.dazhboards.com/')){
//   apiUrl='https://endpoint.dazhboards.com/'
// }else if(host.includes('jcsoftwaresolution.com')){
//   apiUrl='http://endpoint.jcsoftwaresolution.com:6030/'
// }else{
//   apiUrl='https://endpoint.dazhboards.com/'
// }
const environment = {
    api: apiUrl,
    adminRoleId:'64b152a909d268f038611929',
    userRoleId:'64b15102b14de6c28838f7d2',
    map_api_key:'AIzaSyAbD0kk0SRu76yogIQKhY2r-oKdAZIbNIs',
    resellerTypeId:'64b23b7d690d1d5f7ae76102',
    productTypeId:'64a7d198fa039f179c0320ca',
    OPENAI_API_KEY:'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',
    STRIPE_LIVE_KEY:"pk_live_51OirgCIHXFocmX1Rq3qzKaFrv9NIYr8Ku7wPPsqAEKfZjphBHaek0tcKbNO8b07r6ie8AxyIlzQEjSNNhEnJxGJd00ujhgPgt1",
    STRIPE_TEST_KEY:"pk_test_51OirgCIHXFocmX1R3fmKMQlLMQbDYqTTTP3AP5Fje3HhMzvdeq1JB7mJUpFxxXnZkCKVptDbMPDIj9qRaz8giCjX007GOgQU0y"
  };
  export default environment;
