//src/plugins/aiAssistant.js
import { generateResponse } from './opnai';

export async function getAssistantResponse(userMessage) {
    try {
        const assistantResponse = await generateResponse(userMessage);
        return assistantResponse;
    } catch (error) {
        console.error('Error in AI Assistant:', error);
        return 'Sorry, I am unable to process your request at the moment.';
    }
}
