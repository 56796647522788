import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { ToastContainer, toast } from 'react-toastify';
import { ToastsStore } from 'react-toasts';
import { logout } from '../../../actions/user';
import ApiClient from '../../../methods/api/apiClient';
import loader from '../../../methods/loader';
import methodModel from '../../../methods/methods';
import Footer from '../../../pages/HomePage/Footer/Footer';
import Header from '../../../pages/HomePage/Header';
// import Header from '../../global/header';
import Layout from '../../global/layout';

const ChangePassword = p => {
  const dispatch = useDispatch()
  const history = useHistory()
  const [form, setForm] = useState({ confirmPassword: '', currentPassword: '', newPassword: '' })
  const [submitted, setSubmitted] = useState(false)
  const formValidation = [
    { key: 'confirmPassword', minLength: 8, confirmMatch: ['confirmPassword', 'newPassword'] },
    { key: 'currentPassword', minLength: 8 },
    { key: 'newPassword', minLength: 8 },
  ]
  useEffect(() => {
    loader(true)
    setTimeout(() => {
      loader(false)
    }, 600);
  }, [])
  const [eyes, setEyes] = useState({ password: false, confirmPassword: false, currentPassword: false });
  const getError = (key) => {
    return methodModel.getError(key, form, formValidation)
  }

  const handleSubmit = e => {
    e.preventDefault();
    setSubmitted(true)
    let invalid = methodModel.getFormError(formValidation, form)
    if (invalid) return

    loader(true)
    ApiClient.put('change/password', { currentPassword: form?.currentPassword, newPassword: form?.newPassword }).then(res => {
      if (res.success) {
        dispatch(logout())
        toast.success(res?.message)
        localStorage.removeItem("persist:admin-app")
        localStorage.removeItem("token")
        history.push('/login?message=' + res.message);
      }
   
      
      loader(false)
    })
  };

  const [isScrolled, setIsScrolled] = useState(false);


  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  return (

    <>
      <div className='bg_leaf position-relative'>
        <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
        <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
        <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

          <Header />
        </div>
        <div className="container-fluid p-0 overflow-hidden">
          <div className="row ">
            <div className="col-lg-12">
              <div className="contact-us-bg bg_light_grey">
                <h2>Change password</h2>

              </div>
            </div>
          </div>
        </div>


      </div>



      <div className='container p80'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6 mx-auto'>
            <div className="border_layout">
              <form
                className="pprofile1 form-row py-3 w-60 mx-auto "
                onSubmit={handleSubmit}
              >
                <div className="col-md-12 mb-3">

                  <label>Current Password<span className="start">*</span></label>
                  <div className="inputWrapper">
                    <input
                      type={eyes.currentPassword ? 'text' : 'password'}
                      className="form-control"
                      value={form.currentPassword}
                      maxLength="20"
                      onChange={e => setForm({ ...form, currentPassword: e.target.value })}
                      required
                    />
                    <i className={eyes.currentPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, currentPassword: !eyes.currentPassword })}></i>
                  </div>
                  {submitted && getError('currentPassword').invalid ? <div className="invalid-feedback d-block">Min Length must be 8 characters long</div> : <></>}
                </div>

                <div className="col-md-12 mb-3">
                  <label>New Password<span className="start">*</span></label>

                  <div className="inputWrapper">
                    <input
                      type={eyes.password ? 'text' : 'password'}
                      className="form-control"
                      value={form.newPassword}
                      maxLength="20"
                      onChange={e => setForm({ ...form, newPassword: e.target.value })}
                      required
                    />
                    <i className={eyes.password ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, password: !eyes.password })}></i>
                  </div>
                  {submitted && getError('newPassword').invalid ? <div className="invalid-feedback d-block">Min Length must be 8 characters long</div> : <></>}
                </div>

                <div className="col-md-12 mb-3">
                  <label>Confirm Password<span className="start">*</span></label>

                  <div className="inputWrapper">
                    <input
                      type={eyes.confirmPassword ? 'text' : 'password'}
                      className="form-control"

                      value={form.confirmPassword}
                      maxLength="20"
                      onChange={e => setForm({ ...form, confirmPassword: e.target.value })}
                      required
                    />
                    <i className={eyes.confirmPassword ? 'fa fa-eye' : 'fa fa-eye-slash'} onClick={() => setEyes({ ...eyes, confirmPassword: !eyes.confirmPassword })}></i>
                  </div>
                  {submitted && getError('confirmPassword').invalid ? <>

                    {/* {getError('confirmPassword').err.minLength ? <div>Min Length must be 8 characters long</div> : <></>} */}
                    {getError('confirmPassword').err.confirmMatch ? <div className="invalid-feedback d-block">Confirm Password is not matched with New Password</div> : <></>}

                  </> : <></>}




                </div>

                <div className="col-md-12 text-right mt-3">
                  <button type="submit" className="btn btn-primary">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    <ToastContainer/>
      <Footer />
    </>

  );
};

export default ChangePassword;
