import React, { useEffect, useState } from "react";
import "./style.css";
import Header from "../Header";
import Footer from "../Footer/Footer";
import ApiClient from "../../../methods/api/apiClient";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import { ToastsStore } from "react-toasts";
import { ToastContainer, toast } from "react-toastify";
import loader from "../../../methods/loader";
import { login_success } from "../../../actions/user";
import methodModel from "../../../methods/methods";
import GooglePayButton from "@google-pay/button-react";
import environment from "../../../environment";
import { useStripe, } from '@stripe/react-stripe-js';
import Stripe from "stripe";


const NextWelcomeQuestions = () => {
  const history = useHistory();

  // const stripe = useStripe()
  // var stripe = loadStripe('pk_test_51OirgCIHXFocmX1R3fmKMQlLMQbDYqTTTP3AP5Fje3HhMzvdeq1JB7mJUpFxxXnZkCKVptDbMPDIj9qRaz8giCjX007GOgQU0y');

  const stripe = new Stripe(environment.STRIPE_LIVE_KEY)
  const [isScrolled, setIsScrolled] = useState(false);
  const [PaymentInfo, SetPaymentInfo] = useState({});

  const [TokenID, setTokenID] = useState("");
  const [check, setCheck] = useState(true);
  const [state, setState] = useState({
    number: "",
    expiry: "",
    cvc: "",
    name: "",
    focus: "",
  });
  // const [Cards, setCard] = useState({
  //     cvc: '',
  //     expiry: '',
  //     focus: '',
  //     name: '',
  //     number: '',
  // }
  // )

  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const [form, setform] = useState({});
  const [terms, setTerms] = useState([]);
  const [plan, setplan] = useState([]);
  const [tab, setTab] = useState("digital");
  const [style, setstyle] = useState("");
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const user = useSelector((state) => state.user);
  const [method, setMethod] = useState("stripe");
  const [hide, unhide] = useState(false);

  const CreateToken = async (e) => {
    e.preventDefault()
    // card: {

    //   number: form?.card_number,
    //   exp_month: Number(form?.exp_month),
    //   exp_year: Number(form?.exp_year),
    //   cvc: form?.cvc
    // }
    const Cardtoken = stripe.tokens.create({
      card: {

        number: form?.card_number,
        exp_month: Number(form?.exp_month),
        exp_year: Number(form?.exp_year),
        cvc: form?.cvc
      }
    }).then((res) => {
      console.log(res, '=========================123')

    })
  }
  console.log(form)
  useEffect(() => {
    // CreateToken()
  }, [])



  // stripe.createToken is not a function in reactjs
  // In ReactJS, the stripe.createToken method is not directly available. Instead, you can use the Stripe.js library to create a token for processing payments. Here is an example of how you can create a token using Stripe.js in a React component:

  // import React, { useEffect } from 'react';
  // import { loadStripe } from '@stripe/stripe-js';

  // const MyComponent = () => {

  //   useEffect(() => {
  //     const stripePromise = loadStripe('your_stripe_public_key');

  //     const createToken = async () => {
  //       const stripe = await stripePromise;
  //       const token = await stripe.createToken({
  //         type: 'card',
  //         name: 'John Doe',
  //         card: {
  //           number: '4242424242424242',
  //           exp_month: 12,
  //           exp_year: 2023,
  //           cvc: '123'
  //         }
  //       });

  //       console.log(token);
  //     }

  //     createToken();
  //   }, []);

  //   return (
  //     <div>
  //       {/* Your component JSX here */}
  //     </div>
  //   );
  // }

  // export default MyComponent;




  // useEffect(async () => {
  //     const session = await stripe.checkout.sessions.create({
  //         line_items: [
  //             {

  //                 price: 'price_1OSDIQSJXboQazN3qDBE9CkV',
  //                 quantity: 1,
  //             },
  //         ],
  //         mode: 'payment',
  //         success_url: 'http://localhost:8053/next',
  //         cancel_url: 'http://localhost:8053',
  //     });

  // }, [])

  const CreateCustomer = async () => {
    const customer = await stripe.customers.create({
      email: "vikasR@yopmail.com",
    });
  };
  useEffect(() => {
    // CreateCustomer()
  }, []);
  const getData = () => {
    // setLoader(true)
    ApiClient.get("all/questions/list").then((res) => {
      if (res.success) {
        setData(res.data?.data);
      }
      // setLoader(false)
    });
  };

  const features = plan.reduce((acc, plan) => {
    plan.features.forEach((feature) => {
      if (!acc.includes(feature.name)) {
        acc.push(feature.name);
      }
    });
    return acc;
  }, []);

  const paymentRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
    allowedPaymentMethods: [
      {
        type: "CARD",
        parameters: {
          allowedAuthMethods: ["PAN_ONLY", "CRYPTOGRAM_3DS"],
          allowedCardNetworks: ["MASTERCARD", "VISA"],
        },
        tokenizationSpecification: {
          type: "PAYMENT_GATEWAY",
          parameters: {
            gateway: "stripe",
            "stripe:version": "2023-10-16",
            "stripe:publishableKey":
              "pk_test_51KyZElEGnmCJcey2kk3glVh6N3xDcL9EGmxOOX6POD0xsaLNUJIBVZcNM4Kvg2Pe5Gyln1bn7Ge0WkdkbyQCRb1J00stuxPQRm",
          },
        },
      },
    ],
    merchantInfo: {
      merchantId: "BCR2DN4TZK2JD4Q2",
      merchantName: "Demo Merchant",
    },
    transactionInfo: {
      totalPriceStatus: "FINAL",
      totalPriceLabel: "Total",
      totalPrice: form?.price,
      currencyCode: "USD",
      countryCode: "US",
    },
    shippingAddressRequired: true,
    callbackIntents: ["SHIPPING_ADDRESS", "PAYMENT_AUTHORIZATION"],
  };
  // if (PaymentInfo?.paymentMethodData) {

  //     const jsonString = PaymentInfo?.paymentMethodData?.tokenizationData?.token;

  //     // Parse the JSON string
  //     const decodedData = JSON.parse(jsonString);

  //     // Access the properties
  //     setTokenID(decodedData.id)
  //     const tokenId = decodedData.id;
  //     const objectType = decodedData.object;
  //     const cardId = decodedData.card.id;
  //     const cardObject = decodedData.card.object;

  //     // Log or use the decoded data
  //     console.log('Token ID:', tokenId);
  //     console.log('Object Type:', objectType);
  //     console.log('Card ID:', cardId);
  //     console.log('Card Object:', cardObject);
  // }
  const GetPlan = () => {
    loader(true);
    ApiClient.get("plans?status=active").then((res) => {
      if (res?.success) {
        setplan(
          res?.data.sort((a, b) => parseFloat(a.price) - parseFloat(b.price))
        );
        // setplan(res?.data)
        setform({
          id: res?.data[0]?.id,
          price: res?.data[0]?.price,
          plan_id: res?.data[0]?.id,
        });
        window.scrollTo(0, 120);
        loader(false);
      }
    });
  };

  useEffect(() => {
    getData();
    GetPlan();
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      history.push("/");
    }
  });

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    // console.log(form);
  }, [form]);
  const HandleSubmit = (e) => {
    e.preventDefault();
    loader(true);
    const Cardtoken = stripe.tokens.create({
      card: {

        number: form?.card_number,
        exp_month: Number(form?.exp_month),
        exp_year: Number(form?.exp_year),
        cvc: form?.cvc
      }
    }).then((response) => {
      console.log(response, '===========================123456')

      if (response.card) {


        ApiClient.post("card", {
          token_id: response?.id,
          last4: response?.card?.last4,
          exp_year: response?.card?.exp_year,
          exp_month: response?.card?.exp_month,
          brand: response?.card?.brand
        }).then((res) => {
          if (res.success) {
            ApiClient.post("payment", {
              amount: form.price,
              currency: "USD",
              user_id: user?.id,
              plan_id: form?.plan_id,
            }).then((res) => {
              if (res?.success) {
                console.log(res);
                localStorage.setItem("TransactID", res?.transaction_id);
                // ApiClient.get('user/detail', { id: user.id }).then((res) => {
                //     if (res?.success) {
                //         dispatch(login_success(res.data));
                //     }
                // })

                toast.success(res?.message);
                history.push("/payment-success");
              }
              loader(false);
            });
          }
        });
      }
    })
  };

  const GetContent = (p = {}) => {
    loader(true);
    let filter = p;
    ApiClient.get("content", p).then((res) => {
      if (res.success) {
        setTerms(res?.data);
      }
      loader(false);
    });
  };

  const HandleSubmitGoogle = (e) => {
    e.preventDefault();
    loader(true);
    // ApiClient.post('card', { card_number: form?.card_number, exp_month: form?.exp_month, exp_year: form?.exp_year, cvc: form?.cvc }).then((res) => {
    // if (res.success) {
    ApiClient.post("payment/create-intent", {
      amount: parseInt(form.price),
      currency: "USD",
      user_id: user?.id,
      plan_id: form?.plan_id,
      exp_month: form?.exp_month,
      exp_year: form?.exp_year,
      cvc: form?.cvc,
      number: form?.card_number,
    }).then((res) => {
      if (res?.success) {
        if (window.confirm("Do you want to complete this purchase ?")) {
          ApiClient.post("payment/confirm-payment", {
            // paymentMethodId: res?.data?.payment_method_configuration_details?.id,
            paymentIntentId: res?.paymentIntent?.id,
          }).then((res) => {
            if (res.success) {
              toast.success(res.message);
              ApiClient.get("user/detail", { id: user?.id }).then((res) => {
                if (res.success) {
                  dispatch(login_success(res?.data));
                }
              });
            }
          });
        }
        // ApiClient.get('user/detail', { id: user.id }).then((res) => {
        //     if (res?.success) {
        //         dispatch(login_success(res.data));
        //     }
        // })

        toast.success(res?.message);
        // history.push('/appointment')
      }
      loader(false);
    });

    // })
  };
  useEffect(() => {
    if (user.is_plan) {
      history.push("/appointment");
    }
  }, [user]);

  return (
    <>
      <div className="bg_leaf position-relative">
        <div className={`header-dark-page ${isScrolled ? "scrollsticky" : ""}`}>
          <Header />
        </div>
      </div>

      <section className="p80 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-10 col-lg-10 mx-auto">
              <div className="row">
                <div className="col-lg-12">
                  <div className="title-box text-center">
                    <h3 className="text-black">
                      <span className="dynamix_name ">
                        {user?.firstName || "Hello"}
                      </span>
                      , Welcome to{" "}
                      <span className="textwhite">Internal Therapy!</span>
                    </h3>

                    <div className="alrt_div">
                      <p>
                        <span class="material-icons mr-2">info</span> Thanks for
                        telling us your preferences! As we search for your
                        therapist, please read the following guide and begin
                        your membership.
                      </p>
                    </div>

                    <div className="text_div">
                      <h1>What happens next?</h1>
                      <ul>
                        <li>
                          You will receive a personalized match to a qualified
                          and licensed therapist.
                        </li>
                        <li>
                          Your therapist will thoughtfully review what you've
                          shared and reach out to you.
                        </li>
                        <li>
                          You can begin communicating with your therapist
                          online, and your therapy process begins.
                        </li>
                      </ul>
                    </div>

                    <div className="text_div">
                      <h1>How do I talk to my therapist?</h1>
                      <p className="text-black">
                        You have many options for how you choose to communicate
                        with your therapist. You can send audio, video, or text
                        messages to your therapist at any time in the messaging
                        room. You can also schedule weekly live sessions (30 to
                        45 min) with your therapist to communicate via phone,
                        video, or live chat.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="p-140 shapebeforeafter bg_blue">
        <div className="container">
          <div className="row">
            <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto">
              <div className="sec-title-plan text-center mb-3">
                <h2 className=" text-white">
                  Suitable Plans For You And Your Family
                </h2>
              </div>

              {/* <div className='pricing_new pb-5'>
                                <div className='row'>
                                    <div className='col-sm-7 p-0'>
                                        <div>
                                            <p></p>
                                        </div>
                                    </div>
                                    <div className='col-sm-5 p-0'>
                                        <div className='row'>
                                            <div className='col-sm-6 p-0'>
                                                <div className={`plans_data ${tab == 'digital' ? 'logo-therpy' : ''} `} onClick={() => {
                                                    setTab('digital')
                                                    setform({ id: plan[0]?.id, price: plan[0]?.price, plan_id: plan[0]?.id })
                                                    console.log(form)
                                                    unhide(true)
                                                    window.scrollTo(0, 2000)
                                                }}>
                                                    <div className='imglogo'>
                                                        Digital Therapy
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-sm-6 p-0'>
                                                <div className={` plans_data ${tab == 'in-office' ? 'logo-therpy' : ''} `} onClick={() => {
                                                    setTab('in-office')
                                                    setform({ id: plan[1]?.id, price: plan[1]?.price, plan_id: plan[1]?.id })
                                                    console.log(form)
                                                    unhide(true)
                                                    window.scrollTo(0, 2000)
                                                }}>
                                                    <p className='m-0'>In-Office</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className='row borderbotom'>
                                    <div className='col-sm-7 p-0'>
                                        {features.map(feature => (
                                            <div className='first_column' key={methodModel.capitalizeFirstLetter(feature)}>
                                                <p className='text-white m-0'>{methodModel.capitalizeFirstLetter(feature)}</p>
                                            </div>
                                        ))}
                                        <div className='first_column'>
                                            <p className='text-white m-0'>Cost</p>
                                        </div>
                                    </div>
                                    <div className='col-sm-5 p-0'>
                                        <div className='row'>
                                            <div className='col-sm-6 p-0'>
                                                {plan?.[0]?.features?.map((item) => {
                                                    return <div className={` second_column ${tab == 'digital' ? 'active_price' : ''} `} >{
                                                        item.isChecked ? <img src="/assets/img/therpy/check.png" className='img-fluid' /> : <img src="/assets/img/therpy/cross.png" className='img-fluid' />
                                                    } </div>

                                                })
                                                }
                                                <div className={`text20 second_column ${tab == 'digital' ? 'active_price' : ''} `} >${plan?.[0]?.price} per week**</div>
                                            </div><div className='col-sm-6 p-0'>
                                                {plan?.[1]?.features?.map((item) => {
                                                    return <div className={` second_column ${tab == 'in-office' ? 'active_price' : ''} `} >{
                                                        item.isChecked ? <img src="/assets/img/therpy/check.png" className='img-fluid' /> : <img src="/assets/img/therpy/cross.png" className='img-fluid' />
                                                    } </div>

                                                })
                                                }
                                                <div className={`text20 second_column   ${tab == 'in-office' ? 'active_price' : ''} `} >${plan?.[1]?.price} per session**</div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div> */}

              <div className="row mt-5">
                {plan?.map((itm) => {
                  return (
                    <div
                      className="col-sm-12 col-md-3 col-lg-3 pointer"
                      onClick={() => {
                        setform({
                          id: itm?.id,
                          price: itm?.price,
                          plan_id: itm?.id,
                        });

                        unhide(true);
                        window.scrollTo(0, 1350);
                      }}
                    >
                      <div
                        className={
                          form?.plan_id == itm?.id
                            ? "align_set selected"
                            : "align_set"
                        }
                      >
                        <img
                          src={`${environment.api}images/users/` + itm?.image}
                          className="wauto"
                        />
                        <div className="">
                          <h4 className="fonts14 white">
                            {methodModel.capitalizeFirstLetter(itm?.name)}
                          </h4>
                          <p className="fonts13 white height_statick">
                            {itm?.description}
                          </p>
                          <h4 className="price_main">${itm?.price}</h4>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* payment */}
      {hide ? (
        <section className="pt-3 payment">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-12">
                <div className="suitable_textblueline position-relative text-center mb-5">
                  <h5 className="blueline1">
                    Enter payment information to start
                  </h5>
                </div>
              </div>

              <div className="col-12 col-sm-12  col-md-6">
                <div className="payment_data ">
                  <div className="row ">
                    {/* <div >
                                                <button style={{ border: '1px solid black', color: `${method == 'stripe' ? 'white' : 'black'}` }} className={`btn ${method == 'stripe' ? 'btn-primary' : null}`} onClick={() => {
                                                    setMethod('stripe')
                                                }}> <img style={{ width: '30px' }} src="https://www.svgrepo.com/show/331592/stripe-v2.svg" alt="" /> Stripe </button>
                                                <button style={{ border: '1px solid black', color: `${method == 'google' ? 'white' : 'black'}` }} className={`btn ml-3  ${method == 'google' ? 'bg-black' : null}`} onClick={() => {
                                                    setMethod('google')
                                                }} ><img style={{ width: '30px' }} src="https://upload.wikimedia.org/wikipedia/commons/thumb/c/c1/Google_%22G%22_logo.svg/1200px-Google_%22G%22_logo.svg.png" alt="" /> Pay </button>
                                             
                                               
                                            </div> */}

                    <div class="payment-form mt-4">
                      {/* <div>

                                                            <p style={{ color: 'wheat' }} className={`btn btn-primary`} ><img style={{ width: '30px' }} src="https://www.svgrepo.com/show/331592/stripe-v2.svg" alt="" /> Stripe </p>

                                                        </div> */}
                      {method == "stripe" ? (
                        <>
                          <div>
                            <h4>Select your payment method.</h4>
                          </div>
                          <button
                            onClick={() => {
                              setMethod("card");
                            }}
                            type="submit"
                            disabled={check}
                            className="btn btn-primary btn-xl mt-3"
                          >
                            <i
                              color="red"
                              class="fa fa-credit-card mr-2"
                              aria-hidden="true"
                            ></i>
                            Pay via Card
                          </button>
                          <button className="btn mt-4" disabled={check}>
                            <GooglePayButton
                              environment="TEST"
                              onPaymentAuthorized={(paymentData) => {
                                SetPaymentInfo(paymentData);
                                //  CreateToken()
                                // Parse the JSON string
                                let JsonString =
                                  paymentData?.paymentMethodData
                                    ?.tokenizationData?.token;
                                const decodedData = JSON.parse(JsonString);
                                console.log(decodedData);
                                // Access the properties
                                setTokenID(decodedData.id);

                                ApiClient.post("payment/create-google-intent", {
                                  googlePayToken: decodedData.id,
                                  amount: form?.price,
                                  currency: "USD",
                                  user_id: user.id,
                                  plan_id: form?.plan_id,
                                }).then((res) => {
                                  if (res.success) {
                                    toast.success(res.message);
                                    localStorage.setItem(
                                      "TransactID",
                                      res?.transaction_id
                                    );
                                    setTimeout(() => {
                                      history.push("payment-success");
                                    }, 1000);
                                  }
                                });

                                return { transactionState: "SUCCESS" };
                              }}
                              onPaymentDataChanged={(paymentData) => {


                                return {};
                              }}
                              paymentRequest={paymentRequest}
                              onLoadPaymentData={(paymentRequest) => {
                                console.log(
                                  "load payment data",
                                  paymentRequest
                                );
                              }}
                            />
                            {/* <Cards
                              number={123}
                              expiry={state.expiry}
                              cvc={state.cvc}
                              name={state.name}
                              focused={state.focus}
                            /> */}
                          </button>
                        </>
                      ) : (
                        <>




                          <form onSubmit={HandleSubmit} method="post">
                            <div class="form-group">
                              <label for="cardholder">Cardholder's Name</label>
                              <input
                                type="text"
                                className="form-control"
                                id="cardholder"
                                name="cardholder"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label for="cardnumber">Card Number</label>
                              <input
                                type="text"
                                maxLength={16}
                                onInput={(object) => {
                                  if (
                                    object.target.value.length >
                                    object.target.maxLength
                                  ) {
                                    object.target.value =
                                      object.target.value.slice(
                                        0,
                                        object.target.maxLength
                                      );
                                  }
                                }}
                                onChange={(e) =>
                                  setform({
                                    ...form,
                                    card_number: e.target.value,
                                  })
                                }
                                className="form-control"
                                id="cardnumber"
                                name="cardnumber"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label for="expiration">Expiration Date</label>
                              <input
                                type="text"
                                onChange={(e) => {
                                  setform({
                                    ...form,
                                    exp_year: e.target.value.split("/")[1],
                                    exp_month: e.target.value.split("/")[0],
                                  });
                                }}
                                className="form-control"
                                id="expiration"
                                name="expiration"
                                placeholder="MM/YY"
                                required
                              />
                            </div>
                            <div class="form-group">
                              <label for="cvv">CVV</label>
                              <input
                                type="text"
                                maxLength={4}
                                onInput={(object) => {
                                  if (
                                    object.target.value.length >
                                    object.target.maxLength
                                  ) {
                                    object.target.value =
                                      object.target.value.slice(
                                        0,
                                        object.target.maxLength
                                      );
                                  }
                                }}
                                onChange={(e) => {
                                  setform({ ...form, cvc: e.target.value });
                                }}
                                className="form-control"
                                id="cvv"
                                name="cvv"
                                required
                              />
                            </div>
                            <button
                              type="submit"
                              disabled={form?.price ? false : true}
                              className="btn btn-primary btn-xl mt-4"
                            >
                              Start Therapy
                            </button>
                            <button
                              className="btn btn-secondary btn-xl mt-4 ml-2"
                              onClick={() => setMethod("stripe")}
                            >
                              Cancel
                            </button>
                            {/* <button className='btn mt-4' >
                                                                    <GooglePayButton

                                                                        environment="TEST"
                                                                        onPaymentAuthorized={paymentData => {

                                                                            SetPaymentInfo(paymentData)
                                                                            console.log('Payment Authorised Success', paymentData)
                                                                            console.log(paymentData, "================")
                                                                            // Parse the JSON string
                                                                            let JsonString = paymentData?.paymentMethodData?.tokenizationData?.token
                                                                            const decodedData = JSON.parse(JsonString);
                                                                            console.log(decodedData)
                                                                            // Access the properties
                                                                            setTokenID(decodedData.id)

                                                                            ApiClient.post('payment/create-google-intent', {
                                                                                googlePayToken: decodedData.id,
                                                                                amount: form?.price,
                                                                                currency: "USD",
                                                                                user_id: user.id
                                                                            }).then((res) => {
                                                                                if (res.success) {
                                                                                    toast.success(res.message)
                                                                                }
                                                                            })


                                                                            return { transactionState: 'SUCCESS' }
                                                                        }
                                                                        }
                                                                        onPaymentDataChanged={paymentData => {
                                                                            console.log('On Payment Data Changed', paymentData)

                                                                            return {}
                                                                        }
                                                                        }
                                                                        paymentRequest={paymentRequest}
                                                                        onLoadPaymentData={paymentRequest => {
                                                                            console.log('load payment data', paymentRequest);
                                                                        }}
                                                                    />
                                                                </button> */}
                          </form>

                        </>
                      )}
                      {method == "stripe" && (
                        <div className="mt-4 d-flex align-items-baseline">
                          <input
                            type="checkbox"
                            className="checkBox"
                            name="check"
                            onChange={(e) => {
                              if (e.target.checked) {
                                setCheck(false);
                              } else {
                                setCheck(true);
                              }
                            }}
                          />
                          <label className="clickBox ml-2 mb-0">
                            By checking this box, I agree that I have read and
                            accepted the
                            <a
                              onClick={() => {
                                GetContent({
                                  page_name: "payment consent form",
                                });
                                document.getElementById("OpenModel").click();
                              }}
                            >
                              {" "}
                              Payment Consent Form
                            </a>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-12  col-md-6">
                <div className="imgtag text-center">
                  <img
                    className="img-fluid"
                    src="/assets/img/therpy/girlbank.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}

      <ToastContainer position="top-right" />
      <button
        id="OpenModel"
        type="button"
        class="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal"
      ></button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {methodModel.capitalizeFirstLetter(terms?.title)}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="overf_scrol"
                dangerouslySetInnerHTML={{ __html: terms?.description }}
              ></div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <Footer />
      </div>
    </>
  );
};

export default NextWelcomeQuestions;
