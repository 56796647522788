import React, { useEffect, useState } from 'react'
import Header from './Header'
import "./style.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Accordion } from "react-bootstrap";
import Footer from './Footer/Footer';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import loader from '../../methods/loader';
import environment from '../../environment';
import ApiClient from '../../methods/api/apiClient';
import methodModel from '../../methods/methods';


const NextArrow = (props) => {
  const { onClick } = props;


  return (
    <div className='mobile-arrow ipad'>
      <div className="custom-next-arrow arrow-right" onClick={onClick}>
        <i className='fa fa-arrow-right'></i>
      </div>
    </div>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <div className='mobile-arrow ipad'>
      <div className="custom-next-arrow arrow-left" onClick={onClick}>
        <i className='fa fa-arrow-left'></i>
      </div>
    </div>
  );
};


function HomePage() {
  const settingss = {
    dots: false,
    infinite: true,
    className: "center",
    centerMode: true,
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    speed: 500,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };
  const user = useSelector((state) => state.user)
  const history = useHistory()
  useEffect(() => {
    loader(true)
    setTimeout(() => {
      loader(false)
    }, 600);
  })
  const [btn, setbtn] = useState(false)
  const [plan, setplan] = useState([])
  const [faqData, setfaqData] = useState([])
  const [filters, setfilters] = useState({})
  const [Cards, setCards] = useState([])
  const GetPlan = () => {
    loader(true)
    ApiClient.get('plans?status=active').then((res) => {
      if (res?.success) {
        setplan(res?.data.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)));
        // setplan(res?.data)
        // setform({ id: res?.data[0]?.id, price: res?.data[0]?.price, plan_id: res?.data[0]?.id })
        // window.scrollTo(0, 120)
        loader(false)
      }
    })
  }

  useEffect(() => {
    GetPlan()
  }, [])

  useEffect(() => {
    ApiClient.get('all/faq', filters).then((res) => {
      setfaqData(res?.data?.data)
    })
    GetTestimonials()
  }, [])

  const GetTestimonials = () => {
    ApiClient.get('testimonials?page=1&count=10&is_top_therapist=true').then((res) => {
      if (res.success) {
        setCards(res?.data)
      }
    })
  }
  return (
    <>




      <section className='sec-one bg-blue-img'>
        <Header />
        <div className='row'>
          <div className='container '>
            <div className='secone-wrapper text-center'>
              <div>
                <div className='secone-inner text-white'>
                  <h1 className='main-title'>You deserve to be happy.</h1>
                  <p className='sub-title'>Your Second chance awaits you!
                    <span className='d-block mt-2'>What type of consultation are you looking for?</span>
                  </p>

                  <span className='width_text'></span>
                </div>
                <div className='box_card_inner'>
                  <div className='row'>
                    <div className='col-sm-12 col-4 col-md-4'>
                      <span className='radio_custom mr-0'>
                        <label class="custom-radio mr-0">
                          <div className='card_one'>
                            <div className='text-left'>
                              <img src="./assets/img/therpy/h1.png" className='img-fluid fluid2' />
                            </div>

                            <div className='text_card d-flex justify-content-between flex-wrap'>
                              <span className='text-left'>
                                <h2>Individual</h2>
                                <p>For Myself</p>
                              </span>

                              {!user?.loggedIn && <><input onChange={() => {

                                setbtn(true)

                              }} type="radio" name="customRadio" />
                                <span class="radio-icon"></span></>}


                            </div>
                          </div>
                        </label>
                      </span>

                    </div>
                    <div className='col-sm-12 col-4 col-md-4'>
                      <span className='radio_custom'>
                        <label class="custom-radio">
                          <div className='card_one'>
                            <div className='text-left'>
                              <img src="./assets/img/therpy/h2.png" className='img-fluid fluid2' />
                            </div>

                            <div className='text_card d-flex justify-content-between flex-wrap'>
                              <span className='text-left'>
                                <h2>Couples</h2>
                                <p>For My Partner</p>
                              </span>

                              {!user?.loggedIn && <><input onChange={() => {
                                setbtn(true)
                              }} type="radio" name="customRadio" />
                                <span class="radio-icon"></span></>}

                            </div>
                          </div>
                        </label>

                      </span>
                    </div>

                    <div className='col-sm-12 col-4 col-md-4'>
                      <span className='radio_custom'>
                        <label class="custom-radio">
                          <div className='card_one'>
                            <div className='text-left'>
                              <img src="/assets/img/family.png" className='img-fluid fluid2' />
                            </div>

                            <div className='text_card d-flex justify-content-between flex-wrap'>
                              <span className='text-left'>
                                <h2>Family</h2>
                                <p>My Family</p>
                              </span>

                              {!user?.loggedIn && <><input onChange={() => {
                                setbtn(true)
                              }} type="radio" name="customRadio" />
                                <span class="radio-icon"></span></>}

                            </div>
                          </div>
                        </label>

                      </span>
                    </div>


                  </div>

                  <div className='row justify-content-center mt-5'>
                    <div className='d-flex justify-content-center'>

                      {
                        btn &&
                        <button className='btn btn-white btn-lg shadowboxbtn' onClick={() => {
                          if (user?.loggedIn) {
                            history.push('/next')
                          } else {
                            history.push('/questions')
                          }
                        }}>Get Started</button>
                      }
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section>


      <section className='sec-third '>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-7 col-lg-7'>
              <div className='img_circle position-relative'>
                <img src="./assets/img/therpy/circle3.png" className='img-fluid' />

                <h2 className='text_absoulte3'>The world's leading <br></br> therapy service. <span className='textblue d-block'>100% online.</span> </h2>

              </div>
            </div>
            <div className='col-sm-12 col-md-5 col-lg-5'>
              <div className='sec3_ul'>
                <ul className='ulclass'>
                  <li className='third_sec_text'>
                    <h5>3,56,8941</h5>
                    <p>Messages, chat, phone, video sessions</p>
                    <span className='wline'></span>
                  </li>
                  <li className='third_sec_text'>
                    <h5>48,941</h5>
                    <p>Professional consultants ready to help</p>
                    <span className='wline'></span>
                  </li>
                  <li className='third_sec_text'>
                    <h5>2,56,8941</h5>
                    <p>People got help</p>

                  </li>
                </ul>
              </div>
            </div>


          </div>
        </div>
      </section>



      <section className='sec-forth bg-grey-img position-relative'>
        <div className=' container'>
          <div className=''>
            <div className='row'>
              <div className='col-sm-12  col-md-7 col-lg-6'>
                <div className='forth_sec'>
                  <div className='text-forth-head'>
                    <h6 className='black-lg-text'>Professional consultants <br></br>
                      who you can trust</h6>
                    <p className='text-p-all'>
                    Our professional consultants are skilled counsellors with a variety of master degrees in Forensic Psychology, Clinical Mental Health Counselings, Social Work and relationships, specializing in a range of areas including crisis management, domestic violence, and trauma. Fluent in English and Arabic. Our consultants are dedicated to assisting individuals, couples and families in overcoming interpersonal conflicts and mental health challenges, focusing on tailored plans and effective coping strategies to enhance their quality of life.                     </p>
                    <button className='btn btn-primary bg-main' onClick={() => {
                      if (!user.loggedIn) {
                        history.push('/login')
                      } else {

                        user?.is_plan && user ? history.push('/appointment') : history.push('/next')
                      }

                    }}>Book Now</button>

                  </div>

                </div>

              </div>

              <div className='col-sm-12 col-md-5 col-lg-6'>
                <div className='img_for_gallery'>
                  <div className='row flex-mobile'>
                    <div className='col-sm-12 col-md-6 '>
                      <img src="./assets/img/therpy/c0.png" className='img-fluid' />
                    </div>
                    {/* <div className=' col-sm-12 col-md-6 justify-content-end align-content-end text-end'>
                      <img src="./assets/img/therpy/p1.png" className='img-fluid' />
                      <br></br>
                      <img src="./assets/img/therpy/p3.png" className='img-fluid' />
                    </div>

                    <div className='col-sm-12 col-md-6'>
                      <img src="./assets/img/therpy/p2.png" className='img-fluid' />
                      <br></br>
                      <img src="./assets/img/therpy/p4.png" className='img-fluid' />
                    </div> */}

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>


      <section className='sec-fifth'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 mx-auto col-sm-12'>
              <div className='heading-top text-center '>
                <h6 className='heading-tittle'>How it works</h6>
                <span className='lineheading d-block'></span>
              </div>
            </div>
          </div>

          <div className='row '>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <div className='img_fifth_sec d-flex justify-content-between align-items-start'>
                <img src="./assets/img/therpy/c1.png" className='img-fluid ' />
                {/* <img src="./assets/img/therpy/p5.png" className='img-fluid' />
                <img src="./assets/img/therpy/p6.png" className='img-fluid' /> */}
              </div>
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <div className='fisthsec_text '>
                <h6 className='light-blue-text'>Get matched to best <br></br> professional consultant for you.</h6>
                <p className='text-p-all'>Utilize the questions provided during your sign up, and the text box to explain how we can help you. Once you set up an appointment on our online calendar and professional consultant will be ready to discuss further and provide you with a tailored plan.</p>
              </div>
            </div>


          </div>

          <div className='row flex-column-reverse-mobile pt5'>


            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <div className='fisthsec_text'>
                <h6 className='light-blue-text'>Communicate your way</h6>
                <p className='text-p-all'>We understand that mental health issues can be complicated and sensitive to some individuals, couples and families. Our services are tailored to fit your comfort level. If you would like to remain anonymous you choose one of our most popular options, a chat, text, or a phone call. We also provide a face to face virtual video option if you prefer to see your professional consultant.</p>
              </div>
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <div className='img_fifth_sec d-flex justify-content-between align-items-start ml-auto'>
                <img src="./assets/img/therpy/c2.png" className='img-fluid ' />
                {/* <img src="./assets/img/therpy/p8.png" className='img-fluid' />
                <img src="./assets/img/therpy/p9.png" className='img-fluid' /> */}
              </div>
            </div>


          </div>

          <div className='row pt5'>
            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <div className='img_fifth_sec d-flex justify-content-between align-items-start'>
                <img src="./assets/img/therpy/c3.png" className='img-fluid ' />
                {/* <img src="./assets/img/therpy/p11.png" className='img-fluid' />
                <img src="./assets/img/therpy/p12.png" className='img-fluid' /> */}
              </div>
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6 '>
              <div className='fisthsec_text '>
                <h6 className='light-blue-text'>Therapy when you need it</h6>
                <p className='text-p-all'>You can message your consultants at anytime, from anywhere. You also get to schedule live sessions when its convenient for you, and cab connect from any mobile device or computer. </p>
              </div>
            </div>
          </div>


        </div>
      </section>


      <section className='sec-sixth bg-blue slider_wrapper'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8 mx-auto col-sm-12'>
              <div className='heading-top text-center '>
                <h6 className='heading-tittle text-white'>Our top Therapists/ Professional Consultants</h6>
                {/* <p className='text-white'>See what are users are saying about our services and support</p> */}
              </div>
            </div>
          </div>

          <div className='row'>
            <div className='col-sm-12 col-md-12'>
              <Slider   {...settingss} className=' slider_slick position-relative' >

                {
                  Cards?.map((itm) => {
                    return (

                      <div>
                        <div className='card-slider-box'>
                          <div className='profile_item'>
                            <img src={environment?.api + "images/users/" + itm?.image} className='imgs_testi' />
                            <div className='profile_name'>
                              <h6 className='mb-2'>{itm?.name}</h6>
                              <p>{itm?.place}</p>
                            </div>
                          </div>
                          <div className='profile_desc'>
                            <p>{itm?.content?.substr(0, 400)}...</p>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </Slider>
            </div>
          </div>
        </div>
      </section>


      <section className='sec-sevnth faq_wrapper p-140'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-md-6 col-lg-6'>
              <div className='faq_text'>
                <h6>
                  Frequently Asked <br></br>
                  Questions ?
                </h6>
                <p className='mt-3'>
                  For more information, please visit the <span className='lblue'>Help Center.</span>
                  <br></br>   also find all faq’s on our faq’s page
                </p>

                <button className='btn btn-primary bg-smal mt-4' onClick={() => history.push('/faq')}>
                  See more
                </button>
              </div>
            </div>

            <div className='col-sm-12 col-md-6 col-lg-6'>
              <div className='faq_accordian'>
                {faqData?.slice(1, 5)?.map((itm) =>
                  <Accordion>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{itm?.question}</Accordion.Header>
                      <Accordion.Body close>
                        <div dangerouslySetInnerHTML={{ __html: itm?.answer }} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                )}
              </div>
            </div>


          </div>
        </div>
      </section>


      <section className='sec-sevnth faq_wrapper p-140 pt-0 pb-5'>
        <div className='container'>
          <div className='row'>
            {plan?.map((itm) => {
              return (
                <div className='col-sm-12 col-md-3 col-lg-3'>
                  <div className='align_set'>
                    <img src={`${environment.api}images/users/` + itm?.image} className='wauto' />
                    <div className='text_centered'>
                      <h4>{methodModel.capitalizeFirstLetter(itm?.name)}</h4>
                      <p>{itm?.description}</p>
                    </div>
                    <div>
                    {/* <h4 className="price_main">${itm?.price}</h4> */}
                    <h3 className='price_main pointer' onClick={()=>{
                      if(user?.loggedIn){
                        history.push('/next')
                      }else{
                        history.push('/questions')
                      }
                    }}>${itm?.price}</h3>
                  </div>
                  </div>
                 
                </div>)
            })}
          </div>
        </div>
      </section>

      <Footer />





    </>

  )
}

export default HomePage
