import React, { useEffect, useState } from 'react';
import "./style.css"
import Header from '../HomePage/Header';
import Footer from '../HomePage/Footer/Footer';
// import ApiClient from '../../../methods/api/apiClient';

import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ApiClient from '../../methods/api/apiClient';


const Questions = () => {
  const history = useHistory()
  const [isScrolled, setIsScrolled] = useState(false);
  const [data, setData] = useState([])
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const[index,setindex] = useState(0)

  const getData = () => {
    // setLoader(true)
    ApiClient.get('all/questions/list',{role:"therapist"}).then(res => {
      if (res.success) {
        setData(res.data?.data)
      }
      // setLoader(false)
    })
  }

  useEffect(() => {
    getData()
    window.scrollTo(0,0)
  }, [])

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleAnswerOptionClick = (answerIndex) => {
    const questionType = data[currentQuestion]?.answer_type;
    const selectedOptions = selectedAnswer ? [...selectedAnswer] : [];

    if (questionType === 'single') {
      setSelectedAnswer(answerIndex);
      const nextQuestion = currentQuestion + 1;
      if (nextQuestion < data.length) {
        setCurrentQuestion(nextQuestion);
        setSelectedAnswer(null); 
      } else {
        console.log('End of questions reached.');
        history.push("/signup");
      }
    } else if (questionType === 'multiple') {
      const selectedIndex = selectedOptions.indexOf(answerIndex);
      if (selectedIndex === -1) {
        selectedOptions.push(answerIndex);
      } else {
        selectedOptions.splice(selectedIndex, 1);
      }
      setSelectedAnswer(selectedOptions);
    }
  };

  return (
    <>


      <div className='bg_leaf position-relative'>
        <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
        <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
        <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

          <Header />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <div className='ques_wrapper p-60'>
                <div className='question_inner'>
                  <div className='main-title text-center'>
                    <h2 className='h2title'>Help us match you to the right therapist</h2>
                    <p className='ptext'>It's important to have a therapist who you can establish a personal connection with. The following questions are designed to match you to a licensed therapist based on your therapy needs and personal preferences.</p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-sm-12 col-md-10 col-lg-7 mx-auto'>
              <div className='starting_quest'>
                <div className='heading_tiled'>
                  {/* <h3 className='h3class'>What type of therapy you are looking for?</h3> */}
                  <h3 className='h3class'>{data[currentQuestion]?.question}</h3>
                  <div className='answing_list'>
                    {data[currentQuestion]?.options?.map((answerOption, index) => (
                      <div key={index} className='onetworadio'>
                        {data[currentQuestion]?.answer_type == 'single' ? (
                          <input
                            type="radio"
                            className='d-none hideradio'
                            id={`option-${currentQuestion}-${index}`}
                            checked={selectedAnswer === index}
                            onChange={() => handleAnswerOptionClick(index)&& setindex(index)}
                          />
                        ) : (
                          
                      
                          <input
                            type="checkbox"
                            className='d-none hideradio'
                            id={`option-${currentQuestion}-${index}`}
                            checked={selectedAnswer && selectedAnswer.includes(index)}
                            onChange={() => handleAnswerOptionClick(index)&& setindex(index)}
                          />
                        )}
                              
                        <label htmlFor={`option-${currentQuestion}-${index}`} className='label_answer'>
                          {answerOption.option}
                        </label>
                      </div>
                    ))}
                  </div>

                                  {
                                      data[currentQuestion]?.answer_type != 'single' ? <div className='d-flex justify-content-end align-items-center mb-3'><button className="btn btn-white" onClick={() => setCurrentQuestion(currentQuestion+1)}>Next</button></div> : null
                                  }


                </div>

                <div className='questinganweser_disclamier'>
                  <div className='disc_icon'>
                    <i className='fa fa-info'></i>
                    <span>This assessment helps us match you to a professional therapist you can trust, based on your specific needs.</span>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>


      </div>
      <Footer />
    </>
  );
};

export default Questions;