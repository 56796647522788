import React, { useEffect, useState } from 'react'
import Footer from '../Footer/Footer'
import Header from '../Header'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import ApiClient from '../../../methods/api/apiClient'
import { login_success } from '../../../actions/user'
import { useDispatch } from 'react-redux'
import methodModel from '../../../methods/methods'
import dateFormat from 'dateformat'
import loader from '../../../methods/loader'

const PaymentSuccess = ()=>{
    const dispatch = useDispatch()
    const [data, setData] = useState({})
    const history = useHistory()
    const TransactID = localStorage.getItem('TransactID')

    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
    },[])

    const GetTransactionDetails = () => {
        loader(true)
        ApiClient.get('user/profile').then((res) => {
            if (res.success) {
                dispatch(login_success(res.data))
            }
        })

        ApiClient.get('transaction', { id: TransactID }).then((res) => {

            if (res.success) {
                setData(res.data)

            }
            loader(false)
        })
    }

    useEffect(() => {
        GetTransactionDetails()
    }, [])
return(
    <><div className="header-dark-page "> <Header /></div><div className='container'>
        <div class="d-flex justify-content-center align-items-center my-5">
            <div class="cong_main_div">
                <div className='text-center'> <img src='assets/img/reward.png' className='img-fluid reward_logo' /></div>
                <h4 class="cong_header text-center">Payment Successful!</h4>
                <p class="cong_desc">
                    Thank you! Your payment of  ${data?.subscription_plan_id?.price} has been recieved.
                </p>
                <p class="cong_id text-center">
                    Order ID: {data?.subscription_plan_id
                        ?.id} | Transaction ID: {data?.transaction_id}
                </p>
                <div class="text-center">
                    <p class="cong_payment">Payment Details</p>
                </div>
                <div class="d-flex justify-content-between">
                    <span class="cong_total_amount">Plan Price : $ {data?.subscription_plan_id?.price}</span>
                    <span class="cong_total_amount"
                    >Transaction Date : {dateFormat(data?.createdAt, 'dd mmm yyyy')}</span>
                </div>
                <div class="d-flex justify-content-between mt-2">
                    <span class="cong_total_amount">Payment Method: {methodModel.capitalizeFirstLetter(data?.payment_method)}</span>
                    <span class="cong_total_amount"
                    >Total Amount Paid : $ {data?.subscription_plan_id?.price}</span>
                </div>
                <div class="d-flex justify-content-between mt-2">
                    <span class="cong_total_amount">Currency : USD</span>
                    {/* <span class="cong_total_amount"
    >Total Ammount Paid : $ 1000</span
    > */}
                </div>
                {/* <div class="d-flex justify-content-between mt-2">
        <span class="cong_total_amount"
        >paid via Incentives : Rs.1000</span
        >
        <span class="cong_total_amount">paid via online transaction : Rs.3000</span>

    </div> */}
                <hr />
                <p class="text-center footer_cong">Click the below button to Continue </p>
                <div class="text-center"><button class="btn btn-primary " onClick={() => {
                    localStorage.removeItem('TransactID');
                    history.push('/appointment');
                } }>Get Started</button></div>
            </div>
        </div></div><Footer /></>
)

}

export default PaymentSuccess;
