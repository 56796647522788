const list = [
    { id: 'PENDING_SUPPLIER', name: 'Pending' },
    { id: 'CANCELLED', name: 'Cancelled' },
    { id: 'CONFIRMED', name: 'Confirmed' },
]

const find = (key) => {
    let ext = list.find(itm=>itm.id == key)
    return ext
}

const name = (key) => {
    let ext = list.find(itm=>itm.id == key)
    return ext?ext.name:key
}

const orderStatusModel = { list, find,name }
export default orderStatusModel