import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import loader from "../../methods/loader";
import ApiClient from "../../methods/api/apiClient";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "./style.scss";
import Header from "../HomePage/Header";
import Footer from "../HomePage/Footer/Footer";
import methodModel from "../../methods/methods";

export default function SignUp() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [terms, setTerms] = useState([]);
  const [reason, setreaon] = useState(true);

  const nickname = localStorage.getItem("nickname");
  const GetContent = (p = {}) => {
    loader(true);
    let filter = p;
    ApiClient.get("content", p).then((res) => {
      if (res.success) {
        setTerms(res?.data);
      }
      loader(false);
    });
  };
  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    GetContent();
  }, []);

  const navigate = useHistory();

  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });
  const questionData = localStorage.getItem("questionJson2");
  return (
    <>
      <div className="bg_leaf position-relative">
        <img
          src="/assets/img/therpy/left1.png"
          className="img-fluid leftside"
        />
        <img
          src="/assets/img/therpy/right1.png"
          className="img-fluid rightside"
        />
        <div className={`header-dark-page ${isScrolled ? "scrollsticky" : ""}`}>
          <Header />
        </div>
      </div>
      {reason ? (
        <div className="col-12 col-sm-12 col-md-10 col-lg-7 mx-auto">
          <div className="starting_quest">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                if (e.target[0]?.value?.length > 10) {
                  setreaon(false);
                }else{
                  toast.error('Please enter minimum 10 characters.')
                }
              }}
            >
              {/* <div className="col-md-6 mb-3"> */}
              <label style={{ fontSize: "20px" }} className="text-md">
                What brings you here?<span className="star">*</span>
              </label>
              <div className="questinganweser_disclamier">
                <div className="disc_icon mb-4">
                  <i className="fa fa-info"></i>
                  <span>
                    {" "}
                    Join the 7,919 people that started therapy with their
                    tailored therapist match this week. Let your therapist know
                    what you’d like to work on below. The more information you
                    provide, the better your therapist will understand where to
                    begin.{" "}
                  </span>
                </div>
              </div>
              <textarea
                onChange={(e) => {

                    localStorage.setItem("reason", e.target.value);
                  
                }}
                type="text"
                name=""
                cols={5}
                required
                rows={5}
                className="form-control"
                id=""
                placeholder="What brings you here...?"
              />
              {/* </div> */}
              <div className="buttn_next mt-3 text-end">
                <button type="submit" className="btn btn-primary btn-sm">
                  Submit{" "}
                </button>
              </div>

              {/* <button className='btn btn-primary mt-4'>Submit</button> */}
            </form>
          </div>
        </div>
      ) : (
        <div className="container-fluid my-3">
          <div className="row align-items-center">
            <div className=" col-10 col-md-5 mx-auto p-0">
              <div className="width50_auto">
                <Formik
                  enableReinitialize
                  initialValues={{
                    email: "",
                    fullName: "",
                    password: "",
                    check: "true",
                  }}
                  onSubmit={(values) => {
                    console.log(values, "gbyb");
                    if (values.confirmEmail != values.email) {
                      return toast.error("Confirm email doesn't match");
                    }
                    const payload = {
                      nickName: nickname,
                      email: values.email,
                      reason:localStorage.getItem('reason'),
                      password: values.password,
                      fullName: values.fullName,
                      role: "patient",
                      isChecked: true,
                    };
                    if (!values.check.length) {
                      toast.error(
                        "Please Accepted The Terms Of Use And Privacy Policy"
                      );
                      return;
                    }
                    loader(true);
                    ApiClient.post(`register`, payload).then((res) => {
                      if (res.message) {
                        localStorage.setItem("SignUpEmail", values.email);
                        localStorage.setItem("SignUpId", res?.data?.id);
                        localStorage.removeItem("nickname");
                        const data = {
                          user_id: res?.data?.id,
                          ques_ans: JSON.parse(questionData),
                        };
                        ApiClient.post(`userQuestion`, data).then((res) => {
                          if (res) {
                            navigate.push(`/otppage`);
                          }
                          loader(false);
                        });
                      }
                    });
                  }}
                >
                  {({ values, handleChange, handleBlur, handleSubmit }) => (
                    <form className="" onSubmit={handleSubmit}>
                      <div className="main_titled mb-4">
                        <h3 className="text-left lgtext">Sign Up</h3>
                        <p>
                          Register with us, It will take less than a minute.
                        </p>
                      </div>

                      {/* <div className='radio_btn mb-4'>
                      <span className='radio_custom mr-3'>
                        <label className="custom-radio">
                          <input
                            type="radio"
                            name="role"
                            value="therapist"
                            checked={values.role === "therapist"}
                            onChange={handleChange}
                          />
                          <span className="radio-icon mr-2"></span>
                          Therapist
                        </label>
                      </span>

                      <span className='radio_custom'>
                        <label className="custom-radio">
                          <input
                            type="radio"
                            name="role"
                            value="patient"
                            checked={values.role === "patient"}
                            onChange={handleChange}
                          />
                          <span className="radio-icon mr-2"></span>
                          Patient
                        </label>
                      </span>
                    </div> */}

                      <div className="row mt-3">
                        <div className=" mb-3">
                          {/* <label className='form-label'>First Name<span className='text-danger'>*</span></label> */}
                          <input
                            type="text"
                            value={values.fullName}
                            required
                            name="fullName"
                            placeholder="Full Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            className="form-control bginput"
                          />
                        </div>
                        {/* <div className='col-md-6'>
                        <input type='text' value={values.lastname} required name='lastname' onChange={handleChange} onBlur={handleBlur} placeholder='Last Name' className='form-control bginput' />
                      </div> */}
                      </div>

                      <div className="">
                        {/* <label className='form-label'>Email<span className='text-danger'>*</span></label> */}
                        <input
                          type="email"
                          value={values.email}
                          required
                          name="email"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Email Address"
                          className="form-control bginput"
                        />
                      </div>
                      <div className="mt-3">
                        {/* <label className='form-label'>Email<span className='text-danger'>*</span></label> */}
                        <input
                          type="email"
                          value={values.confirmEmail}
                          required
                          name="confirmEmail"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Confirm Your Email Address"
                          className="form-control bginput"
                        />
                      </div>

                      <div className="col-md-12 p-0 mt-3 inputWrapper">
                        {/* <label className='form-label'>Password<span className='text-danger'>*</span></label> */}
                        <input
                          className="form-control bginput"
                          type={!eyes.password ? "password" : "text"}
                          required
                          value={values.password}
                          name="password"
                          minLength="8"
                          onChange={handleChange}
                          placeholder="Password"
                          onBlur={handleBlur}
                        />
                        <i
                          className={
                            eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                          }
                          onClick={() =>
                            setEyes({ ...eyes, password: !eyes.password })
                          }
                        ></i>
                      </div>

                      <div className="mt-4 d-flex align-items-baseline">
                        <input
                          type="checkbox"
                          className="checkBox"
                          name="check"
                          onChange={handleChange}
                        />
                        <label className="clickBox ml-2 mb-0">
                          By clicking Create account, I agree that I have read
                          and accepted the{" "}
                          <a
                            style={{ textDecoration: "underline" }}
                            onClick={() => {
                              GetContent({ page_name: "terms and conditions" });
                              document.getElementById("OpenModel").click();
                            }}
                          >
                            Terms of Use {" "}
                          </a>
                          and
                          <a
                            onClick={() => {
                              GetContent({ page_name: "privacy-policies" });
                              document.getElementById("OpenModel").click();
                            }}
                          >
                            {" "}
                            Privacy Policy 
                          </a>
                          {/* <a
                            onClick={() => {
                              GetContent({ page_name: "payment consent form" });
                              document.getElementById("OpenModel").click();
                            }}
                          >
                            {" "}
                            Payment Consent Form
                          </a> */}
                        </label>
                      </div>
                      <div>
                        <button
                          type="submit"
                          disabled={values?.check != "on"}
                          className="btn btn-primary loginclass mt-3"
                        >
                          Sign Up
                        </button>
                      </div>
                      {/* <div className='borderCls mt-5'><span className='or'>or</span></div>
                    <div className='text-center d-flex justify-content-between mt-5'>
                      <a className='btn btn-outline google_id'>
                        <img src='/assets/img/gogle.png' />
                        <span className='google_heading'>Google</span>
                      </a>
                      <a className='btn btn-outline google_id'>
                        <img src='/assets/img/facebooklogo.png' />
                        <span className='google_heading'>Facebook</span>
                      </a>
                    </div>

                    <div className=" pt-4">
                      <p className=" text-center">
                        Already have an account?{" "}
                        <Link class="sign_up" to="/login">
                          {" "}
                          Sign In
                        </Link>
                      </p>
                    </div> */}
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      )}

      <button
        id="OpenModel"
        type="button"
        class="btn btn-primary d-none"
        data-toggle="modal"
        data-target="#exampleModal"
      ></button>

      <div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {methodModel.capitalizeFirstLetter(terms?.title)}
              </h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div
                className="overf_scrol"
                dangerouslySetInnerHTML={{ __html: terms?.description }}
              ></div>
            </div>

            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <div class="modal fade" id="exampleModal2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel2">Privacy And Policy</h5>
            <button type="button" class="close" data-dismiss="modal2" aria-label="Close2">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div  dangerouslySetInnerHTML={{ __html: terms?.description }}></div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal2">Close</button>

          </div>
        </div>
      </div>
    </div> */}
      <Footer />
      <ToastContainer position="top-right" />
    </>
  );
}
