import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './style.scss';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

const Forgotpassword = () => {
    const history = useHistory();

    const user = useSelector(state => state.user)
    useEffect(() => {
        if (localStorage.getItem('token')) {
            history.push('/dashboard')
        }
    }, [])

    useEffect(() => {
        loader(true)
        setTimeout(() => {
            loader(false)
        }, 600);
    }, [])
    const [form, setForm] = useState({ email: '' });

    useEffect(() => {

    }, [])

    const hendleSubmit = (e) => {
        e.preventDefault();
        loader(true)

        ApiClient.post('user/forgot-password', { ...form }).then(res => {
            if (res.success) {
                history.push('/confirmcode');
            }
            loader(false)
        })
    };

    return (
        <>



            <div className="container-fluid p-0">
                <div className="row align-items-center mx-0">
                    <div className="col-md-6 p-0">
                        <div className='banner_img_local' >


                        </div>
                    </div>
                    <div style={{ position: 'absolute', top: '10px',display:'flex',justifyContent:'end' }}>

<button className='btn btn-primary' onClick={()=>{
    history.goBack()
}}>Back</button>
</div>
                    <div className="col-md-6 p-0">
                      
                        <div className=''>
                            <form
                                className="width80 mx-auto"
                                onSubmit={hendleSubmit}
                            >

                                <div className="text-center mb-4">
                                    <h3 className="text-left lgtext">Forgot password?</h3>
                                </div>
                                <p className=''>No worries!! Just enter your email and we’ll send you a reset password link.</p>
                                <div className="inner_login">
                                    <div className="inputWrapper">
                                        <input
                                            type="email"
                                            className="form-control  mb-0 bginput" placeholder='Email*'
                                            value={form.email}
                                            required
                                            onChange={e => setForm({ ...form, email: e.target.value })}
                                        />

                                    </div>
                                </div>


                                <div className="buttons mt-5">

                                    <button type="submit" className="btn btn-primary loginclass mb-4">
                                        Send Recovery Email
                                    </button>
                                </div>

                                <p className='accopuntt mt-2'> Just Remember?<Link class="login" to="/login"> Sign in</Link></p>
                            </form>

                        </div>

                    </div>


                </div>
            </div>


            {/* <div className="login-wrapper">
                <div className="mainfromclss">
                    <div className="row">
                        <div className="col-md-6  px-0">
                            <form
                                className="p-5 rounded shadow"
                                onSubmit={hendleSubmit}
                            >
                                <a href="/login"><i className="fa fa-arrow-left" title='Back' aria-hidden="true"></i></a>
                                <div className="mb-3">
                                    <Link to={''}>
                                        <img src="/assets/img/logo.jpg" className="logimg pt-4" />
                                    </Link>
                                </div>
                                <div className="text-center mb-3">
                                    <h3 className="text-left lgtext">Forgot Password</h3>
                                </div>
                                <label></label>
                                <div className="mb-3">
                                    <input
                                        type="email"
                                        className="form-control  mb-0 bginput" placeholder='Email*'
                                        value={form.email}
                                        required
                                        onChange={e => setForm({ ...form, email: e.target.value })}
                                    />

                                </div>

                                <div className="text-right">
                                    <button type="submit" className="btn btn-primary loginclass">Send</button>
                                </div>
                            </form>
                        </div>

                        <div className="col-md-6 px-0">
                            <img src="./assets/img/login_Img.png" className="loginimg w-100" />
                        </div>
                    </div>
                </div>
            </div> */}






            <ToastContainer position="top-right" />
        </>
    );
};

export default Forgotpassword;
