import React, { useEffect, useRef, useState } from "react";
import methodModel from "../../methods/methods";
import MultiSelectDropdown from "../../components/common/MultiSelectDropdown";
import GooglePlaceAutoComplete from "../../components/common/GooglePlaceAutoComplete";
import SelectDropdown from "../../components/common/SelectDropdown";
import datepipeModel from "../../models/datepipemodel";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
const Html = ({
  contacts,
  addContact,
  updateContact,
  removeContact,
  uploadImage,
  handleSubmit,
  getError,
  form,
  setform,
  submitted,
  categories,
  user,
  currency,
  addressResult,
  addressSellected,
  timezoneLoader,
}) => {
  const btnref = useRef(null);

  const ShowModel = () => {
    const modelbtn = document.getElementById("model").click()

  }
  useEffect(() => {
    if (user.financialYear == '') {
      ShowModel()
    }
  }, [])

  return (
    <div className="">
      {/* Pop Model For Empty Financial Year */}
      <button
        type="button"
        class="btn btn-primary"
        ref={btnref}
        style={{ display: 'none' }}
        id="model"
        data-bs-toggle="modal"
        data-bs-target="#exampleModal"
      >
        Open modal
      </button>
      <div
        class="modal fade "
        style={{ marginTop: "10vh" }}
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
      >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                Financial Year is not set - choose the month
                when your financial year starts.{" "}
              </h5>
            </div>
            <div className="modal-body">
              <SelectDropdown
                id="financialYear"
                displayValue="name"
                placeholder="Select Month"
                name="financialYear"
                required={true}
                intialValue={form.financialYear}
                result={(e) => {
                  setform({ ...form, financialYear: e.value });
                }}
                options={datepipeModel.monthArray.map((itm) => {
                  return {
                    ...itm,
                    id: itm.id + 1,
                  };
                })}
              />
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-primary"
                data-bs-dismiss="modal"
                onClick={handleSubmit}>
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="pprofile12">
        <h3 className="hedding" >Company Details</h3>
        <div className="company_cards">
          <form onSubmit={handleSubmit}>
            <div className="">
              <div class="info_detail">
                <div className="company_info">
                  <div className="maininutcls">
                    <label className="profileImageLabel">
                      <img
                        src={methodModel.userImg(form.logo)}
                        className="profileImage"
                      />
                    </label>
                    <div className="profile_btn">
                      <div>
                        <label className="btn btn-primary edit ml-3">
                          <input
                            id="bannerImage"
                            type="file"
                            className="d-none"
                            accept="image/*"
                            value={form.baseImg ? form.baseImg : ""}
                            onChange={(e) => {
                              uploadImage(e);
                            }}
                          />
                          {form.logo ? "Change" : "Upload"} Image
                        </label>
                        {form?.logo ?
                          <label className="btn btn-primary edit ml-3" onClick={() => {
                            setform({ ...form, logo: '', baseImg: '' })
                          }} >Delete Image</label> : <></>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className="btn_edit">
                  {/* <h4 className="btnwhite"><span class="material-icons material_edit">edit</span>Edit</h4> */}
                </div>
              </div>


              <div className="edit_company_detail">
                <div className="row mt-2">
                  <div className="col-md-12">
                    <label className="user_company">Name of Company</label>
                    <div className="col-md-12  mb-3 p-0">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="What’s your company name? "
                        value={form.companyName}
                        onChange={(e) => {
                          setform({ ...form, companyName: e.target.value })
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      Physical Address<span className="star">*</span>
                    </label>
                    <GooglePlaceAutoComplete
                      value={form.companyAddress}
                      result={addressResult}
                      id="address"
                      placeholder="Type address"
                    />
                    {!addressSellected && form.companyAddress ? (
                      <div className="invalid-feedback d-block">
                        Please Select Location Suggestion
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>
                      {" "}
                      Select Time Zone{" "}
                      {timezoneLoader ? (
                        <>
                          <i className="fa fa-spinner fa-spin"></i>
                        </>
                      ) : (
                        <></>
                      )}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Select date"
                      value={form.companytimezone}
                      onChange={(e) =>
                        setform({ ...form, companytimezone: e.target.value })
                      }
                      required
                      disabled
                    />
                  </div>
                  {user?.companyName ?
                    <div className="col-md-6 mb-3">
                      <label>Registered business number</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="5467-7643-0989-1234"
                        value={form.bussinessNumber}
                        maxLength="16"
                        minLength="16"
                        onChange={(e) => {
                          setform({ ...form, bussinessNumber: methodModel.isNumber(e) })
                        }}

                      />
                    </div>
                    : null}
                  <div className="col-md-6 mb-3">
                    <label>Website address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="What’s your company name? "
                      value={form.website}
                      onChange={(e) => {
                        setform({ ...form, website: e.target.value })
                      }}
                      required
                    />
                  </div>
                  {user?.companyName ?
                    <>
                      <div className="col-md-6 mb-3">
                        <label className="user_company">
                          Contact Person
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={form.contactPerson}
                          onChange={(e) => {
                            setform({ ...form, contactPerson: e.target.value })
                          }}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <label className="user_company">
                          Designation
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder=""
                          value={form.position}
                          onChange={(e) => {
                            setform({ ...form, position: e.target.value })
                          }}
                          required
                        />
                      </div>
                    </>
                    : null}
                  <div className="col-md-6 mb-3">
                    <label className="user_company">
                      Contact Number
                    </label>
                    <div className="row">
                      <div className="col-md-12">
                        <PhoneInput
                          country={"us"}
                          required
                          enableSearch={true}
                          placeholder="Enter phone number"
                          value={form?.companymobileno}
                          countryCodeEditable={true}
                          onChange={(e) => {
                            setform({ ...form, companymobileno: e })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="user_company">Email</label>
                    <div className="col-md-12  mb-3 p-0">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Input your email address"
                        value={form.contactemail}
                        onChange={(e) => {
                          setform({ ...form, contactemail: e.target.value })
                        }}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Product Categories</label>
                    <MultiSelectDropdown
                      options={categories}
                      intialValue={form.productcategory}
                      displayValue="name"
                      id="productCat"
                      result={(e) =>
                        setform({ ...form, productcategory: e.value })
                      }
                    />
                  </div>
                  {user?.companyName ?
                    <div className="col-md-6 mb-3">
                      <label>Reseller Type</label>
                      <MultiSelectDropdown
                        options={categories}
                        intialValue={form.productcategory}
                        displayValue="name"
                        id="productCat"
                        result={(e) =>
                          setform({ ...form, productcategory: e.value })
                        }
                      />
                    </div>
                    : null}
                  <div className="col-md-6 mb-3">
                    <label>Currency</label>
                    <MultiSelectDropdown
                      id="currency"
                      displayValue="currency"
                      intialValue={form.currency}
                      result={(e) => setform({ ...form, currency: e.value })}
                      options={currency}
                    />
                  </div>


                  <div className="col-md-6 mb-3">
                    <label>Financial Year Start</label>
                    <SelectDropdown
                      id="financialYear"
                      displayValue="name"
                      placeholder="Select Month"
                      name="financialYear"
                      required={true}
                      intialValue={form.financialYear}
                      result={(e) => {
                        setform({ ...form, financialYear: e.value });
                      }}
                      options={datepipeModel.monthArray.map((itm) => {
                        return {
                          ...itm,
                          id: itm.id + 1,
                        };
                      })}
                    />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>Date Format</label>
                    <SelectDropdown
                      id="dateFormmate"
                      displayValue="name"
                      placeholder="Select Date Format"
                      name="dateFormmate"
                      required={true}
                      intialValue={form.companyDateFormat}
                      result={(e) => {
                        setform({ ...form, companyDateFormat: e.value });
                      }}
                      options={datepipeModel.dateFormat}
                    />
                  </div>
                  <div className="col-md-6 mb-3">
                    <label>Currency Format</label>
                    <SelectDropdown
                      id="CurrencyFormmate"
                      displayValue="name"
                      placeholder="Select Currency Format"
                      name="CurrencyFormmate"
                      required={true}
                      intialValue={form.companyCurrencyFormat}
                      result={(e) => {
                        setform({ ...form, companyCurrencyFormat: e.value });
                      }}
                      options={datepipeModel.currencyFormat}
                    />
                  </div>
                </div>
                {user?.companyName ?
                  <>
                    <h1 className="info_heading">Contact Person</h1>
                    {contacts.map((itm, i) => {
                      return <div className="row mb-3 border p-2 mx-0 rounded">
                        <div className="col-md-6 mb-3">
                          <label>Contact person name</label>
                          <input
                            type="text"
                            className="form-control"
                            value={itm.personName}
                            onChange={(e) =>
                              updateContact(i, 'personName', e.target.value)
                            }
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>Designation</label>
                          <input
                            type="text"
                            className="form-control"
                            value={itm.designation}
                            onChange={(e) => updateContact(i, 'designation', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>Contact number</label>
                          <PhoneInput
                            country={"us"}
                            required
                            enableSearch={true}
                            placeholder="Enter phone number"
                            value={itm?.contactNumber}
                            countryCodeEditable={true}
                            onChange={(e) => { updateContact(i, 'contactNumber', e) }}
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            value={itm.email}
                            onChange={(e) => updateContact(i, 'email', e.target.value)}
                            required
                          />
                        </div>
                        <div className="col-md-12 mb-3 text-right">
                          <i className="fa fa-trash" onClick={e => removeContact(i)}></i>
                        </div>
                      </div>
                    })}

                    <div className="">
                      <button type="button" className="btn btn-light light_white" onClick={addContact}><i class="fa fa-plus mr-2" aria-hidden="true"></i>Add Another Person</button>
                    </div>
                  </>
                  : null}
                <div className=" text-right mt-4">
                  {/* <button type="button" className="btn btn-light light_white">Cancel</button> */}
                  <button className="btn btn-primary ml-3">Save</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Html;
