import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Layout from '../../components/global/layout';
import ApiClient from '../../methods/api/apiClient';
import loader from '../../methods/loader';
import './profile.scss'
import { useSelector } from 'react-redux';
import methodModel from '../../methods/methods';
import Header from '../HomePage/Header';
import Footer from '../HomePage/Footer/Footer';
import { useHistory } from 'react-router';
import environment from '../../environment';


const Profile = () => {




  const user = useSelector((state) => state.user);
  const [data, setData] = useState('');
  const history = useHistory()
  const gallaryData = () => {
    loader(true)
    ApiClient.get(`user/profile`).then(res => {
      if (res.success) {
        setData(res.data)
      }
      loader(false)

    })
  };

  useEffect(
    () => {
      if (user && user.loggedIn) {
        gallaryData();
      } else {

        history.push('/')
      }
    },
    []
  );


  const [isScrolled, setIsScrolled] = useState(false);


  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    // <Layout>
    <>
      <div className='bg_leaf position-relative'>
        <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
        <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
        <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

          <Header />
        </div>
        <div className="container-fluid p-0 overflow-hidden">
          <div className="row ">
            <div className="col-lg-12">
              <div className="contact-us-bg bg_light_grey">
                <h2> Profile</h2>

              </div>
            </div>
          </div>
        </div>


      </div>
      <div className='container p80'>
        <div className='row'>
          <div className='col-12 col-sm-12 col-md-6 mx-auto'>

            <div className='border_layout'>
              <div className="form-row">
                <div className="col-md-12 ">

                  <div className='row '>

                    <div className='col-12 col-sm-12 col-md-4'>
                      <div className='text-center'>
                        <label className="listimgpro">
                          <img src={data?.image ? `${environment?.api}images/users/` + data?.image : "/assets/img/person.jpg"} className="img-fluid " />
                        </label>
                      </div>
                    </div>
                    <div className='col-12 col-sm-12 col-md-8'>
                      <div className='row mt-4'>
                        <div className="col-12  ">
                          <div className='form-group'>
                            <label>Name</label>
                            <div>
                              <p className="profile_data">{data && data.fullName}</p>
                            </div>
                          </div>

                        </div>
                        <div className="col-12  ">
                          <div className='form-group'>
                            <label>Email</label>
                            <div>
                              <p className="profile_data">{data && data.email}</p>
                            </div>
                          </div>

                        </div>
                        {data.mobileNo ? <div className="col-12 ">
                          <div className='form-group'>
                            <label>Mobile No</label>
                            <div>
                              <p className="profile_data">{String(data && `${data.dialCode}-` + data.mobileNo)}</p>
                            </div>
                          </div>

                        </div> : <></>}
                          {data.plan_details ? <div className="col-12 ">
                          <div className='form-group'>
                            <label>Subscribed Plan</label>
                            <div>
                              <p className="profile_data">{String(data && data.plan_details?.name)}</p>
                            </div>
                          </div>

                        </div> : <></>}
                      </div>
                    </div>
                    

                    <div className='col-12 col-sm-12 col-md-12 mt-4'>
                      <div className='text-right'>
                        <Link to="/editprofile" className="btn btn-primary ">
                          <i class="fa fa-edit mr-2 text-white" title="Edit Profile"></i>
                          Edit Profile

                        </Link>
                      </div>
                    </div>

                  </div>

                </div>

              </div>



            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
    // </Layout>
  );
};

export default Profile;
