import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function Footer() {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  return (
    <>
      <section className="pt130  bgblueradius">
        <div className="container">
          <div className="row">
            <div className="footerarea d-flex justify-content-between align-items-center">
              <p className="m-0">
                If you are in a crisis or any other person may be in danger -
                don't use this site.{" "}
                <span className="d-block">
                  {" "}
                  <b className="text-white">Contact </b><a className="text-white jhovering" href="tel:911">911</a>
                </span>
              </p>
              <img
                className="img-fluid"
                src="/assets/img/therpy/securepayment.png"
              />
            </div>
          </div>
        </div>
      </section>

      <footer className="p-60 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-3 col-lg-3">
              <div className="footer_logo">
                <img
                  src="./assets/img/therpy/logo-blue.png"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <div className="footer_head">
                <h6>Links</h6>
                <ul className="p-0 ">
                  <li
                    className="pointer"
                    onClick={() => {
                      history.push("/");
                    }}
                  >
                    Home
                  </li>
                  <li className="pointer" onClick={() => {
                      history.push("/plans");
                    }}>Product</li>
                  {/* <li className="pointer" onClick={() => {
                      history.push("/plans");
                    }}> Pricing</li> */}
                  <li  className="pointer" onClick={() => {
                      history.push("/about");
                    }}>About us</li>
                  {/* <li>Careers</li>
                  <li>Features</li> */}
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <div className="footer_head">
                <h6>Help & Support</h6>
                <ul className="p-0 pointer ">
                  <li
                    onClick={() => {
                      history.push("/faq");
                    }}
                  >
                    FAQ
                  </li>
                  {/* <li>Blog</li> */}
                  <li
                    className="pointer"
                    onClick={() => {
                      history.push("/contact");
                    }}
                  >
                    Contact Us
                  </li>
                  <li
                    className="pointer"
                    onClick={() => {
                      history.push("/privacy");
                    }}
                  >
                   Privacy Policy
                  </li>
                  {/* <li>Support</li> */}
                </ul>
              </div>
            </div>
            <div className="col-sm-12 col-md-3 col-lg-3">
              <div className="footer_head">
                <h6>Our Address</h6>
                <ul className="p-0 ">
                  <li>185 Main St, Cliffside Park, NJ 07010</li>
                  <li>
                    <div className="img-social">
                      <span>
                        {" "}
                        <img
                          src="./assets/img/therpy/facebook.png"
                          className="img-fluid"
                        />
                      </span>
                      <span>
                        {" "}
                        <img
                          src="./assets/img/therpy/linkedin.png"
                          className="img-fluid"
                        />
                      </span>
                      <span>
                        {" "}
                        <img
                          src="./assets/img/therpy/google-plus.png"
                          className="img-fluid"
                        />
                      </span>
                      <span>
                        {" "}
                        <img
                          src="./assets/img/therpy/twitter.png"
                          className="img-fluid"
                        />
                      </span>
                    </div>
                  </li>

                  <li>
                    {user ? null : (
                      <span className="new_leeter">SIGN UP FOR NEWSLETTER</span>
                    )}
                    <span className="d-flex mt-2">
                      <input
                        type="text"
                        placeholder="Email Address"
                        className="form-control mr-2"
                      />
                      <button className="btn btn-primary btn-color btn-sm">
                        Subscribe
                      </button>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="text-center copyright_wrap">
              <p >
                © Copyright 2024. Anonymous Therapy <p className="p-0 mt-2">Only licensed in NJ</p>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
