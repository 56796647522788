import React, { useEffect, useState } from 'react'
import Header from '../Header'
import Footer from '../Footer/Footer';
import "./style.css"
import loader from '../../../methods/loader';
import ApiClient from '../../../methods/api/apiClient';
import methodModel from '../../../methods/methods';

const About = () => {
  const[content,setContent]=useState(null)
  const[howitwork,setHowtowork]=useState(null)
  const [isScrolled, setIsScrolled] = useState(false);
  useEffect(()=>{
    loader(true)
    setTimeout(() => {
        loader(false)
    }, 600);
},[])

useEffect(()=>{
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
},[])

  const GetContent = (p = {page_name:'about-us',status:'active'}) => {
    loader(true);
    let filter = p;
    ApiClient.get("content", p).then((res) => {
      if (res.success) {
        setContent(res?.data);
      }
      loader(false);
    });
  };

  const GetHowitWork = (p = {page_name:'how-it-works',status:'active'}) => {
    loader(true);
    let filter = p;
    ApiClient.get("content", p).then((res) => {
      if (res.success) {
        setHowtowork(res?.data);
      }
      loader(false);
    });
  };

  useEffect(()=>{
    GetContent()
    GetHowitWork()
  },[])

  useEffect(() => {
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);


  return (<>
    <div className='bg_leaf position-relative'>
      <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
      <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
      <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

        <Header />
      </div>
      <div className="container-fluid p-0 overflow-hidden bg_light_grey">
        <div className="row ">
          <div className="col-lg-10 mx-auto">
            <div className="contact-us-bg ">
              <h2>{methodModel.capitalizeFirstLetter(content?.page_name)}
              </h2>
      <p>{content?.meta_description}</p>
            </div>
          </div>
        </div>
      </div>


    </div>

    <section className="about-section ">
      <div className="container">
        <div className="row align-items-center">
          <div className="content-column col-lg-7 col-md-12 col-sm-12 order-2">
            <div className="inner-column">
              <div className="sec-title">
                <span className="title">{content?.meta_title}</span>
                {/* <h2>We are Creative Tech Enthusiast working since 2015</h2> */}
              </div>
              <p
                dangerouslySetInnerHTML={{
                  __html: content?.description,
                }}
                className=""
              ></p>

              {/* <div className="text">I am Therapist works at  since last 3 years. We are here to provide touch notch solution for your website or web application that helps you to make your website look attractive & efficient in handling by creating usefull plugins thats you need.</div>
              <div className="text">
                We are here to serve you next level tutorial that currently in trend to match you with your expertise. Css3 transition is a learning website. where you can find many good quality content related to web development and tutorials about plugins. here we are using html, html5, css, css3, jquery & javascript along with inspirational UI design layout by professionals by using Photoshop and adobe allustrator.
              </div>
              <div className="btn-box">
                <a href="#" className="btn btn-primary btn-lg">Contact Us</a>
              </div> */}
            </div>
          </div>


          <div className="image-column col-lg-5 col-md-12 col-sm-12">
            <div className="inner-column wow fadeInLeft">
              <div className="author-desc">
                <h2>Admin</h2>
                <span>Therapist</span>
              </div>
              <figure className="image-1"><img title="loreum ipsum" className='img-fluid' src="/assets/img/therpy/profile.jpg" alt="" /></figure>

            </div>
          </div>

        </div>


      </div>
    </section>


    <section className='p80 pt-0'>
      <div className='container'>
        <div className="sec-title text-center mb-3">
          <h2 className="">{howitwork?.title}</h2>
          
        </div>

        <div className="text mt-4">
        <p
                dangerouslySetInnerHTML={{
                  __html: howitwork?.description,
                }}
                className=""
              ></p>
          <p>{howitwork?.meta_description}
          </p>
        </div>
       
      </div>
    </section>




    <Footer />
  </>
  );
};

export default About;
