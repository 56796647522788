import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { login_success } from "../../actions/user";
import ApiClient from "../../methods/api/apiClient";
import loader from "../../methods/loader";
import { Link } from "react-router-dom";
import "./style.scss";
import methodModel from "../../methods/methods";
import { ToastContainer, toast } from "react-toastify";

const Login = () => {
  const history = useHistory();
  const user = useSelector((state) => state.user);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      history.push("/Dashboard");
    }
  }, []);

  const dispatch = useDispatch();
  const [username, setUsername] = useState("");
  const [remember, setRemember] = useState(false);
  const [password, setPassword] = useState("");
  const [eyes, setEyes] = useState({
    password: false,
    confirmPassword: false,
    currentPassword: false,
  });

  useEffect(() => {
    loader(true);
    setTimeout(() => {
      loader(false);
    }, 500);
    let r = localStorage.getItem("remember");
    if (r) {
      let data = JSON.parse(r);
      setUsername(data.email);
      setPassword(data.password);
      setRemember(true);
    }
    let message = methodModel.getPrams("message");
    if (message) toast.success(message);
  }, []);

  const hendleSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: username,
      password,
    };
    loader(true);
    ApiClient.post("user/signin", data).then((res) => {
      loader(false);
      if (res.success) {
        dispatch(login_success(res?.data))
        localStorage.setItem('token', res?.data?.access_token)
        toast.success(res.message);
        history.push(`/appointment`)
        // localStorage.setItem('SignUpId', res?.data?.id)
        // if (!res.data.companyName) {
        //   toast.success(res.message);
        //   history.push(`/company`)
        // } else {
        //   if (res?.data?.subscriptionId || res.data.on_trial) {
        //     toast.success(res.message);
        //     history.push("/HomePage");
        //   } else {
        //     setTimeout(() => {
        //       toast.error('Please buy a Plan.')
        //     }, 100);
        //     history.push(`/plans`)
        //   }
        // }
      }
    });
  };

  return (
    <>
      <div className="">
        <div className="row align-items-center mx-0">
          <div className="col-md-6 p-0">
            <div className="banner_img_local back_img">
            </div>
          </div>

          <div className="col-md-6">
            <div className="">

              <form className="width80 mx-auto" onSubmit={hendleSubmit}>
                <div className="">
                  <img onClick={() => {
                    history.push('/')
                  }} style={{ height: '80px', width: 'auto' }} src="./assets/img/therpy/logo-blue.png" className="logos" />
                </div>

                <div className="main_titled mb-4">
                  <h3 className="text-left lgtext">Sign in</h3>
                  <p>Please enter your valid details.</p>
                </div>

                <div className="inner_login">
                  <div className="mb-3">
                    <input
                      type="email"
                      className="form-control mb-0 bginput"
                      placeholder="Email address"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <div className="inputWrapper">
                      <input
                        type={eyes.password ? "text" : "password"}
                        className="form-control mb-0 bginput"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        required
                      />
                      <i
                        className={
                          eyes.password ? "fa fa-eye" : "fa fa-eye-slash"
                        }
                        onClick={() =>
                          setEyes({ ...eyes, password: !eyes.password })
                        }
                      ></i>
                    </div>
                  </div>
                </div>

                <div className="d-flex justify-content-between ">
                  <div className="forget">
                    <label>
                      <input type="checkbox" className="mr-2" />

                      Remember Me
                    </label>

                  </div>
                  <div className="forget">
                    <Link to="/forgotpassword" className="text-primary">
                      Forgot Password?
                    </Link>
                  </div>
                </div>
                <div className="mt-0">
                  {/* <label><input type="checkbox" checked={remember} onChange={(e)=>setRemember(e.target.checked)} className="mr-2" /> Remember Me</label> */}
                  <button
                    type="submit"
                    className="btn btn-primary loginclass mt-2"
                  >
                    Sign in
                  </button>
                </div>
                {/* <div className="borderCls mt-5">
                  <span className="or">or</span>
                </div>
                <div className="text-center d-flex justify-content-between mt-4">
                  <a className="btn btn-outline google_id">
                    <img src="/assets/img/gogle.png" />
                    <span className="google_heading">Google</span>
                  </a>
                  <a className="btn btn-outline google_id">
                    <img src="/assets/img/facebooklogo.png" />
                    <span className="google_heading">Facebook</span>
                  </a>
                </div> */}
                <div className=" pt-4">
                  <p className=" text-center">
                    Don’t have an account?{" "}
                    <Link class="sign_up" to="/questions">
                      {" "}
                      Sign Up
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer position="top-right" />
    </>
  );
};

export default Login;
