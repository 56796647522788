import React, { useEffect, useState } from 'react'
import Footer from '../HomePage/Footer/Footer'
import Header from '../HomePage/Header'
import "./style.css"
import ApiClient from '../../methods/api/apiClient';
import { toast } from 'react-toastify';
import loader from '../../methods/loader';
import PhoneInput from 'react-phone-input-2';

function Contact() {
    const [isScrolled, setIsScrolled] = useState(false);
    const [data, setData] = useState([])
    const [form, setform] = useState({})


    useEffect(() => {
        loader(true)
        setTimeout(() => {
            loader(false)
        }, 600);
    }, [])

    useEffect(()=>{
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        })
    },[])


    // ... (other code remains the same)

    const handleAnswerOptionClick = (answerIndex) => {
        const questionId = data[currentQuestion]?.id;
        const questionType = data[currentQuestion]?.answer_type;
        const selectedOptionId = data[currentQuestion]?.options[answerIndex]?.id;

        if (questionType === 'single') {
            const selectedAnswerData = {
                ques_id: questionId,
                ans_id: [selectedOptionId]
            };

            setSelectedAnswersData(prevData => [...prevData, selectedAnswerData]);

            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < data.length) {
                setCurrentQuestion(nextQuestion);
                setSelectedAnswer(null);
            } else {
                localStorage.setItem("questionJson", JSON.stringify(selectedAnswersData))
                history.push("/signup");
            }
        }
    };

    const HandleSubmit = (e) => {
        e.preventDefault()
        ApiClient.post('query', form).then((res) => {
            if (res.success) {
                toast.success(res?.message)
            }
        })
    }

    const handleCheckboxChange = (selectedOptionId) => {
        const currentIndex = selectedOptions.indexOf(selectedOptionId);
        const newSelectedOptions = [...selectedOptions];

        if (currentIndex === -1) {
            newSelectedOptions.push(selectedOptionId);
        } else {
            newSelectedOptions.splice(currentIndex, 1);
        }

        setSelectedOptions(newSelectedOptions);
    };

    // Function to handle clicking the "Next" button
    const handleNextButtonClick = () => {
        const questionId = data[currentQuestion]?.id;
        const questionType = data[currentQuestion]?.answer_type;

        if (questionType === 'multiple') {
            const selectedAnswerData = {
                ques_id: questionId,
                ans_id: selectedOptions
            };

            setSelectedAnswersData(prevData => [...prevData, selectedAnswerData]);

            const nextQuestion = currentQuestion + 1;
            if (nextQuestion < data.length) {
                setCurrentQuestion(nextQuestion);
                // setSelectedOptions([]); // Reset selected options for the new question
            } else {
                localStorage.setItem("questionJson", JSON.stringify(selectedAnswersData))
                history.push("/signup");
            }
        }
    };




    return (
        <>
            <div className='bg_leaf position-relative'>
                <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
                <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
                <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

                    <Header />
                </div>



                <div className="container-fluid p-0 overflow-hidden">
                    <div className="row ">
                        <div className="col-lg-12">
                            <div className="contact-us-bg bg_light_grey">
                                <h2>Contact US</h2>
                                <p>Please fill the details</p>
                            </div>
                        </div>
                    </div>
                </div>


            </div>


            <section className="contact-us-page">

                <div className="address-fields">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="address-inner">
                                    <div className="address-icon">
                                        <i className='fa fa-phone'></i>
                                    </div>
                                    <div>
                                        <h3> Call Us</h3>
                                        <p>973-544-8681</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                
                            </div>
                            <div className="col-sm-12 col-md-4 col-lg-4">
                                <div className="address-inner">
                                    <div className="address-icon">
                                        <i className='fa fa-envelope'></i>
                                    </div>
                                    <div>
                                        <h3> Email</h3>
                                        <p>arezk@secondchancemhc.com</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>


            <section className='p-80'>
                <div className="container">
                    <div className="row">

                        <div className="col-lg-12">
                            <div className="contact-us-title-inner">
                                <h4>HAVE QUESTION? WRITE A MESSAGE</h4>
                                <p>We will catch you as early as we receive the message</p>
                            </div>
                        </div>
                        <div className="col-lg-8 mx-auto">
                            <form onSubmit={HandleSubmit}>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label for="name">Name:</label>
                                            <input
                                                type="text"
                                                id="name"
                                                name="name"
                                                className="form-control"
                                                required
                                                onChange={(e) => setform({ ...form, name: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label for="email">Email</label>
                                            <input
                                                type="email"
                                                id="email"
                                                name="email"
                                                className="form-control"
                                                required
                                                onChange={(e) => setform({ ...form, email: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-6">
                                        <div className="form-group">
                                    <label for="email">Mobile</label>
                                            <PhoneInput
                                                country={"us"}
                                                required
                                                enableSearch={true}
                                                placeholder="Enter phone number"
                                                value={form?.phoneNo}
                                                countryCodeEditable={true}
                                                onChange={(e) => {
                                                    setform({ ...form, phoneNo: e })
                                                }}
                                            />
                                        </div>
                                    </div>


                                    <div className="col-lg-6">
                                        <div className="form-group">
                                            <label for="text">Subject</label>
                                            <input
                                                type="text"
                                                id="text"
                                                name="text"
                                                className="form-control"
                                                required
                                                onChange={(e) => setform({ ...form, subject: e.target.value })}
                                            />
                                        </div>
                                    </div>

                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <label for="message">Message:</label>
                                            <textarea
                                                id="message"
                                                name="message"
                                                rows="4"
                                                className="form-control"
                                                required
                                                onChange={(e) => setform({ ...form, message: e.target.value })}
                                            ></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className='text-center'>
                                    <button type="submit" className="btn btn-primary btn-lg">
                                        Submit
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </section>

            <Footer />
        </>
    )
}

export default Contact
