import React from 'react'
import Header from '../HomePage/Header'
import Footer from '../HomePage/Footer/Footer'

function Advise() {
  return (
   <>
   <Header/>
   <Footer/>    
   </>
  )
}

export default Advise
