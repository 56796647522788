import React, { useEffect, useState } from 'react'
import Header from '../HomePage/Header'
import Footer from '../HomePage/Footer/Footer'
import loader from '../../methods/loader';
import ApiClient from '../../methods/api/apiClient';

function Review() {
  const [isScrolled, setIsScrolled] = useState(false);
  const[Data ,setData] = useState([])
  useEffect(() => {
    loader(true)
    setTimeout(() => {
      loader(false)
    }, 600);
  }, [])

  const GetReivews = ()=>{
    ApiClient.get('reviews?search=&page=1&count=10').then((res)=>{
      if(res.success){
        setData(res?.data)
      }
    })
  }
  useEffect(() => {
    GetReivews()
    let timeoutId;

    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 150;

      // Check if the scroll position is beyond the threshold
      setIsScrolled(scrollTop > scrollThreshold);

      // Clear any existing timeout
      clearTimeout(timeoutId);

      // Set a new timeout to add the class after 5 seconds
      timeoutId = setTimeout(() => {
        setIsScrolled(false);
      }, 8000);
    };

    // Add scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener and timeout on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);


  return (
    <>
      <div className='bg_leaf position-relative'>
        <img src="/assets/img/therpy/left1.png" className='img-fluid leftside' />
        <img src="/assets/img/therpy/right1.png" className='img-fluid rightside' />
        <div className={`header-dark-page ${isScrolled ? 'scrollsticky' : ''}`}>

          <Header />
        </div>

        <div className="container-fluid p-0 overflow-hidden">
          <div className="row ">
            <div className="col-lg-12">
              <div className="contact-us-bg bg_light_grey">
                <h2>Internal Therapy reviews</h2>
                <p>These quotes represent a few of the many positive reviews that we have received for therapists who work with Internal Therapy.</p>
              </div>
            </div>
          </div>
        </div>

      </div>

      <section className='p80'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-12'>
              <div className='reviews_wrapper'>
                <div className="testimonial-area">
                  <div className=" testimonial-content">

                    <div className="row">

                     {Data?.map((itm)=>{
                      return(

                     <div className="col-12 col-sm-12 col-md-4">

                        <div className="single-testimonial mb-5">

                          <p>{itm?.review}</p>
                          <div className="client-info">

                            <div className="client-details">
                              <h6>{itm?.user_details[0]?.fullName}</h6>
                              <span>Patient</span>
                            </div>
                          </div>

                        </div>
                      </div> )
                     }) }

                     





                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>




      <Footer />
    </>
  )
}

export default Review
