import React, { useState, useEffect } from "react";
import { ToastsStore } from "react-toasts";
import { useSelector } from 'react-redux';
import { useHistory, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import ApiClient from "../../../methods/api/apiClient";
import loader from "../../../methods/loader";
import methodModel from "../../../methods/methods";
import SelectDropdown from "../../../components/common/SelectDropdown";
import { earlybirdpricingType } from "../../../models/type.model";
import requiredModel from "../../../models/required.model";
import countModel from "../../../models/count.model";
import './style.scss';
import countryModel from "../../../models/country.model";
import datepipeModel from "../../../models/datepipemodel";
import dynamicPriceModel from "../../../models/dynamicPrice.model";
import countryStateModel from "../../../models/countryState.model";
import DateRangePicker from "../../../components/common/DateRangePicker";
import Breadcrumb from "../../../components/common/Breadcrumb";
import pipeModel from "../../../models/pipeModel";

const AddEdit = ({ dynamicPricingId, isCopy = false,isViewP=false, productData, dType = '', result }) => {
    const { id, type, copy } = useParams()

    const getId = () => {
        return dynamicPricingId ? dynamicPricingId : id
    }

    const getCopy = () => {
        return dynamicPricingId ? isCopy : JSON.parse(copy ? copy : 'false')
    }

    const getProductCode = () => {
        return productData ? productData.productCode : methodModel.getPrams('productCode')
    }

    const getProductId = () => {
        return productData ? productData.id : methodModel.getPrams('productId')
    }

    const defaultvalue = () => {
        let keys = { ...earlybirdpricingType }
        Object.keys(earlybirdpricingType).map(itm => {
            if (itm != 'permissions') keys[itm] = ''
        })
        keys.status = 'active'
        keys.applyToDaysTimeSlot = 'yes'
        return keys
    }

    const symbol = [
        { id: '<', name: '<' },
        { id: '>', name: '>' }
    ]


    const priceTypesList = dynamicPriceModel.priceTypesList

    const [form, setform] = useState(earlybirdpricingType)
    const history = useHistory()
    const [submitted, setSubmitted] = useState(false)
    const user = useSelector((state) => state.user);
    const [date, setdate] = useState([{ startDate: '', endDate: '' }])
    const [availablity, setAvailabilty] = useState([...dynamicPriceModel.slots])
    const [priceTypes, setPriceTypes] = useState([...priceTypesList])
    // const applicables = ['Public Holidays', 'School Holidays', 'Events']
    const applicables = ['State', 'National']
    const[Dateformat,setFormat]=useState('dd/mm/yyyy')

    useEffect(()=>{
        if(user.companyDateFormat=='dd/mm/yyyy'){
            setFormat('dd/MM/yy')

        }else{
            setFormat('MM/dd/yy')
        }
        if(user.companyDateFormat=='dd-mmm-yyyy'){
            setFormat('dd-MMM-yy')
        }
    })

    const formValidation = [
        { key: 'name', required: true },
        { key: 'changesDate', required: true },
        { key: 'notApplicableFor', required: true },
        { key: 'amountOrPercent', required: true },
        { key: 'daysToApply', required: true },
        { key: 'timeSlots', required: true },
        { key: 'discOrPre', required: true },
    ]

    const setpricetype = (index, value, key) => {
        const field = [...priceTypes]
        field[index] = { ...field[index], [key]: value }
        setPriceTypes([...field]);
    }

    const handleSubmit = (e, override = 'no') => {
        if (e) e.preventDefault()
        if(isView) return

        setSubmitted(true)
        let dates = []
        if (form.notApplicableFor.includes('National')) {
            dates = nholidays
        } else if (form.notApplicableFor.includes('State')) {
            dates = sholidays
        }

        // console.log("form.notApplicableFor",form.notApplicableFor)
        // console.log("dates",dates)
        // return

        let err = false
        if (form.applyPriceType == 'no') {
            priceTypes.filter(itm => itm.checked).map(itm => {
                Object.keys(itm).map(oitm => {
                    if (!itm[oitm]) err = true
                })
            })
        }

        let invalid = methodModel.getFormError(formValidation, form)
        if (invalid || err) return
        let method = 'post'
        let url = 'api/dynamic/pricing'
        let value = {
            ...form, type: 'earlyBirdCustomDates',
            blackOutDates: date,
            override,
            donotDates: dates,
            priceTypes: priceTypes.filter(itm => itm.checked)
        }

        if (getCopy() && getProductCode()) {
            url = 'api/apply/template/multiple'
            value.dynamicPricingId = value.id
            value.productId = [getProductId()]
        }

        if (value.id && !getCopy()) {
            method = 'put'
            url = 'api/dynamic/pricing/update'
            if (getProductCode()) url = 'api/product/pricing/update'

        } else {
            delete value.id
        }
        loader(true)
        ApiClient.allApi(url, value, method).then(res => {
            if (res.success) {
                ToastsStore.success(res.message)
                if (dynamicPricingId) {
                    result({ type: 'Submitted', value: res })
                } else {
                    history.goBack()
                }
            } else if (!res.error) {
                let el = document.getElementById('overrideModalBtn')
                if (el) el.click()
            }
            loader(false)
        })
    }


    const year = new Date().getFullYear()
    const [nholidays, setNHolidays] = useState([])
    const [sholidays, setSHolidays] = useState([])
    const [isView, setIsView] = useState(false)

    const getHolidays = (p = {}) => {
        let payload = { year: year, ...p }
        ApiClient.get("api/holidays/listing", payload).then(res => {
            if (res.success) {
                setSHolidays(res.data)
            }
        })
    }

    const getNHolidays = (p = {}) => {
        let payload = { year: year, ...p }
        ApiClient.get("api/holidays/listing", payload).then(res => {
            if (res.success) {
                setNHolidays(res.data)
            }
        })
    }

    useEffect(() => {
        if (getId()) {
            loader(true)
            let url = 'api/dynamic/pricing/detail'
            if (getProductCode() && !getCopy()) url = 'api/product/pricing/detail'
            ApiClient.get(url, { id: getId() }).then(res => {
                if (res.success) {
                    let value = res.data
                    let payload = earlybirdpricingType
                    Object.keys(payload).map(itm => {
                        payload[itm] = value[itm]
                    })
                    if (payload.changesDate) payload.changesDate = new Date(payload.changesDate)
                    if (payload.changesDateTo) payload.changesDateTo = new Date(payload.changesDateTo)

                    const getpriceType = (l, key) => {
                        let ext = payload.priceTypes?.find(itm => itm.label == l)
                        return ext ? ext?.[key] : ''
                    }

                    let pt = productData?.priceOptions?.length ? productData.priceOptions : priceTypesList

                    setPriceTypes([
                        ...pt.map(itm => {
                            return {
                                ...itm,
                                checked: getpriceType(itm.label, 'label') ? true : false,
                                amountOrPercent: getpriceType(itm.label, 'amountOrPercent'),
                                discOrPre: getpriceType(itm.label, 'discOrPre'),
                                number: getpriceType(itm.label, 'number'),

                            }
                        })
                    ])
                    if(payload.blackOutDates){
                        setdate([...payload.blackOutDates.map(itm => {
                            return { startDate: itm.startDate?new Date(itm.startDate):'', endDate: itm.endDate?new Date(itm.endDate):'' }
                        })])
                    }

                    if (getCopy()) {
                        payload.name = `${getProductCode() ? 'Custom' : 'Copy of'} ${payload.name}`
                    }
                    if (!payload.applyToDaysTimeSlot) {
                        payload.applyToDaysTimeSlot = 'yes'
                    }
                    setform({
                        ...payload
                    })
                }
                loader(false)
            })
        }
        let ext = countryModel.search(user.country)
        let country = 'us'
        if (ext) country = ext.cca2.toLowerCase()
        let counties = countryStateModel.stateIso(user.country, user.state)

        getHolidays({ country: country, counties: counties })
        getNHolidays({ country: country })
        setform({ ...defaultvalue(), country })
    }, [getId()])

    useEffect(()=>{
        setIsView(isViewP||methodModel.getPrams('view')?true:false)   
    },[methodModel.getPrams('view'),isViewP])

    const selectAll = (checked) => {
        if (checked) {
            setform({ ...form, notApplicableFor: [...applicables] });
        } else {
            setform({ ...form, notApplicableFor: [] });
        }
    }

    const setchecks = (value, checked) => {
        let applyFor = form.notApplicableFor || []
        if (checked == true) {
            applyFor.push(value)
        }
        else {
            applyFor = applyFor.filter(itm => itm != value)
        }
        setform({ ...form, notApplicableFor: applyFor })
    }

    const addmore = () => {
        setdate([...date, { blackOutDates: '' }])
    }
    const remove = (index) => {
        const rows = [...date];
        rows.splice(index, 1);
        setdate(rows);
    }

    const setalldays = (checked) => {
        if (checked) {
            setform({ ...form, daysToApply: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] });
        } else {
            setform({ ...form, daysToApply: [] });
        }
    }

    const setdays = (value, checked) => {
        let array = form.daysToApply || []
        if (checked == true) {
            array.push(value)
        }
        else {
            array = array.filter(item => item != value)
        }
        setform({ ...form, daysToApply: array })
    }

    const setTimeSlot = (data, checked) => {
        let timeslotarray = form.timeSlots || []
        if (checked == true) {
            timeslotarray.push(data)
        }
        else {
            timeslotarray = timeslotarray.filter(item => item.id != data.id)
        }
        setform({ ...form, timeSlots: timeslotarray })
    }

    const setAllTimeSlots = (checked) => {
        if (checked == true) {
            setform({ ...form, timeSlots: [...availablity] })
        }
        else {
            setform({ ...form, timeSlots: [] })
        }
    }

    const isAlltimeSlots = () => {
        let value = true
        availablity.map(itm => {
            if (!form.timeSlots.find(titm => titm.id == itm.id)) {
                value = false
            }
        })
        return value
    }

    const isAllNotApplicable = () => {
        let value = true
        if (form && !form.notApplicableFor) {
            return false
        }
        applicables.map(itm => {
            if (form && !form.notApplicableFor.find(titm => titm == itm)) {
                value = false
            }
        })
        return value
    }

    const back = () => {
        if (dynamicPricingId) {
            result({ type: 'back', value: '' })
        } else {
            history.goBack()
        }
    }

    const selectAllPriceType = (e) => {
        let arr = []
        if (e.target.checked) {
            arr = priceTypes.map(itm => {
                return { ...itm, checked: true }
            })
        } else {
            arr = priceTypes.map(itm => {
                return { ...itm, checked: false }
            })
        }

        setPriceTypes([
            ...arr
        ])
    }

    const isAllPriceType = () => {
        let value = true
        priceTypes.map(item => {
            if (!item.checked) value = false
        })
        return value
    }

    const blockDateChange=(e,index)=>{
        const field = [...date]
        let startDate = e.startDate?datepipeModel.datetostring(e.startDate):null
        let endDate = e.endDate?datepipeModel.datetostring(e.endDate):null
        field[index] = { ...field[index],endDate,startDate}
        setdate([...field]);
    }

    return <>
        <form onSubmit={handleSubmit}>
            <div className="pprofile1">
   {
    !getProductCode()?
<Breadcrumb
links={[
    {
        name:'Manage Rules',
        link:'/dynamicpricelist'
    }
]}
currentPage={'Last Minute Pricing Inventory Discounting'}
/>
:null

}
                
                <h3 className="Viewtext mb-3">{isView?'View':<>{form && form.id && !getCopy() ? 'Edit' : 'Add'}</>} Early Bird Pricing Custom Dates </h3>
                <div className="form-row">
                    <div className="col-md-6 mb-3">
                        <label>Rule Name<span className="star">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder='Early Bird Pricing - Custom Dates'
                            value={form.name}
                           
                            disabled={isView}
                            onChange={e => setform({ ...form, name: e.target.value })}
                            required
                        />
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Select date to & from when the rates need to be applied<span className="star">*</span></label>
                        <div className="form-row">
                            <div className="col-6">
                                <DatePicker
                                    className="form-control"
                                    selected={form.changesDate}
                                    minDate={new Date()}
                                    placeholderText="Start Date"
                                    name="changesDate"
                                    dateFormat={Dateformat}
                                    required
                                    disabled={isView}
                                    onChange={(date) => {
                                        setform({ ...form, changesDate: date, changesDateTo: '', timeSlots: [] });
                                    }}
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />

                            </div>
                            <div className="col-6">
                                <DatePicker
                                    className="form-control"
                                    placeholderText="End Date"
                                    selected={form.changesDateTo}
                                    dateFormat={Dateformat}
                                    minDate={form.changesDate || new Date()}
                                    disabled={isView}
                                    onChange={(date) => {
                                        setform({ ...form, changesDateTo: date, timeSlots: [] });
                                    }}
                                    name="changesDateTo"
                                    required
                                    onKeyDown={(e) => {
                                        e.preventDefault();
                                    }}
                                />
                            </div>
                        </div>
                        {submitted && (!form?.changesDate || !form?.changesDateTo) ? <div className="text-danger">Date Range is Required</div> : <></>}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Apply early bird pricing if the number of available spaces is<span className="star">*</span></label>
                        <span className="d-flex">
                            <SelectDropdown
                                id="statusDropdown"
                                displayValue="name"
                                placeholder="Select One"
                                intialValue={form.notApplyCondition}
                                result={e => { setform({ ...form, notApplyCondition: e.value }) }}
                                options={symbol}
                                disabled={isView}
                            />
                            <input
                                type="text"
                                className="form-control pCls w-25 ml-3"
                                placeholder="Spaces"
                                minLength={1}
                                maxLength={10}
                                value={form.notApply?form.notApply+"%" : "0%"}
                                disabled={isView}
                                onChange={e => setform({ ...form, notApply: methodModel.isNumber(e) })}
                            />
                        </span>
                        {/* {submitted && !form.notApplyCondition ? <div className="text-danger">Early Bird Pricing is Required</div> : <></>} */}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Do not apply early bird pricing<span className="star">*</span></label>
                        <div class="checkPrice d-flex">
                            <label>
                                <input type="checkbox" onChange={e => selectAll(e.target.checked)} disabled={isView} checked={isAllNotApplicable()} className="mr-1" />
                                All
                            </label>
                            {applicables.map(itm => {
                                return <label>
                                    <input type="checkbox" className="mr-1" disabled={isView} checked={form.notApplicableFor.includes(itm)} onChange={e => setchecks(itm, e.target.checked)} />
                                    {itm}
                                </label>
                            })}
                        </div>
                        {submitted && !form.notApplicableFor ? <div className="text-danger">Do not apply early is Required</div> : <></>}
                    </div>
                    <div className="col-md-12 mb-3">
                        <label>Add Block out Dates or Date Range </label>
                        <div className="form-row">
                            {date && date.map((item, index) => {
                                return <div className="col-md-11 mb-3">
                             
                                    <DateRangePicker
                                    value={item}
                                    id={index}
                                    disabled={isView}
                                    onChange={e=>blockDateChange(e,index)}
                                    />
                                 {
                                    !isView?
                                    <i class="material-icons inputOverlapIcon text-danger pointer" title="Remove" onClick={e => remove(index)}>remove</i>
                                    :<></>
                                 }
                                </div>
                            })}
                            <div className="col-md-12 text-right">
                                {
                                    !isView?
                                    <i class="material-icons bg-primary addIc" title="Add More" onClick={e => addmore()}>add</i>
                                    :<></>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Would you like to Apply Discount (-) or Add Premium (+)<span className="star">*</span></label>
                        <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="Discount (-) Or Add Premium (+)"
                            name="discOrPre"
                            required={true}
                            intialValue={form.discOrPre}
                            disabled={isView}
                            result={e => { setform({ ...form, discOrPre: e.value }) }}
                            options={[
                                { name: 'Discount', id: 'Discount' },
                                { name: 'Premium', id: 'Premium' }
                            ]}
                        />
                        {submitted && !form?.discOrPre ? <div className="text-danger">Discount (-) or Add Premium (+) is Required</div> : <></>}
                    </div>
                    <div className="col-md-6 mb-3">
                        <label>Apply % or Amount<span className="star">*</span></label>
                        <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="% or Amount"
                            disabled={isView}
                            intialValue={form.amountOrPercent}
                            result={e => { setform({ ...form, amountOrPercent: e.value }) }}
                            options={countModel.list}
                        />
                        {submitted && !form.amountOrPercent ? <div className="text-danger">Amount is Required</div> : <></>}
                    </div>

                    <div className="col-md-6 mb-3">
                        <label>Add Number (please add number only not $ or % sign)<span className="star">*</span></label>
                        <input
                            type="text"
                            className="form-control"
                            placeholder=''
                            minLength={1}
                            maxLength={10}
                            required
                            disabled={isView}
                            value={form.number}
                            onChange={e => setform({ ...form, number: methodModel.isNumber(e) })}
                        />
                    </div>


                    <div className="col-md-6 mb-3">
                        <label>Apply to all price types<span className="star">*</span></label>
                        <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="Select Yes/No"
                            disabled={isView}
                            intialValue={form.applyPriceType}
                            result={e => { setform({ ...form, applyPriceType: e.value }) }}
                            options={requiredModel.list}
                        />
                        {/* {submitted && !form.applyPriceType ? <div className="text-danger">All Price Types is Required</div> : <></>} */}
                    </div>
                    {form?.applyPriceType == 'no' ?
                        <div className="col-md-12">
                            <table class="table table-striped">
                                <thead className="table_head">
                                    <tr className="heading_row">
                                        <th className="table_data" scope="col">

                                            <input
                                                type='checkbox'
                                                className="mr-2"
                                                disabled={isView}
                                                checked={isAllPriceType() ? true : false}
                                                onChange={e => selectAllPriceType(e)}
                                            />
                                            Label</th>
                                        <th className="table_data" scope="col">Current Price</th>
                                        <th className="table_data" scope="col"></th>
                                        <th className="table_data" scope="col"></th>
                                        <th className="table_data" scope="col"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {priceTypes.map((itm, i) => {
                                        return <tr>
                                            <th scope="row"><label>
                                                <input
                                                    type='checkbox' 
                                                    className="mr-2"
                                                    disabled={isView}
                                                    checked={itm.checked ? true : false}
                                                    onChange={e => setpricetype(i, e.target.checked, 'checked')}
                                                />
                                                {itm.label}</label></th>
                                            <td>{ pipeModel.currency(itm.price,"",user.companyCurrencyFormat)}</td>
                                            <td>
                                                <SelectDropdown
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    placeholder="Discount (-) Or Add Premium (+)"
                                                    name="discOrPre"
                                                    className={`${!itm.discOrPre && submitted && itm.checked ? 'invalid' : ''}`}
                                                    required={true}
                                                    disabled={itm.checked ? false : true || isView}
                                                    intialValue={itm.discOrPre}
                                                    result={e => { setpricetype(i, e.value, 'discOrPre') }}
                                                    options={[
                                                        { name: 'Discount', id: 'Discount' },
                                                        { name: 'Premium', id: 'Premium' }
                                                    ]}
                                                />
                                            </td>
                                            <td>
                                                <SelectDropdown
                                                    id="statusDropdown"
                                                    displayValue="name"
                                                    disabled={itm.checked ? false : true || isView}
                                                    className={`${!itm.amountOrPercent && submitted && itm.checked ? 'invalid' : ''}`}
                                                    placeholder="Discount % or $"
                                                    intialValue={itm.amountOrPercent}
                                                    result={e => { setpricetype(i, e.value, 'amountOrPercent') }}
                                                    options={countModel.list}
                                                />
                                            </td>

                                            <td>
                                                <input
                                                    type="text"
                                                    className={`form-control ${!itm.number && submitted && itm.checked ? 'invalid' : ''}`}
                                                    placeholder='Amount or %'
                                                    maxLength={10}
                                                    disabled={itm.checked ? false : true || isView}
                                                    value={itm.number}
                                                    onChange={e => setpricetype(i, methodModel.isNumber(e), 'number')}
                                                />
                                            </td>
                                        </tr>
                                    })}
                                </tbody>
                            </table>
                        </div>
                        : null}

                    <div className="col-md-6 mb-3">
                        <label>Apply to all days and all time slots<span className="star">*</span></label>
                        <SelectDropdown
                            id="statusDropdown"
                            displayValue="name"
                            placeholder="Select Yes/No"
                            disabled={isView}
                            intialValue={form.applyToDaysTimeSlot}
                            result={e => { setform({ ...form, applyToDaysTimeSlot: e.value }) }}
                            options={requiredModel.list}
                        />
                        {submitted && !form.applyToDaysTimeSlot ? <div className="text-danger">All days and all time slot is Required</div> : <></>}
                    </div>
                    {form.applyToDaysTimeSlot == 'no' && getProductCode() ?
                        <>
                            <div className="col-md-6 mb-3">
                                <label>Select Days to apply<span className="star">*</span></label>
                                <div>
                                    <div className='d-flex dayCheckbox'>
                                        <div class="form-check ml-0">
                                            <input type="checkbox" class="form-check-input" id="exampleCheck1" disabled={isView} onClick={e => setalldays(e.target.checked)} checked={form.daysToApply.includes('monday') && form.daysToApply.includes('tuesday') && form.daysToApply.includes('wednesday') && form.daysToApply.includes('thursday') && form.daysToApply.includes('friday') && form.daysToApply.includes('saturday') && form.daysToApply.includes('sunday')} />
                                            <label class="form-check-label" for="exampleCheck1">All</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Monday" disabled={isView} checked={form.daysToApply.includes('monday')} onClick={e => setdays('monday', e.target.checked)} />
                                            <label class="form-check-label" for="Monday">Monday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Tuesday" disabled={isView} checked={form.daysToApply.includes('tuesday')} onClick={e => setdays('tuesday', e.target.checked)} />
                                            <label class="form-check-label" for="Tuesday">Tuesday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Wednesday" disabled={isView} checked={form.daysToApply.includes('wednesday')} onClick={e => setdays('wednesday', e.target.checked)} />
                                            <label class="form-check-label" for="Wednesday">Wednesday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Thursday" checked={form.daysToApply.includes('thursday')} onClick={e => setdays('thursday', e.target.checked)} />
                                            <label class="form-check-label" for="Thursday">Thursday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Friday" disabled={isView} checked={form.daysToApply.includes('friday')} onClick={e => setdays('friday', e.target.checked)} />
                                            <label class="form-check-label" for="Friday">Friday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Saturday" disabled={isView} checked={form.daysToApply.includes('saturday')} onClick={e => setdays('saturday', e.target.checked)} />
                                            <label class="form-check-label" for="Saturday">Saturday</label>
                                        </div>
                                        <div class="form-check ml-3">
                                            <input type="checkbox" class="form-check-input" id="Sunday" disabled={isView} checked={form.daysToApply.includes('sunday')} onClick={e => setdays('sunday', e.target.checked)} />
                                            <label class="form-check-label" for="Sunday">Sunday</label>
                                        </div>
                                    </div>
                                </div>
                                {submitted && !form.daysToApply ? <div className="text-danger">Days to Apply is Required</div> : <></>}
                            </div>
                            <div className="col-md-12 mb-3">
                                <label>Select Time Slots to apply<span className="star">*</span></label>
                                <div class="form-check">
                                    <input type="checkbox" class="form-check-input" id="timeslot" disabled={isView} checked={isAlltimeSlots()} onClick={e => setAllTimeSlots(e.target.checked)} />
                                    <label class="form-check-label" for="timeslot">All Slots</label>

                                </div>
                                {availablity.length ? <>
                                    <div className="table-responsive table_section border">
                                        <table class="table table-striped">
                                            <thead className="table_head">
                                                <tr className="heading_row">
                                                    <th className="table_data" scope="col">Select</th>
                                                    <th className="table_data" scope="col">Time</th>
                                                    {form.daysToApply && form.daysToApply.map((item, index) => {
                                                        return <th scope="col" className="table_data text-capitalize">{item}</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {availablity && availablity.map((itm, i) => {
                                                    return <tr>
                                                        <th scope="row"><input type="checkbox" id="timeslotchecks" disabled={isView} checked={form?.timeSlots && form?.timeSlots?.find(i => i.id == itm.id) ? true : false} onClick={e => setTimeSlot(itm, e.target.checked)} /></th>
                                                        <td>{datepipeModel.time(itm.start)} - {datepipeModel.time(itm.end)}</td>
                                                        {form.daysToApply && form.daysToApply.map((item, index) => {
                                                            return <td scope="row"><input type='checkbox' checked={form.timeSlots.find(titm => titm.id == itm.id) ? true : false} onClick={(e)=>setTimeSlot(itm,e.target.checked)}/></td>
                                                        })}
                                                    </tr>
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </> : <>
                                    <div className="text-danger">Slots are not available from {datepipeModel.date(form.changesDate)} to {datepipeModel.date(form.changesDateTo)}</div>
                                </>}

                                {submitted && !form.timeSlots ? <div className="text-danger">Time Slot is Required</div> : <></>}
                            </div>
                        </>
                        : null}
                </div>
                <div className="text-right">
                    <a className="btn btn-light light_btn" onClick={e => back()}>Back</a>
                    {isView?<></>:<>
                    <button type="submit" className="btn btn-primary ml-2">Save</button>
                    </>}
                    
                </div>
            </div>
        </form>

        <button type="button" class="btn btn-primary d-none" data-toggle="modal" data-target="#overrideModal" id="overrideModalBtn">
            Launch demo modal
        </button>

        <div class="modal fade" id="overrideModal" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Message</h5>
                        {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button> */}
                    </div>
                    <div class="modal-body">
                        Some Dates are overlapping. Do you want to override
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">No</button>
                        <button type="button" class="btn btn-primary" data-dismiss="modal" onClick={e => handleSubmit('', 'yes')}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </>
}

export default AddEdit